import type { ReactNode } from 'react';

const makeTranslation = <T extends string>(str: T) =>
  <TParams = {}>() => str as T & {
    _tParams: TParams;
    _tStr: T;
  };

const enMaestroDictionary = {
  RICH_TEXT: 'Rich Text',
  SELECT_A_PAYMENT_METHOD: 'Select a Payment Method',
  ADD_NEW_PAYMENT_METHOD: 'Add a new Payment Method',
  ADD_USERNAME_CHAT:
    'You need a username to chat. Please add a username to your Profile.',
  ADMIN_LABEL_CONFIGURE_ROW: 'Configure your row',
  ADMIN_LABEL_COLOR_PICKER_OPACITY: 'Opacity',
  ADMIN_LABEL_COLOR_PICKER_COLOR: 'Color',
  ADMIN_LABEL_COLOR_PICKER_CHOOSE_COLOR: 'Choose Color',
  ADMIN_LABEL_COLOR_PICKER_ACCENT_COLOR: 'Accent Color',
  ADMIN_LABEL_WELCOME_MESSAGE: 'Welcome Message',
  ADMIN_LABEL_ADD_CHANNEL: 'Add Channels',
  ADMIN_LABEL_ADD_TAGGED_VIDEOS: 'Tagged Videos',
  ADMIN_LABEL_ADD_TEXT_BLOCK: 'Text',
  ADMIN_LABEL_ADD_FAQ: 'FAQ',
  ADMIN_LABEL_ADD_FAQ_CARD: 'FAQ Card',
  ADMIN_LABEL_IMAGE_GALLERY: 'Image Gallery',
  TAGGED_VIDEOS_BLOCK_TITLE: 'Add Block: Tagged Videos',
  TAGGED_VIDEOS_BLOCK_SUBTITLE: 'Display videos with a specific tag',
  TAGGED_VIDEOS_BLOCK_NAME: 'Name',
  TAGGED_VIDEOS_BLOCK_NAME_ERROR: 'Please, set a name',
  TAGGED_VIDEOS_BLOCK_TAG: 'Tag',
  TAGGED_VIDEOS_BLOCK_TAG_PLACEHOLDER: 'Select a tag',
  TAGGED_VIDEOS_BLOCK_TAG_ERROR: 'Please, select a tag',
  TAGGED_VIDEOS_BLOCK_SORT: 'Sort',
  TAGGED_VIDEOS_BLOCK_SORT_TRENDING_OPTION: 'Trending',
  TAGGED_VIDEOS_BLOCK_SORT_MOST_VIEWS_OPTION: 'Most Views',
  TAGGED_VIDEOS_BLOCK_SORT_MOST_RECENT_OPTION: 'Most Recent',
  ADMIN_LABEL_NO_VIDEOS: 'No Videos',
  ADMIN_LABEL_BUTTON_LABEL: 'Button Label',
  ADMIN_LABEL_DELETE: 'Delete',
  ADMIN_LABEL_DESCRIPTION_HELPER: 'This text appears below the panel title',
  ADMIN_LABEL_PAGE: 'Page',
  ADMIN_LABEL_VIDEO_OR_PLAYLIST: 'Video Or Playlist',
  ADMIN_LABEL_CHANNEL_TYPE_DESC: 'A large video with a sidebar area',
  ADMIN_LABEL_LANDING_PAGE_NO_CONTENT_TITLE: 'Build a Page',
  ADMIN_LABEL_LANDING_PAGE_NO_CONTENT_DESCRIPTION: 'Create a landing page for your event, a place to discover',
  ADMIN_LABEL_LANDING_PAGE_NO_CONTENT_DESCRIPTION_2: 'content (like the home screen on Netflix, Twitch, or YouTube), and more.',
  ADMIN_LABEL_LOCALE_DROPDOWN_LABEL: 'Base Language',
  ADMIN_LABEL_LOCALE_DROPDOWN_DESCRIPTION:
    'Used for caption generation.',
  ADMIN_LABEL_COMMUNITY_RESULTS_TITLE: 'Find users',
  ADMIN_LABEL_COMMUNITY_SEARCH_SPECIFIC_USER:
    'Search for a specific user or select a segment to filter the user list',
  ADMIN_LABEL_COMMUNITY_ACCOUNT_INFO: 'Search for a User',
  ADMIN_LABEL_COMMUNITY_ENTITLEMENTS: 'Users with Entitlement',
  ADMIN_LABEL_COMMUNITY_FILTER_DEFAULT: 'Filter',
  ADMIN_LABEL_COMMUNITY_FILTER_PLACEHOLDER: 'Search by Name or Email Address',
  ADMIN_LABEL_COMMUNITY_ACCESS_CODE_PLACEHOLDER: 'Type an access code',
  ADMIN_LABEL_COMMUNITY_RECEIPT_NUMBER_PLACEHOLDER: 'Type a receipt number',
  ADMIN_LABEL_COMMUNITY_PAID_ENTITLEMENTS: 'Users who purchased Entitlement',
  ADMIN_LABEL_COMMUNITY_USERS_WHO_LOGGED_IN: 'Users who logged in',
  ADMIN_LABEL_COMMUNITY_TIME_PERIOD: 'Time Period',
  ADMIN_LABEL_COMMUNITY_GRANT_TICKET_BUNDLE_SUBSCRIPTION: 'Grant',
  ADMIN_LABEL_COMMUNITY_GRANT_TICKET_BUNDLE_SUBSCRIPTION_DESCRIPTION:
    'You can directly grant one or more tickets, bundles or subscriptions to this customers account by clicking grant ticket or subscription above. Search through the list of available tickets, bundles or subscriptions and then simply click to manually grant it to this customers account, it will be effective immediately.',
  ADMIN_LABEL_COMMUNITY_TICKETS_TITLE: 'Tickets',
  ADMIN_LABEL_COMMUNITY_TICKETS_DESCRIPTION: 'Pertaining to one-time SKUs',
  ADMIN_LABEL_COMMUNITY_SUBSCRIPTIONS: 'Subscriptions',
  ADMIN_LABEL_COMMUNITY_SUBSCRIPTIONS_DESCRIPTION:
    'Pertaining to monthly or annually recurring SKUs',
  TIME_PERIOD_START_DATE: 'Start Date',
  TIME_PERIOD_START_TIME: 'Start Time',
  TIME_PERIOD_END_DATE: 'End Date',
  TIME_PERIOD_END_TIME: 'End Time',
  ADMIN_LABEL_REMOVE_ENTITLEMENT_TITLE_MODAL:
    'Are you sure you want to remove this Entitlement',
  ADMIN_LABEL_REMOVE_ENTITLEMENT_SUBTITLE_MODAL:
    'You will not be able to undo this action',
  ADMIN_LABEL_LANDING_TYPE_DESC:
    'A grid of content with optional spotlight images',
  ADMIN_LABEL_LANDING_PAGE: 'Landing Page',
  ADMIN_LABEL_CONTENT_DESC:
    'Content will appear in rows on the landing page. Go to EDIT MODE to edit and preview content on the page.',
  ADMIN_LABEL_CHOOSE_PAGE_TYPE: 'Choose a Page Type',
  ADMIN_LABEL_SHOPIFY_STORE: 'Connected store',
  ADMIN_LABEL_DEVELOPER: 'Developer',
  ADMIN_CARD_SHOPIFY_INFO: 'Sales channel for your Shopify products',
  ADMIN_CARD_SHOPIFY_NAME: 'Shopify',
  ADMIN_SHOPIFY_V2: 'Shopify',
  ADMIN_CARD_SHOPIFY_CONNECTED_STORE: 'Connected store',
  ADMIN_CARD_SHOPIFY_FEATURE_ITEM: 'Feature item',
  ADMIN_CARD_SHOPIFY_FEATURE_PRODUCT: 'Feature product',
  ADMIN_CARD_SHOPIFY_FEATURE_COLLECTION: 'Feature collection',
  ADMIN_CARD_SHOPIFY_SELECT_PRODUCT: 'Select product',
  ADMIN_CARD_SHOPIFY_SELECT_COLLECTION: 'Select collection',
  ADMIN_LABEL_SHOPIFY_OVERLAY_NAME: 'Overlay Name',
  ADMIN_LABEL_SHOPIFY_PRODUCT: 'Product',
  ADMIN_LABEL_SHOPIFY_VARIANT: 'Variant',
  ADMIN_LABEL_SHOPIFY_DISCONNECT_CONFIRM:
    'Are you sure you want to disconnect Maestro from your Shopify store? This will not uninstall the sales channel from your Shopify store.',
  ADMIN_LABEL_UNNAMED_TAB: 'Unnamed Tab',
  ADMIN_LABEL_APP_DISCONNECT_CONFIRM:
    'Are you sure you want to disconnect Maestro from this app?',
  ADMIN_CARD_SHOPIFY_NOT_CONNECTED_TITLE: 'Store',
  SHOPIFY_PRODUCTS_LIST_SHOPIFY_NOT_CONNECTED:
    'No Store connected yet. Please connect a Store',
  ADMIN_CARD_SHOPIFY_RECURRING_CHARGE_MISSING_STEP: 'You are missing one more step',
  ADMIN_CARD_SHOPIFY_RECURRING_CHARGE_MISSING_STEP_DESCRIPTION_1: 'You successfully connected your Shopify store:',
  ADMIN_CARD_SHOPIFY_RECURRING_CHARGE_MISSING_STEP_DESCRIPTION_2: 'The last step to start selling is to accept the commission charge which be automatically deducted from the transaction subtotal (before shipping and taxes).',
  ADMIN_CARD_SHOPIFY_RECURRING_CHARGE_CONFIRMATION_LINK: 'Go to Shopify to Accept Commission ',
  ADMIN_CARD_SHOPIFY_NOT_CONNECTED_DESCRIPTION:
    'There aren’t any Shopify stores connected. Install our Shopify app to get started and you’ll be ready to start selling in a few minutes.',
  ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTRUCTIONS_TEXT:
    'For more detailed instructions, check out our ',
  ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTRUCTIONS_LINK: 'help center article.',
  ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTALL_APP: 'Install Shopify App',
  ADMIN_CARD_SHOPIFY_INSTALLED_APP:
    'Your shopify store was successfully connected.',
  CONNECT_NEW_STORE: 'Connect New Store',
  SOLD_OUT: 'Sold Out',
  SHOPIFY_PRODUCTS_LIBRARY_DESCRIPTION:
    'Choose a product from a connected store. Don’t see a product? Find tips in our <a target="_blank" href="https://support.maestro.io">help center</a>.',
  SEARCH_SOMETHING: 'Search something',
  ADMIN_CARD_SHOPIFY_DISCONNECT: 'DISCONNECT',
  LABEL_AIRPLAY: 'Airplay',
  LABEL_DISABLE_AIRPLAY: 'Turn Airplay off',
  LABEL_CHROMECAST: 'Chromecast',
  LABEL_CASTING: 'Casting',
  LABEL_CASTING_TO: 'Casting to',
  LABEL_STOP_CASTING: 'Stop Casting',
  LABEL_ADD_VIDEO_PLAYLIST: 'Add Video Or Playlist',
  LABEL_PERSON_TITLE_PLACEHOLDER: 'type the name of the person',
  LABEL_PERSON_SEARCH_PLACEHOLDER: 'Search for a Person',
  LABEL_MULTIPLE_CHOICE_TITLE_PLACEHOLDER:
    'Give a name to your Poll, trivia or prediction.',
  ADMIN_LABEL_POLL_EDITOR_QUESTION_PLACEHOLDER:
    'Who is the MVP of the Tournament?',
  LABEL_ADD_VIDEO: 'Add Video',
  LABEL_ADD_PLAYLIST: 'Add Playlist',
  LABEL_BUTTON_TEXT: 'Button Text',
  LABEL_CONTENT: 'Content',
  LABEL_MATCH_SCOREBOARD: 'Match Scoreboard',
  ADMIN_LABEL_PUBG_MATCH: 'PUBG Match',
  ADMIN_LABEL_PRODUCT_NAME: 'Product Name',
  ADMIN_LABEL_RETURN_POLICY: 'Return Policy',
  ADMIN_LABEL_PRODUCTS_PER_ROW: 'Products Per Row',
  ADMIN_PRODUCT_VIEW: 'Product View',
  LABEL_TEAM: 'Team',
  LABEL_PLAYER: 'Player',
  LABEL_RANK: 'Rank',
  LABEL_MATCH: 'Match',
  LABEL_MARKETING_OPT_IN: 'I would like to receive future updates',
  LABEL_OF: 'of',
  LABEL_COPY: 'Copy',
  LABEL_COPY_LINK: 'Copy Link',
  LABEL_COPY_EMBED: 'Copy Embed',
  LABEL_SHARE_LINK: 'Share Link',
  LABEL_COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  LABEL_GAME: 'Game',
  LABEL_ENABLED: 'Enabled',
  LABEL_DISABLED: 'Disabled',
  LABEL_FEATURED_COLLECTION: 'Featured Collection',
  LABEL_NEW_POLL: 'New Poll',
  LABEL_CREATE_BUNDLE: 'Create Bundle',
  LABEL_ADD_BLOCK: 'Add Block',
  LABEL_HERO_BANNER: 'Hero Banner',
  LABEL_TEXT_BLOCK: 'Text Block',
  LABEL_EVENT_CALENDAR: 'Event Calendar',
  LABEL_FAQ_BLOCK: 'FAQ Block',
  LABEL_VIDEO_SPOTLIGHT: 'Video Spotlight',
  LABEL_CHANNEL_NAVIGATOR: 'Channel Navigator',
  LABEL_EMBED_CODE: 'Embed Code',
  ADMIN_LABEL_ADD_ANOTHER_BENEFIT: 'Add Another Benefit',
  ADMIN_LABEL_COPY_LINK: 'Copy Link',
  ADMIN_LABEL_DONATIONS: 'Donations',
  ADMIN_LABEL_DOMAIN: 'Domain',
  ADMIN_LABEL_TOURNAMENT_ID: 'Tournament id',
  ADMIN_LABEL_FORTNITE_DUOS_LEADERBOARD: 'Duos Leaderboard (Fortnite)',
  ADMIN_LABEL_FORTNITE_DUOS_MATCH: 'Duos Match (Fortnite)',
  ADMIN_LABEL_FORTNITE_SINGLES_MATCH: 'Singles Match (Fortnite)',
  ADMIN_LABEL_GAME_ID: 'Game ID',
  ADMIN_LABEL_EXPORT: 'Export',
  ADMIN_LABEL_DOWNLOAD: 'Download',
  ADMIN_ERROR_EXPORT_FAILED:
    'Export service temporarily unavailable. Please try again later.',
  ADMIN_LABEL_GENERATE_CSV: 'Generate CSV',
  ADMIN_LABEL_LAST_EXPORT: 'Last Export',
  ADMIN_HELPER_CSV_GENERATION:
    'CSV generation  may take a ffew minutes. Only one CSV can be generated at a time. Download link will become available when the file is ready.',
  ADMIN_LABEL_CHAT_EXPORT: 'Chat Export',
  ADMIN_LABEL_CHAT_EXPORT_HELP:
    'Export a CSV file of chat messages posted in a specific date range.',
  ADMIN_HELPER_EXPORT_ACHIEVEMENTS:
    `Export a CSV file of the leaderboard over a specific date range. End date cannot be today's date.`,
  ADMIN_LABEL_SELECT_TIME_RANGE: 'Select a time range',
  ADMIN_LABEL_SELECT_DATE: 'Select Date',
  ADMIN_LABEL_SELECT_MONTH: 'Select Month',
  ADMIN_LABEL_SELECT_YEAR: 'Select Year',
  ADMIN_LABEL_SELECT_WEEK: 'Select Week',
  ADMIN_LABEL_SELECT_QUARTER: 'Select Quarter',
  ADMIN_LABEL_GO_LIVE: 'Go Live',
  ADMIN_EXIT_STREAM_MODE: 'Exit Stream Mode',
  ADMIN_LABEL_GO_OFFLINE: 'Go Offline',
  ADMIN_LABEL_DATE_FORMAT: 'YYYY/MM/DD',
  ADMIN_LABEL_EXPORT_CSV: 'Export CSV',
  ADMIN_LABEL_GENERATING: 'Generating',
  ADMIN_LABEL_UNTITLED_POLL: '(Untitled Poll)',
  ADMIN_LABEL_LEADERBOARD: 'Leaderboard',
  ADMIN_LABEL_LEADERBOARD_V2: 'Leaderboard V2',
  ADMIN_LABEL_EARN_POINTS: 'How to earn points',
  ADMIN_HELPER_EARN_POINTS:
    `Users see this when they click the 'Earn Points' button on the leaderboard. The tasks that appear are configured in the Achievements tab of the admin menu.`,
  ADMIN_DESC_LEADERBOARD: 'Display Top 10 point earners for your site',
  ADMIN_STREAM_GO_OFFLINE_TITLE: 'Congratulations on your Stream!',
  ADMIN_STREAM_GO_OFFLINE_DESCRIPTION:
    'Your stream is now in the process of being saved as a VOD to your library and will be available soon.  If you would like to give it a name and a thumbnail image, you can do this now, otherwise click skip and you can do this later from the library.',
  ADMIN_STREAM_GO_OFFLINE_SUCCESS:
    'Your video information was successfully saved, and will soon be available in your library',
  FIELD_TEST_LABEL: 'field test',
  FIELD_TEST_TEXT: 'field test',
  ACCOUNT_LOOKUP_MODAL_DESCRIPTION:
    'Enter your email to log in or create an account.',
  ACCESS_CONTROL: 'Access Control',
  ACCESS_CONTROL_AMAZONBENEFIT_BENEFITS: 'Benefits',
  ACCESS_CONTROL_AMAZONBENEFIT_BENEFIT_URL: 'Benefit URL',
  ACCESS_CONTROL_AMAZONBENEFIT_BENEFIT_ID: 'Benefit ID',
  ACCESS_CONTROL_AMAZONBEENFIT_SETTING_DESCRIPTION: 'Users must own a digital token with the associated benefit(s) in their Amazon accounts to gain access.',
  ACCESS_CONTROL_AMAZONBENEFIT_ID: 'What is the benefit ID?',
  ACCESS_CONTROL_AMAZONBENEFIT_PURCHASE_URL: 'Where can the user purchase the benefit?',
  ACCESS_CONTROL_AMAZONBENEFIT_REDEEM_WINDOW_START: 'When does the benefit redeem window start?',
  ACCESS_CONTROL_AMAZONBENEFIT_REDEEM_WINDOW_END: 'When does the benefit redeem window end?',
  ACCESS_CONTROL_AMAZONBENEFIT_ID_ERROR: 'Benefit ID is invalid',
  ACCESS_CONTROL_AMAZONBENEFIT_PURCHASE_URL_ERROR: 'Benefit purchase URL is invalid',
  ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_LABEL: 'Instructions',
  ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_1: 'For each Series, copy the below Benefit URL into your Benefit Setup Form',
  ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_2: 'Go to the Creator Portal and upload your Benefit Setup Form',
  ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_3: 'Amazon will send you a list of Benefit IDs',
  ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_4: 'Return here and paste the Benefit ID for each Series/Benefit URL',
  ACCESS_CONTROL_DESCRIPTION: 'Choose your method below.',
  ACCESS_CONTROL_VIDEO_DESCRIPTION: 'Choose how users can access this video',
  ACCESS_CONTROL_LOGIN_DESCRIPTION:
    'Users are required to create a new account or log in to an existing account.',
  ACCESS_CONTROL_TICKET_BUNDLE_SUBSCRIPTION_DESCRIPTION:
    'Users must have a ticket, bundle or active subscription to access, either purchased directly or obtained via another method.',
  ACCESS_CONTROL_TICKET_BUNDLE_SUBSCRIPTION_TITLE:
    'Ticket, Bundle or Subscription',
  ACCESS_CONTROL_PASSWORD_DESCRIPTION:
    'Users are required to enter a password.',
  ACCESS_CONTROL_PASSWORD_SETTING_DESCRIPTION:
    'Any user with this password will be able to gain access. Minimum 8 characters.',
  ACCESS_CONTROL_PASSWORD_ERROR:
    'Password is invalid, must be at least 8 characters',
  ACCESS_CONTROL_NO_VISIBLE_ENTITLEMENTS_WARNING: 'With this setup, viewers who already have access can get in, but no new purchases will be allowed',
  ACCESS_CONTROL_SHOW_ADVANCED_OPTIONS: 'Show Advanced Options',
  ACCESS_CONTROL_HIDE_ADVANCED_OPTIONS: 'Hide Advanced Options',
  ACCESS_CONTROL_ACCESS_CODE_ONLY: 'Access Codes Only (Advanced)',
  ACCESS_CONTROL_ACCESS_CODE_TOOLTIP:
    'Enable this <span style="font-style: italic;">only</span> if users won’t make purchases through Maestro and instead will enter a  code you give them directly. <a style="color: #5A67F2" target="_blank" href="https://support.maestro.io/knowledge/how-do-access-codes-work">Learn More.</a>',
  ACCESS_CONTROL_NO_TICKET_BUNDLE_OR_SUBSCRIPTION:
    `You don't have any ticket, bundle or subscription yet`,
  ACCESS_CONTROL_DONE: 'DONE',
  ACCESS_CONTROL_NEXT: 'NEXT',
  ACCESS_CONTROL_CHANGE_GATE: 'Change',
  ACCESS_CONTROL_LOGIN_TITLE: 'Log In',
  ACCESS_CONTROL_AMAZON_BENEFIT_TITLE: 'Amazon Benefit',
  ACCESS_CONTROL_SUBSCRIPTION_TITLE: 'Ticket, Subscription or Bundle',
  ACCESS_CONTROL_AMAZON_BENEFIT_DESCRIPTION: 'Users are required to have a Benefit purchased to access the content',
  ACCESS_GATE_AMAZON_BENEFIT_INSTRUCTION: 'Unlock your token benefit',
  ACCESS_GATE_AMAZON_BENEFIT_PURCHASE_BUTTON: 'Purchase Benefit',
  ACCESS_GATE_AMAZON_BENEFIT_NOT_STARTED: 'Unlock this benefit starting on',
  ACCESS_GATE_AMAZON_BENEFIT_EXPIRED: 'This Benefit expired on',
  ACCESS_GATE_AMAZON_BENEFIT_PURCHASE_ERROR: 'You have not purchased the required Amazon Benefit',
  ACCESS_GATE_AMAZON_BENEFIT_REDEMPTION_ERROR: 'There was an error redeeming your Amazon Benefit',
  ACTION_ADD: 'Add',
  ACTION_DELETE: 'Delete',
  ACTION_DELETE_EVENT: 'Delete Event',
  ACTION_ADD_AN_ICON: 'Add an icon',
  ACTION_ADD_ICON: 'Add Icon',
  ACTION_ADD_LINK: 'Add Link',
  ACTION_DELETE_IMAGE_CARD: 'Delete Image Card',
  ACTION_ACCEPT_TERMS: 'Accept Cookies',
  ACTION_REJECT_TERMS: 'Reject',
  ACTION_CC: 'CC',
  ACTION_CC_OFF: 'Closed captioning (off)',
  ACTION_CC_ON: 'Closed captioning (on)',
  ACTION_CLOSE_VIDEO: 'Close video',
  ACTION_CONFIRM: 'Yes',
  ACTION_DENY: 'No',
  ACTION_DISABLE_FULLSCREEN: 'Exit fullscreen',
  ACTION_ENTER_RAFFLE: 'Submit Entry',
  ACTION_FULLSCREEN: 'Fullscreen',
  ACTION_THEATER_MODE: 'Theater Mode',
  ACTION_DISABLE_THEATER_MODE: 'Exit Theater Mode',
  ACTION_GO_TO_CHANNEL: 'Go to channel',
  ACTION_EDIT_CHANNEL: 'Edit Channel',
  ACTION_EDIT_VIDEO: 'Edit Video',
  ACTION_EDIT_PAGE: 'Edit Page',
  ACTION_GO_TO_HUB: 'Go to hub',
  ACTION_HIDE_VIDEO: 'Hide video',
  ACTION_INFO: 'Info',
  ACTION_LOG_IN: 'Log in',
  ACTION_LOG_OUT: 'Log out',
  ACTION_MORE: 'More',
  ACTION_NEW_LINK: 'New Link',
  ACTION_NEXT: 'Next',
  ACTION_PAUSE_VIDEO: 'Pause video',
  ACTION_PLAY_VIDEO: 'Play video',
  ADMIN_LABEL_PLAY: 'Play',
  ADMIN_LABEL_PAUSE: 'Pause',
  ADMIN_LABEL_MUTE: 'Mute',
  ADMIN_LABEL_UNMUTE: 'Unmute',
  ACTION_SAVE: 'Save',
  ACTION_SHOW_VIDEO: 'Show video',
  ADMIN_SIDEBAR_SHEET_INFO: 'Set a card or add panels to your sidebar.',
  ADMIN_SIDEBAR_SHEET_TITLE: 'Sidebar',
  ADMIN_SIDE_BAR_SHEET_TITLE: 'Side Bar',
  ADMIN_SIDEBAR_SETTINGS: 'Sidebar Settings',
  ACTION_SIGN_UP: 'Sign up',
  AGREE_TERMS_OF_USE: `I agree to Maestro's `,
  ACTION_LOGIN_UP_HELPER: 'I agree to the ',
  ACTION_VOTE_NOW: 'Vote now!',
  ADMIN_ANALYTICS_HERE_IS_YOUR_REPORT: 'Here is your analytics report',
  ADMIN_LABEL_JUST_NOW: 'Just Now',
  ADMIN_ANON_HELPER_TEXT:
    'Users participate in chat without having to login. Users get assigned a random user number and are asked to login after 5 mins of activity.',
  ADMIN_ANALYTICS_GREAT_JOB_TEAM: 'Great Job Team!',
  ADMIN_ACTION_ADD: 'Add',
  ADMIN_ACTION_ADD_ACTION: 'Add Action',
  ADMIN_ACTION_ADD_LIVESTREAM: 'Add Live Stream',
  ADMIN_ACTION_DELETE_OVERLAY: 'Delete overlay',
  ADMIN_ACTION_ACTIVE_TAB: 'Active',
  ADMIN_ACTION_EXPIRED_TAB: 'Expired',
  ADMIN_ACTION_ENTER_CHANNEL: 'Go to Channel',
  ADMIN_ACTION_ADD_A_SPONSOR: 'Add a sponsor',
  ADMIN_ACTION_ADD_TO_CHANNEL: 'Add to Channel',
  ADMIN_ACTION_BACK_TO_LIBRARY: 'Back to Library',
  ADMIN_ACTION_BACK_TO_PLAYLIST: 'Back to Playlist',
  ADMIN_ACTION_CLICK_TO_SELECT_A_FILE: 'Click to select a file',
  ADMIN_ACTION_CLOSE: 'Close',
  ADMIN_ACTION_CONFIGURE_FOOTER_LINK: 'Add text or a link to your footer',
  ADMIN_ACTION_CONFIGURE_HASHTAG: 'Configure your hashtag',
  ADMIN_ACTION_COUNTDOWN_ADD_TEXT: 'Add text to the top of your countdown',
  ADMIN_ACTION_CREATE_PAGE: 'Create Channel',
  ADMIN_ACTION_CREATE_LANDING_PAGE: 'Create Page',
  ADMIN_ACTION_ENTER_A_URL: 'Enter a URL…',
  ADMIN_ACTION_SELECT: 'Select',
  ADMIN_ACTION_SET_LIVESTREAM: 'Use Livestream',
  ADMIN_ACTION_PUBLISH: 'Publish',
  ADMIN_ACTION_PUBLISHED: 'Published',
  ADMIN_ACTION_VIEW_CATALOG: 'View catalog',
  ADMIN_ACTION_ADD_FROM_LIBRARY: 'Add from library',
  ADMIN_ACTION_HIDE: 'Hide',
  ADMIN_ACTION_ADD_QUEST: 'Add quest',
  ADMIN_COUNTDOWN_DATE: 'Date',
  ADMIN_CALENDAR_BLOCK_END_TIME_TOOLTIP: `Use End Time for \‘LIVE\’ indicator to appear`,
  ADMIN_CALENDAR_BLOCK_CHOOSE_DATE_AND_TIME: 'Choose Date and Time',
  ADMIN_CALENDAR_BLOCK_EDIT_EVENT: 'Edit Event',
  ADMIN_CALENDAR_BLOCK_DELETE_EVENT: 'Delete Event',
  ADMIN_PLACEHOLDER_TRIVIA: 'Select trivia...',
  ADMIN_PLACEHOLDER_POLL: 'Select poll...',
  ADMIN_PLACEHOLDER_POLL_RESULTS: 'Select poll result...',
  ADMIN_COUNTDOWN_SCHEDULE_PICKER: 'Thing To Reference',
  ADMIN_COUNTDOWN_START_TIME: 'Start Time',
  ADMIN_LABEL_START_CALENDAR: 'Start Calendar',
  ADMIN_LABEL_END_CALENDAR: 'End Calendar',
  ADMIN_LABEL_START_HOUR: 'Start Hour',
  ADMIN_LABEL_END_HOUR: 'End Hour',
  ADMIN_LABEL_PROVIDER: 'Provider',
  ADMIN_COUNTDOWN_TOGGLE_CUSTOM: 'Custom',
  ADMIN_COUNTDOWN_TOGGLE_REFERENCE: 'Reference',
  ADMIN_ACTION_SET_CARD: 'Set card',
  ADMIN_ACTION_SET_NEW_CARD: 'Change Card',
  ADMIN_ACTION_CURRENT_CARD: 'Currently Active Card',
  ADMIN_ACTION_ADD_PANELS: 'Add Panels',
  ADMIN_ACTION_CURRENT_PANELS: 'Currently Active Panels',
  ADMIN_ACTION_IMPOSSIBLE_ACTION: 'Impossible Action',
  ADMIN_ACTION_ADD_TO_CALENDAR: 'Add to Calendar',
  ADMIN_ADD_TO_CART: 'Add to Cart',
  ADMIN_ACTION_VISIT_CHANNEL: 'Visit Channel',
  ADMIN_ACTION_WATCH_VIDEO: 'Watch Video',
  ADMIN_ACTION_WATCH_ANY_VIDEO: 'Watch Any Video',
  ADMIN_ACTION_WATCH_SPEFCIFIC_VIDEO: 'Watch Specific Video',
  ADMIN_ACTION_RETWEET: 'Retweet',
  ADMIN_ACTION_FACEBOOK_SHARE: 'Facebook',
  ADMIN_ACTION_TWITTER_SHARE: 'Twitter',
  ADMIN_ACTION_VISIT_TIME: 'Visit Time',
  ADMIN_ACTION_ANSWER_TRIVIA: 'Answer Trivia',
  ADMIN_ACTION_ANSWER_ANY_TRIVIA: 'Answer Any Trivia',
  ADMIN_ACTION_ANSWER_ANY_TRIVIA_CORRECTLY: 'Answer Any Trivia Correctly',
  ADMIN_ACTION_ANSWER_SPECIFIC_TRIVIA: 'Answer Specific Trivia',
  ADMIN_ACTION_ANSWER_SPECIFIC_TRIVIA_CORRECTLY:
    'Answer Specific Trivia Correctly',
  ADMIN_ACTION_ANSWER_POLL: 'Answer Poll',
  ADMIN_ACTION_ANSWER_ANY_POLL: 'Answer Any Poll',
  ADMIN_ACTION_ANSWER_SPECIFIC_POLL: 'Answer Specific Poll',
  ADMIN_ACTION_ANSWER_PREDICTION: 'Answer Prediction',
  ADMIN_ACTION_ANSWER_ANY_PREDICTION: 'Answer Any Prediction',
  ADMIN_ACTION_ANSWER_ANY_PREDICTION_CORRECTLY:
    'Answer Any Prediction Correctly',
  ADMIN_ACTION_ANSWER_SPECIFIC_PREDICTION: 'Answer Specific Prediction',
  ADMIN_ACTION_ANSWER_SPECIFIC_PREDICTION_CORRECTLY:
    'Answer Specific Prediction Correctly',
  ADMIN_ACTION_VIEW_PANEL: 'View Panel',
  ADMIN_ACTION_OPEN_LINK: 'Open Link',
  ADMIN_ACTION_UPLOAD_VIDEO: 'Upload video',
  ADMIN_ACTION_SEA_OF_THIEVES_VISIT_ISLAND: 'Visit Island',
  ADMIN_ACTION_SEA_OF_THIEVES_KILL_PLAYER: 'Kill Player',
  ADMIN_ACTION_SEA_OF_THIEVES_KILL_SKELETON: 'Kill Skeleton',
  ADMIN_ANALYTICS_TRENDS_TITLE: 'Trends',
  ADMIN_LABEL_DUPLICATE: 'Duplicate',
  ADMIN_SETTINGS_EVENTS_TITLE: 'Events',
  ADMIN_SETTINGS_COMMUNITY_TITLE: 'Community',
  ADMIN_SETTINGS_ENGAGEMENT_TITLE: 'Engagement',
  ADMIN_SETTINGS_RETENTION_TITLE: 'Retention',
  ADMIN_SETTINGS_ADVERTISING_TITLE: 'Advertising',
  ANALYTICS_LABEL_AUDIENCE: 'Audience',
  ANALYTICS_LABEL_UNIQUE_VIEWERS: 'Unique Viewers',
  ANALYTICS_LABEL_UNIQUE_VISITOR: 'Unique Visitors',
  ANALYTICS_LABEL_LOGGED_IN_USERS: 'Logged in Users',
  ANALYTICS_LABEL_ANONYMOUS_DEVICES: 'Anonymous Devices',
  ANALYTICS_LABEL_PEAK_CONCURRENTS: 'Peak Concurrents',
  ANALYTICS_LABEL_AVERAGE_CONCURRENTS: 'Average Concurrents',
  ANALYTICS_LABEL_VIEWERS_HOURS: 'Viewers Hours',
  ANALYTICS_LABEL_LIVE_HOURS: 'Live Hours',
  ANALYTICS_LABEL_AVERAGE_WATCH_TIME: 'Average Watch Time',
  ANALYTICS_LABEL_SESSIONS: 'Sessions',
  ANALYTICS_LABEL_BROADCAST_HOURS: 'Broadcast Hours',
  ANALYTICS_LABEL_GROSS_EARNINGS: 'Gross Earnings',
  ANALYTICS_LABEL_NET_EARNINGS: 'Net Earnings',
  ANALYTICS_LABEL_MONETIZED_UNIQUES: 'Monetized Uniques',
  ANALYTICS_LABEL_AEPU: 'Average Earnings Per Unique',
  ANALYTICS_LABEL_REVENUE_AEPPU: 'Average Earnings Per Paying Unique',
  ANALYTICS_TIP_UNIQUE_VIEWERS:
    'Our best estimate of the distinct number of people who watched content.',
  ANALYTICS_TIP_UNIQUE_VISITOR:
    'Our best estimate of the distinct number of people who visited your site.',
  ANALYTICS_TIP_VIEWER_HOURS:
    'The total of all Live hours and On Demand hours watched.',
  ANALYTICS_TIP_LOGGED_IN_USERS:
    'The amount of unique accounts that were authenticated during a session and viewed content.',
  ANALYTICS_TIP_ANONYMOUS_DEVICES:
    'An estimate of how many people who watched content and were not logged in during their session.',
  ANALYTICS_TIP_PEAK_CONCURRENTS:
    'The highest number of simultaneous unique viewers reached in a 60 second window over the period.',
  ANALYTICS_TIP_AVERAGE_CONCURRENTS:
    'The average number of active sessions simultaneously connected over a period.',
  ANALYTICS_TIP_LIVE_HOURS:
    'Hours watched of live streaming, and scheduled video.',
  ANALYTICS_TIP_ON_DEMAND_HOURS:
    'Hours watched of videos made available to watch offline.',
  ANALYTICS_TIP_AVERAGE_WATCH_TIME:
    'Average amount of hours watched of your content.',
  ANALYTICS_TIP_SESSIONS:
    `A defined time period that marks the beginning and end of a user's time spent on your site.`,
  ANALYTICS_TIP_BROADCAST_HOURS:
    'The amount of time content has been sent to a streaming provider for live streaming using the stream feature.',
  ANALYTICS_TIP_GROSS_REVENUE:
    'The total amount earned from viewers after subtracting Receipts, Taxes, and Fees.',
  ANALYTICS_TIP_NET_REVENUE:
    'Total earnings after subtracting Maestro fees and deductions.',
  ANALYTICS_TIP_MONETIZED_UNIQUES:
    'The number of Unique Viewers who spent money on your site. This does not include ecommerce earnings.',
  ANALYTICS_TIP_AEPU:
    'The average amount spent per Unique Visitor on your site.',
  TOPLINE_METRICS: 'Top Line Metrics',
  TOPLINE_METRICS_DESCRIPTION:
    'Here you will find your most important high level analysis of your audience, and their viewing and earnings behaviour on your site.',
  ADMIN_CARD_IMAGE_INFO: 'Display an image and an optional call to action',
  ADMIN_CARD_IMAGE_NAME: 'Image',
  ADMIN_CARD_MESSAGE_INFO:
    'Display a custom message with a title, body, and optional call to action',
  ADMIN_CARD_MESSAGE_NAME: 'Message',
  ADMIN_CARD_PERSON_INFO: 'Highlight a person',
  ADMIN_CARD_PERSON_NAME: 'Person',
  ADMIN_PERSON_BUTTON_PLACEHOLDER: 'Select a person',
  ADMIN_GROUP_BUTTON_PLACEHOLDER: 'Select a group',
  ADMIN_POLL_BUTTON_PLACEHOLDER: 'Select a poll',
  ADMIN_TRIVIA_BUTTON_PLACEHOLDER: 'Select a trivia',
  ADMIN_PREDICTION_BUTTON_PLACEHOLDER: 'Select a prediction',
  ADMIN_CARD_POLL_INFO:
    'Drive engagement to a poll, prediction, or trivia question',
  ADMIN_CARD_POLL_NAME: 'Multiple choice',
  ADMIN_CARD_SOCIAL_INFO:
    'Highlight a social media post with images from Twitter or Instagram',
  ADMIN_CARD_SOCIAL_NAME: 'Social',
  ADMIN_OVERLAY_RAFFLE_INFO: 'Select a random user to award a prize',
  ADMIN_OVERLAY_RAFFLE_NAME: 'Raffle',
  ADMIN_OVERLAY_TWEET_INFO: 'Highlight a tweet, images are shown as well',
  ADMIN_OVERLAY_TWEET_NAME: 'Tweet',
  ADMIN_OVERLAY_HIGHLIGHT_INFO: `Highlight a player's action`,
  ADMIN_OVERLAY_HIGHLIGHT_NAME: 'Highlight',
  ADMIN_OVERLAY_MESSAGE_INFO: 'A message with an optional call to action',
  ADMIN_OVERLAY_MESSAGE_NAME: 'Message',
  ADMIN_OVERLAY_MESSAGE_PLACEHOLDER: 'max: 140 characters',
  ADMIN_OVERLAY_POLL_HELP: 'Poll to use',
  ADMIN_OVERLAY_POLL_INFO: 'See what your audience is thinking',
  ADMIN_OVERLAY_POLL_NAME: 'Poll',
  ADMIN_OVERLAY_PREDICTION_HELP: 'Prediction to use',
  ADMIN_OVERLAY_PREDICTION_INFO: 'See what your audience predicts will happen',
  ADMIN_OVERLAY_PREDICTION_NAME: 'Prediction',
  ADMIN_OVERLAY_TRIVIA_HELP: 'Trivia to use',
  ADMIN_OVERLAY_TRIVIA_INFO: 'Test your audience with trivia',
  ADMIN_OVERLAY_TRIVIA_NAME: 'Trivia',
  ADMIN_OVERLAY_POLL_RESULTS_INFO: 'Show the results of a previously used poll',
  ADMIN_OVERLAY_POLL_RESULTS_NAME: 'Poll Results',
  ADMIN_OVERLAY_LOWER_THIRD_INFO:
    'An image on the bottom third of the video, can have a CTA',
  ADMIN_OVERLAY_LOWER_THIRD_NAME: 'Lower Third',
  ADMIN_OVERLAY_SHOPIFY_INFO:
    'An image of your shopify product on the bottom third of the video',
  ADMIN_OVERLAY_SHOPIFY_NAME: 'Shopify',
  ADMIN_OVERLAY_INSTAGRAM_INFO: 'Highlight an instagram photo',
  ADMIN_OVERLAY_INSTAGRAM_NAME: 'Instagram',
  ADMIN_OVERLAY_COMMERCE_INFO: 'Surface a product from your library',
  ADMIN_OVERLAY_COMMERCE_NAME: 'Commerce',
  ADMIN_OVERLAY_THEME_NAME: 'Theme',
  ADMIN_CARD_INSTAGRAM_INFO: 'Highlight an Instagram post',
  ADMIN_CARD_INSTAGRAM_NAME: 'Instagram',
  ADMIN_CARD_TWEET_INFO: 'Display a full Tweet including images',
  ADMIN_CARD_TWEET_NAME: 'Tweet',
  ADMIN_SETTINGS_SHOPIFY: 'Shopify',
  ADMIN_SETTINGS_BILLING: 'Billing Settings',
  ADMIN_SETTINGS_BILLING_TERMS_HEADER: 'Terms',
  ADMIN_SETTINGS_BILLING_ADD_STRIPE_ACCOUNT: 'Add Stripe Account ID',
  ADMIN_SETTINGS_BILLING_STRIPE_ACCOUNT_ID: 'Stripe Account ID',
  ADMIN_SETTINGS_BILLING_PAYMETHOD_ADD: 'Add',
  ADMIN_SETTINGS_BILLING_PAYMETHOD_REMOVE: 'REMOVE',
  ADMIN_SETTINGS_BILLING_PAYMETHOD_SUBMIT: 'Submit',
  ADMIN_SETTINGS_BILLING_PAYMENT_METHOD_HEADER: 'Payment Methods',
  ADMIN_SETTINGS_BILLING_SUBSCRIPTIONS_HEADER: 'Subscriptions',
  ADMIN_SETTINGS_BILLING_TICKETS_HEADER: 'Tickets',
  ADMIN_SETTINGS_BILLING_TICKETS_EMPTY: 'No Current Tickets',
  ADMIN_SETTINGS_BILLING_SUBSCRIPTIONS_EMPTY: 'No Current Subscriptions',
  ADMIN_SETTINGS_BILLING_PAY_HEADER_TITLE: 'How you pay',
  ADMIN_SETTINGS_BILLING_PAY_HEADER_TEXT:
    'Edit your payment details, add an additional, or switch your primary payment method.',
  ADMIN_SETTINGS_BILLING_HISTORY_HEADER_TITLE: 'Billing history',
  ADMIN_SETTINGS_BILLING_HISTORY_TITLE: 'Invoices',
  ADMIN_SETTINGS_BILLING_HISTORY_TEXT:
    'Invoices will be sent to the account email that signed up for Masestro. Alternatively you can specify a different email address for receiving invoices (optional)',
  ADMIN_SETTINGS_BILLING_PAYOUT_STRIPE_BUTTON: 'Connect with',
  ADMIN_SETTINGS_BILLING_PAYOUT_STRIPE_CONNECTED: 'Stripe Account Connected',
  ADMIN_SETTINGS_BILLING_PAYOUT_VIEW_HISTORY: 'View your payout history',
  ADMIN_SETTINGS_BILLING_PAYOUT_VIEW_STRIPE_ACCOUNT: 'View your stripe account',
  ADMIN_SETTINGS_BILLING_PAYOUT_HEADER_TITLE: 'Payouts',
  ADMIN_SETTINGS_BILLING_PAYOUT_METHOD_HEADER: 'Payout Method',
  ADMIN_SETTINGS_BILLING_PAYOUT_METHOD_TIP:
    `You'll be redirected to Stripe to complete the onboarding process.`,
  ADMIN_SETTINGS_BILLING_PAYMENT_METHOD_ADDED:
    'New payment method successfully added!',
  ADMIN_SETTINGS_BILLING_NO_PAYMENT_METHOD: 'No payout method found',
  ADMIN_SETTINGS_BILLING_PENDING_PAYOUTS: 'Pending Payouts',
  ADMIN_SETTINGS_BILLING_SUBTITLE:
    'View your current plan, manage your payment method, and setup payouts.',
  ADMIN_SETTINGS_BILLING_PLAN_HEADER_TITLE: 'YOUR PLAN',
  ADMIN_SETTINGS_BILLING_PLAN_NEXT_BILL: 'Your next bill',
  ADMIN_SETTINGS_BILLING_PLAN_FREE_TRIAL_PURCHASE_ERROR:
    'Payment transaction failed, please try again',
  ADMIN_SETTINGS_BILLING_PLAN_FREE_TRIAL_EXPIRES: 'Free trial expiration date',
  ADMIN_SETTINGS_BILLING_PLAN_FREE_TRIAL_SUCCESS:
    'Success! Visitors can view your site and you can go live anytime.',
  ADMIN_SETTINGS_BILLING_UPGRADE_PLAN: 'UPGRADE PLAN',
  ADMIN_SETTINGS_BILLING_PLAN_FREE_TRIAL_BUY_PLAN: 'BUY PLAN',
  ADMIN_SETTINGS_BILLING_EXPIRED_FREE_TRIAL_MESSAGE:
    'Your free trial has expired',
  ADMIN_SETTINGS_BILLING_PLAN_FREQUENCY: 'Frequency',
  ADMIN_SETTINGS_BILLING_CHANGE_PLAN_TIP:
    'If you want to change plans, <a target="_blank" href="https://info.maestro.io/pricing" data-testid="billingViewPlans">view them here</a> and contact us.',
  ADMIN_SETTINGS_BILLING_STRIPE_PAYOUT_TEXT:
    'Connect your Stripe account to enable automatic payouts and transfer earnings to your bank account. For more details on payouts, including payout schedules, visit the <a target="_blank" href="https://support.maestro.io/knowledge/paywall-payouts">help center.</a>',
  ADMIN_SETTINGS_BILLING_CANCEL_ACCOUNT:
    'Do you want to cancel your account? <u>Click here</u> to send a request.',
  ADMIN_SETTINGS_BILLING_ENTERPRISE_PLAN_NAME: 'CUSTOM PLAN',
  ADMIN_LABEL_PRIMARY_UPDATED: 'Primary payment method updated!',
  ADMIN_LABEL_ADD_NEW_PAYMENT_METHOD: 'Add a new Payment Method',
  ADMIN_LABEL_REMOVE_PAYMENT_METHOD: 'Payment method updated!',
  ADMIN_LABEL_CARD_ENDING_IN: 'Card ending in',
  ADMIN_SELECT_PAYMENT_METHOD: 'Select payment method',
  ADMIN_SETTINGS_BILLING_PAYMENT_FREQUENCY: 'Payment Frequency',
  ADMIN_SETTINGS_BILLING_NEXT_BILL_DATE: 'Next Bill Date',
  ADMIN_SETTINGS_BILLING_CURRENT_INVOICE_HEADER: 'Current Invoice',
  ADMIN_SETTINGS_BILLING_AMOUNT_DUE: 'Amount Due',
  ADMIN_SETTINGS_BILLING_STATUS: 'Status',
  ADMIN_SETTINGS_BILLING_VIEW_INVOICE: 'View',
  ADMIN_SETTINGS_BILLING_NO_INVOICE: 'Invoice is not currently available',
  ADMIN_SETTINGS_CONCURRENTS_TITLE: 'Concurrents',
  ADMIN_SETTINGS_CUSTOMER_PROFILE: 'Customer Profile',
  ADMIN_SETTINGS_CUSTOMER_PROFILE_HELPER_TEXT:
    'The Customer Name and Customer Logo will appear on the receipt sent to viewers who purchase a ticket or entitlement.',
  ADMIN_SETTINGS_CUSTOMER_PROFILE_TITLE: 'Customer Profile Settings',
  ADMIN_SETTINGS_LOCALIZATION_TITLE: 'Localization Settings',
  ADMIN_SETTINGS_LOGIN: 'Log In Settings',
  ADMIN_SETTINGS_DEFAULT_LANGUAGE_TITLE: 'Default Language',
  ADMIN_SETTINGS_USER_PROFILES: 'User Profiles',
  ADMIN_SITE_SETTINGS_ACCESS_INSTRUCTIONS_HELPER_TEXT:
    'Access Instructions (Optional)',
  ADMIN_SITE_SETTINGS_ACCESS_INSTRUCTIONS_PLACEHOLDER: 'Enter access instructions',
  ADMIN_SITE_SETTINGS_ACCESS_INSTRUCTIONS_DESCRIPTION:
    `Enter specific instructions for access to your events or site. E.g. 'You don't need an access code, just log back into the site to view the concert/event when it starts'`,
  ADMIN_SITE_SETTINGS_ACCESS_INSTRUCTIONS_TEXTAREA_HELPER_TEXT:
    'Maximum 200 characters',
  ADMIN_SITE_SETTINGS_SUPPORT_CONTACT_TITLE_HELPER_TEXT: 'Support Contact',
  ADMIN_SITE_SETTINGS_SUPPORT_CONTACT_SUBTITLE_HELPER_TEXT:
    'A support email is required to use gates and will appear on the receipt sent to viewers who purchase a ticket, bundle or subscription.',
  ADMIN_SITE_SETTINGS_SUPPORT_EMAIL_HELPER_TEXT: 'Support Email',
  ADMIN_SITE_SETTINGS_SUPPORT_LINK_HELPER_TEXT:
    'Support website/link (Optional)',
  ADMIN_TEXT_CHANGE_NAME: 'Allow users to edit username',
  ADMIN_LABEL_GALLERY: 'Gallery',
  ADMIN_LABEL_TODAYS_UPCOMING_EVENTS: 'Today\'s Upcoming Events',
  ADMIN_LABEL_DAY_UPCOMING_EVENTS: makeTranslation('{{weekDay}} {{date}}')<{ date: number; weekDay: string; }>(),
  ADMIN_SETTINGS_USER_PROFILES_HELPER_TEXT:
    'Configure user profile settings, drag and drop to add to the profile image gallery.',
  ADMIN_SETTINGS_USER_PROFILES_TITLE: 'User Profiles Settings',
  ADMIN_SUPPORT_CONTACT_INFO_UNABLE_TO_PUBLISH: 'Unable to publish.',
  ADMIN_SUPPORT_CONTACT_INFO_TITLE: 'Support contact info required',
  ADMIN_SUPPORT_CONTACT_INFO_DESCRIPTION:
    'Before you can add tickets, bundles and subscriptions you must add a support contact email for your customers, in the event that they experience any issues with their purchases.',
  ADMIN_HALT_GATE_CANT_ADD_GATE: 'Can\'t Add a Gate to this Page.',
  ADMIN_HALT_GATE_DESCRIPTION: 'The Terms of Service of Youtube and Twitch \n don\'t allow gates in front of their content. \nPlease remove these videos and try again.',
  ADMIN_HALT_GATE_CTA: 'Got it',
  ADMIN_CUSTOMER_PROFILE_TITLE: 'CUSTOMER PROFILE',
  ADMIN_CUSTOMER_PROFILE_DESCRIPTION:
    'You can add your support contact information, as well as your business name and a logo for your customers receipts in the customer profile menu.',
  ADMIN_TEXT_ADMIN_ONLY_TOGGLE:
    'Make this profile image available only for admins to use',
  ADMIN_LABEL_ADMIN_ONLY: 'Admin Only',
  ADMIN_LABEL_DELETE_PROFILE_IMAGE: 'Delete Profile Image',
  ADMIN_LABEL_DELETE_PROFILE_IMAGE_WARNING:
    'Warning: this action cannot be undone and removes the profile images for all users who are using it',
  ADMIN_LABEL_REMOVE_ENTITLEMENT:
    'WARNING: Users have already purchased this entitlement. Are you sure you want to remove this entitlement from the gate?',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_LABEL: 'Webhooks',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_DESCRIPTION:
    `Webhooks notify your application when certain events occur. Specify a URL to be notified and toggle on the events you'd like to receive.`,
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_URL_TO_NOTIFY_TITLE: 'URL to Notify',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_URL_TO_NOTIFY_PLACEHOLDER:
    'Enter your webhook ingest URL here',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_URL_TO_NOTIFY_BUTTON: 'Test',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENT_SENT: 'Test event sent',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_SAVED: 'Webhook saved!',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_LABEL: 'Events',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_ACCESSCODE: 'Access Code',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_ACCOUNTS: 'Accounts',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_AUTHENTICATION: 'Authentication',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_BILLING: 'Billing',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_CHANNELS: 'Channels',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_POLL: 'Polls',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_SHOPIFY: 'Shopify',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_STREAMING: 'Streaming',
  ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_VIDEO: 'Video',
  ADMIN_SETTINGS_VIDEO_PLAYER: 'Video Player Settings',
  ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT:
    'Configure the video player and the controls below the video.',
  ADMIN_SETTINGS_VIDEO_PLAYER_PLACEHOLDER_CLIENT_ID: 'Enter your Client ID...',
  ADMIN_SETTINGS_VIDEO_PLAYER_PLACEHOLDER_REDIRECT_URI: 'Enter Redirect URI...',
  ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_VIDEO_TITLE: 'Video Title',
  ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_VIDEO_TITLE:
    'Displays the name of the video below the video',
  ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_TWITTER_SHARE: 'Twitter Share',
  ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_TWITTER_SHARE:
    'Show or hide Twitter sharing from the video bar',
  ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_FACEBOOK_SHARE: 'Facebook Share',
  ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_FACEBOOK_SHARE:
    'Show or hide Facebook sharing from the video bar',
  ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_LINK_SHARE: 'Link Share',
  ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_LINK_SHARE:
    'Show or hide Link sharing from the video bar',
  ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_EMBED_SHARE: 'Embed Share',
  ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_EMBED_SHARE:
    'Show or hide Embed sharing from the video bar',
  ADMIN_SETTINGS_YOUTUBE_NO_COOKIE_EMBEDS_TITLE: 'YouTube No-Cookie Embeds',
  ADMIN_SETTINGS_YOUTUBE_NO_COOKIE_EMBEDS_DESCRIPTION: 'Use a version of YouTube embeds that do not use cookies',
  ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_GOOGLE_PLUS_SHARE: 'Google+ Share',
  ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_GOOGLE_PLUS_SHARE:
    'Show or hide Google+ sharing from the video bar',
  ADMIN_SETTINGS_THIRD_PARTY: '3rd Party Apps Settings',
  ADMIN_SETTINGS_CODE_INJECTION_TITLE: 'Code Injection Settings',
  ADMIN_SETTINGS_CODE_INJECTION_HEADER: 'Header',
  ADMIN_SETTINGS_CODE_INJECTION_BODY: 'Body',
  ADMIN_SETTINGS_LANGUAGE_HELPER_TEXT:
    'Allow users to set their own locale, and choose a default language for your site.',
  ADMIN_SETTINGS_LANGUAGE_SELECT_HELPER_TEXT:
    'Allow the users to select their language. The translations will use auto-translate unless a custom language pack has been provided.',
  ADMIN_LABEL_SHOW_CONCURRENTS: 'Show Concurrents',
  ADMIN_LABEL_MIN_CONCURRENTS: 'Minimum Concurrents',
  ADMIN_LABEL_COMPLETED: 'Completed',
  ADMIN_LABEL_SHOW_COMPLETED_COUNT: 'Show number of people who completed',
  ADMIN_LABEL_MODERATION: 'MODERATION',
  ADMIN_LABEL_CONTENT_PRIORITIES: 'Schedule > Live streams > Offline',
  ADMIN_LABEL_CONTENT_PRIORITIES_DESCRIPTION:
    'Video content will appear on your channel based on the following priority:  ',
  ADMIN_LABEL_DELETE_CHANNEL: 'Delete Channel',
  ADMIN_LABEL_DELETE_PAGE: 'Delete Page',
  ADMIN_LABEL_CREATE: 'Create',
  ADMIN_LABEL_OR_HIT_ENTER: 'or hit \'enter\'',
  ADMIN_LABEL_ACCESS_CODES_GENERATE: 'CREATE',
  ADMIN_LABEL_ACCESS_CODES_GENERATE_CREATING: 'CREATING',
  ADMIN_LABEL_ACCESS_CODES_GENERATE_SUCCESS: 'SUCCESS',
  ADMIN_LABEL_ACCESS_CODES_GENERATE_FAILURE: 'Try Again',
  ADMIN_LABEL_ACCESS_CODES_ERROR_MESSAGE: 'Something Went Wrong',
  ADMIN_LABEL_ACCESS_CODE: 'Access Code',
  ADMIN_LABEL_GATE: 'Gate',
  ADMIN_LABEL_ACCESS_CODES_GENERATE_INSTRUCTIONS:
    'Code generation may take a few minutes. When the codes are ready a .csv file containing the codes will automatically start to download.',
  ADMIN_LABEL_DELETE_CHANNEL_WARNING: 'Warning: This action cannot be undone.',
  ADMIN_GLOBAL_NAV_WARNING:
    'Switching back to global navigation will override your current channel-specific links',
  ADMIN_FREE_TRIAL_NAVBAR_WARNING:
    'You’re on a free trial. Visitors can’t see your site and you can’t go live.',
  ADMIN_FREE_TRIAL_EXPIRED_NAVBAR_WARNING:
    'Your free trial has expired. Purchase a plan to regain access.',
  ADMIN_PAST_DUE_NAVBAR_WARNING:
    'Your Maestro subscription is now past due. Update your billing information to keep ownership of your Maestro site.',
  ADMIN_PRIVATE_PAGE_NAVBAR_WARNING:
    'This Page is private so only admins can access it. You can change the visibility in Settings.',
  ADMIN_UNSAVED_CHANGES_WARNING:
    'Hey! You have unsaved changes. Are you sure you want to leave them behind?',
  ADMIN_LABEL_DISCARD_CHANGES: 'Are you sure you want to discard your changes?',
  ADMIN_LABEL_RESET_STREAM_KEY: 'Are you sure you want to refresh your stream key?',
  ADMIN_LABEL_UNDO_WARNING: 'You will not be able to undo this action',
  ADMIN_GLOBAL_SOCIALS_WARNING:
    'Switching back to global socials will override your current channel-specific links',
  ADMIN_HEADER_ENTER_EDIT: 'Go to edit mode',
  ADMIN_HEADER_NO_CHANGE_BUTTON: 'No changes',
  ADMIN_LABEL_DOWNLOADING_CLAIMS: 'Downloading Claims',
  ADMIN_LABEL_SEARCH_ENGINE_OPTIMIZATION_SEO:
    'Search Engine Optimization (SEO)',
  ADMIN_ICON_SEARCH_PLACEHOLDER: 'Search for an icon',
  ADMIN_OVERLAY_SEARCH_PLACEHOLDER: 'Search for an overlay...',
  ADMIN_ICON_PICKER_SEARCH_PLACEHOLDER: 'Enter a URL for your icon',
  ADMIN_ICON_PICKER_PLACEHOLDER: 'No Icon',
  ADMIN_ICON_PICKER_LABEL: 'Icon',
  ADMIN_DESC_CONCURRENTS_TAB:
    'Control when viewers see your concurrent count, displayed on the video player’s footer',
  ADMIN_INFO_NO_CARD: 'No Card set yet',
  ADMIN_INFO_NO_PANELS: 'No Panels added yet',
  ADMIN_UNKNOWN_ERROR: 'An unknown error occurred',
  ADMIN_STREAM_KEY_GET_ERROR: makeTranslation('Could not activate stream: {{errorMessage}}')<{ errorMessage: string }>(),
  ADMIN_IMAGE_LIBRARY_SORT_LABEL: 'Recent Upload',
  ADMIN_IMAGE_LIBRARY_ERROR_UPLOAD: 'Image Could Not Be Uploaded',
  ADMIN_IMAGE_LIBRARY_UPLOAD_TITLE: 'No Images In Library',
  ADMIN_IMAGE_LIBRARY_BUTTON_ADD: 'Upload a new image',
  ADMIN_IMAGE_LIBRARY_TITLE: 'Image Library',
  TEXT_GROUP: 'Text Group',
  PARAGRAPH: 'Paragraph',
  ADMIN_IMAGE_LIBRARY_DESCRIPTION:
    'Upload images in JPGE and PNG forms. Each file can be up to 10 MB.',
  ADMIN_IMAGE_LIBRARY_SEARCH: 'Search for an image...',
  ADMIN_IMAGE_LIBRARY_LOADING: 'Loading...',
  ADMIN_ACTION_BROADCAST: 'Broadcast',
  ADMIN_INFO_PAGE_SLUG:
    'The slug is the URL for the channel. It may only include lower-case letters, numbers, and dashes.',
  ADMIN_INFO_PAGE_NAVIGATION_BUTTON:
    'The channel navigation button represents this channel in the channel navigation.',
  ADMIN_PLACEHOLDER_SCHEDULE_VIDEO: 'Choose a video to schedule...',
  ADMIN_PLACEHOLDER_SCHEDULE_PLAYLIST: 'Choose a playlist to schedule...',
  ADMIN_LABEL_SAVED: 'Saved',
  ADMIN_LABEL_DISCARD: 'Discard',
  ADMIN_LABEL_CHANGES_SAVED: 'Changes Saved',
  ADMIN_LABEL_SELECT_PLAYLIST: 'Select a Playlist',
  ADMIN_LABEL_ACHIEVEMENTS: 'Achievements',
  ADMIN_LABEL_TIME_FRAME: 'Time Frame',
  ADMIN_LABEL_TIME: 'Event Time',
  ADMIN_LABEL_DATE: 'Event Date',
  ADMIN_LABEL_DATE_DESCRIPTION:
    'If the event is multi-day, enter the last day of the event',
  ADMIN_LABEL_HOURS_ON_SITE: 'Hours on Site',
  ADMIN_LABEL_ACTIVITY_FEED_TEXT: 'Activity Feed Text',
  ADMIN_LABEL_CHOOSE_ACTION: 'Choose an Action',
  ADMIN_LABEL_TIME_RANGE: 'Time Range',
  ADMIN_LABEL_ANSWER_POLL: 'Answer Poll',
  ADMIN_DESC_ANSWER_POLL: 'Viewers earn points for participating poll',
  ADMIN_DESC_INDIVIDUAL_QUEST: 'Appears on each individual quest',
  ADMIN_LABEL_COMPLETE_QUEST: 'Complete Quest',
  ADMIN_LABEL_ENGAGE_OVERLAY: 'Engage Overlay',
  ADMIN_DESC_ENGAGE_OVERLAY:
    'Viewers earn points for engaging with any overlay',
  ADMIN_LABEL_TIME_ON_SITE: 'Time on Site',
  ADMIN_DESC_COMPLETE_QUEST: 'Viewers earn points for completing any quest',
  ADMIN_DESC_TIME_ON_SITE:
    'Viewers earn points for the amount of time they spend on the site.',
  ADMIN_LABEL_ACTIVE_ON: 'Active on',
  ADMIN_LABEL_OVERFLOW_BEHAVIOR: 'Overflow Behavior',
  ADMIN_LABEL_REQUIRED_AMOUNT: 'Required Amount',
  ADMIN_LABEL_AWAITING_STREAM: 'Awaiting Stream',
  ADMIN_LABEL_CREATING_STREAM: 'Creating Stream',
  ADMIN_LABEL_ENCODER_TEXT: 'Preview will appear when encoder sends your feed',
  ADMIN_LABEL_INGEST_TEXT:
    'Stream key will be available when your stream is created',
  ADMIN_LABEL_ALL: 'All',
  ADMIN_LABEL_NO_ACTIONS: 'No Actions',
  ADMIN_LABEL_OUTLINE: 'Outline',
  ADMIN_LABEL_SOLID: 'Solid',
  ADMIN_LABEL_ADD_ACTION: 'Add Action',
  ADMIN_LABEL_LAST_UPDATED: 'Last Updated',
  ADMIN_LABEL_ACTIVATE_SUBSCRIPTIONS: 'Activate Subscriptions',
  ADMIN_LABEL_PRODUCT_VARIANT: 'Variant',
  ADMIN_LABEL_ACTIVATE_ENTITLEMENTS: 'Activate Entitlements',
  ADMIN_LABEL_CALL_TO_ACTION_CTA: 'Call To Action (CTA)',
  ADMIN_LABEL_CALL_TO_ACTION: 'Call To Action',
  ADMIN_LABEL_CTA: 'Call To action',
  ADMIN_LABEL_CTA_TWEET: 'Tweet',
  ADMIN_LABEL_CTA_SHOW_PLAYER_STATS: 'Show Player Stats',
  ADMIN_LABEL_CTA_TWEET_MESSAGE: 'Message to tweet',
  ADMIN_LABEL_CTA_FACEBOOK_SHARE: 'Facebook share',
  ADMIN_LABEL_CTA_OPEN_LINK: 'Open link',
  ADMIN_LABEL_CTA_PLAY_VIDEO: 'Play video',
  ADMIN_LABEL_CTA_SHOW_PERSON: 'Show person',
  ADMIN_LABEL_CTA_SHOW_PANEL: 'Show panel',
  ADMIN_LABEL_CTA_TARGET_CHANNEL: 'Target channel',
  ADMIN_LABEL_ENTITLEMENTS_TITLE: 'Entitlements',
  ADMIN_LABEL_CTA_HTML: 'HTML',
  ADMIN_LABEL_OVERLAY_DURATION: 'Duration (Max 600 seconds)',
  ADMIN_LABEL_DURATION: 'Duration',
  ADMIN_LABEL_EMBED_CODE: 'Embed Code',
  ADMIN_LABEL_LIBRARY: 'Library',
  ADMIN_LABEL_ASSETS: 'Assets',
  ADMIN_LABEL_LOGIN: 'Login',
  ADMIN_LABEL_ARCHIVE: 'Archive',
  ADMIN_LABEL_RESTORE: 'Restore',
  ADMIN_LABEL_RECEIPTS: 'Receipts',
  ADMIN_LABEL_PLAYER_ID: 'Player ID',
  ADMIN_LABEL_POLL: 'Poll',
  ADMIN_LABEL_NONE: 'None',
  ADMIN_LABEL_WEEKLY: 'Weekly',
  ADMIN_LABEL_DAILY: 'Daily',
  ADMIN_LABEL_RULES_LINK: 'Rules Link',
  ADMIN_LABEL_HELP_LINK: 'Help Link',
  ADMIN_LABEL_HELP: 'Help',
  ADMIN_LABEL_TEXT: 'Text',
  ADMIN_LABEL_MESSAGE: 'Message',
  ADMIN_LABEL_REFERENCE_SOURCE: 'Reference Source',
  ADMIN_LABEL_MORE_INFO: 'More Info',
  ADMIN_LABEL_HTML: 'HTML',
  ADMIN_LABEL_SLIDER: 'Slider',
  ADMIN_LABEL_LEFT: 'Left',
  ADMIN_LABEL_RIGHT: 'Right',
  ADMIN_LABEL_CENTER: 'Center',
  ADMIN_LABEL_FULL_WIDTH: 'Full Width',
  ADMIN_LABEL_IMAGE_ALT_TEXT: 'Alternative Text',
  ADMIN_LABEL_IMAGE_ALT_TEXT_DESC: 'Descriptive Alternative Text',
  ADMIN_LABEL_STACK: 'Stack',
  ADMIN_LABEL_SLUG_UNAVAILABLE: 'Slug already in use',
  ADMIN_LABEL_IMAGES_PER_ROW: 'Images per row',
  'ADMIN_LABEL_PLACE(S)': 'place(s)',
  ADMIN_LABEL_THIS_ITEM_APPEARS_IN: 'This item appears in',
  ADMIN_LABEL_TITLE: 'Title',
  ADMIN_LABEL_BLOCK_TITLE: 'Block Title',
  ADMIN_LABEL_BLOCK_SETTINGS: 'Block Settings',
  ADMIN_LABEL_EVENT_SETTINGS: 'Event Settings',
  ADMIN_LABEL_CREATE_EVENT: 'Create Event',
  ADMIN_LABEL_CATEGORIES: 'Categories',
  ADMIN_LABEL_REMOVE_CATEGORY: 'Remove category',
  ADMIN_LABEL_TYPE_TO_ADD_CATEGORY: 'Type to add category',
  ADMIN_LABEL_TYPE_TO_ADD_LABEL: 'Type to add label',
  ADMIN_LABEL_ADD_CATEGORY: 'Add Category',
  ADMIN_LABEL_DISCARD_EVENT_CHANGES_DESCRIPTION: 'This will remove you progress and remove the event',
  ADMIN_LABEL_TEXT_BLOCK_SETTINGS: 'Text Block Settings',
  ADMIN_LABEL_HERO_BANNER_SETTINGS: 'Hero Banner Settings',
  ADMIN_LABEL_HOME: 'Home',
  ADMIN_LABEL_DESKTOP: 'Desktop',
  ADMIN_HERO_BANNER_DESKTOP_TOOLTIP:
    'Upload an image that is 1920x675px if you want it to bleed to the next block. Upload an image that is 1920x460px if you just want your block to work as a banner without a bleed.',
  ADMIN_HERO_BANNER_MOBILE_TOOLTIP:
    'Upload an image that is 375x755 if you want it to bleed to the next block. Upload an image that is 375x590px if you just want your block to work as a banner without a bleed.',
  ADMIN_LABEL_MOBILE: 'Mobile',
  ADMIN_LABEL_UNSAVED_CHANGES: 'Unsaved Changes',
  ADMIN_LABEL_HINT_TITLE: '(Required)',
  [`ENTITLEMENT_GATE_CAN'T_FIND_CODE`]: `I can't find my code`,
  ADMIN_LABEL_ERROR_NAME: 'Enter a valid name',
  ADMIN_LABEL_ERROR_QUESTION: 'Enter a valid question',
  ADMIN_LABEL_ERROR_ANSWER: 'Enter valid answers',
  ADMIN_LABEL_QUESTION: 'Question',
  ADMIN_LABEL_QUESTIONS: 'Questions',
  ADMIN_LABEL_SUBTITLE: 'Subtitle',
  ADMIN_LABEL_SUBTITLES: 'Subtitles',
  ADMIN_GATE_DATETIME_PICKER_TIMEZONE: 'Local Time',
  ADMIN_LABEL_NEW_QUESTION: 'New question',
  ADMIN_LABEL_NO_QUESTIONS: 'No questions',
  ADMIN_LABEL_QUEST_ACTIONS: 'Actions',
  ADMIN_LABEL_QUEST_ACTIONS_VIDEO_BUTTON_ANY: 'Any Video',
  ADMIN_LABEL_QUEST_ACTIONS_VIDEO_BUTTON_SPECIFIC: 'Specific Video',
  ADMIN_LABEL_QUEST_REWARD: 'Reward',
  ADMIN_LABEL_QUEST_REWARD_PRIZE: 'Prize',
  ADMIN_LABEL_QUEST_REWARD_RAFFLE: 'Raffle',
  ADMIN_LABEL_QUEST_REWARD_WEBHOOK: 'Webhook',
  ADMIN_LABEL_QUEST_LIFESPAN: 'Lifespan',
  ADMIN_LABEL_QUEST_LINK_TEXT: 'Link Text',
  ADMIN_LABEL_QUEST_WEBHOOK_ID: 'Quest ID',
  ADMIN_LABEL_CUSTOMER_PROFILE_NAME: 'Customer Name',
  ADMIN_LABEL_CUSTOMER_PROFILE_LOGO: 'Customer Logo',
  ADMIN_LABEL_CUSTOMER_PROFILE_LOGO_HELPER_TEXT:
    'Personalize your profile. Max size: 200 x 200',
  ADMIN_PLACEHOLDER_QUEST_LINK_TEXT: 'Enter Link Text Here',
  ADMIN_LABEL_CHANNEL_SELECT_DISPLAY: 'Display Style',
  ADMIN_LABEL_QUEST_VIDEO: 'Video',
  ADMIN_LABEL_QUEST_VIDEO_SELECT_PLACEHOLDER: 'Select A Video',
  ADMIN_LABEL_CHANNEL_SPECIFIC: 'Channel Specific',
  ADMIN_LABEL_CHANNEL_SPECIFIC_HELPER:
    'An action that is channel specific must be completed on a specific channel',
  ADMIN_LABEL_QUEST_CHANNEL_TO_VISIT: 'Channel To Visit',
  ADMIN_LABEL_QUEST_MINUTES_WATCHED: 'Minutes Watched Required',
  ADMIN_LABEL_QUEST_TRIVIA_ANY: 'Any Trivia',
  ADMIN_LABEL_QUEST_TRIVIA_SPECIFIC: 'Specific Trivia',
  ADMIN_LABEL_QUEST_TRIVIA: 'Trivia',
  ADMIN_LABEL_REQUIRE_ANSWER: 'Require Correct Answer',
  ADMIN_LABEL_QUEST_PREDICTION: 'Prediction',
  ADMIN_LABEL_QUEST_PREDICTION_ANY: 'Any Prediction',
  ADMIN_LABEL_QUEST_PREDICTION_SPECIFIC: 'Specific Prediction',
  ADMIN_LABEL_QUEST_POLL_ANY: 'Any Poll',
  ADMIN_LABEL_QUEST_POLL_SPECIFIC: 'Specific Poll',
  ADMIN_LABEL_QUEST_URL: 'URL',
  ADMIN_LABEL_QUEST_URL_TEXT: 'URL Text',
  ADMIN_LABEL_QUEST_TEXT_PLACEHOLDER: 'Enter text for this action...',
  ADMIN_LABEL_QUEST_VK_URL: 'VK Post URL',
  ADMIN_LABEL_QUEST_METHOD: 'Method',
  ADMIN_LABEL_QUEST_PANEL_TO_VIEW: 'Panel To View',
  ADMIN_LABEL_QUEST_RETWEETS: 'Amount of Retweets',
  ADMIN_LABEL_QUEST_SEA_OF_THIEVES_SKELETON_KILLS: 'Amount of Skeleton Kills',
  ADMIN_LABEL_QUEST_SEA_OF_THIEVES_PLAYER_KILLS: 'Amount of Player Kills',
  ADMIN_LABEL_QUEST_SEA_OF_THIEVES_ISLAND_VISITS: 'Amount of Island Visits',
  ADMIN_LABEL_QUEST_START_DATE: 'Start Date',
  ADMIN_LABEL_QUEST_END_DATE: 'End Date',
  ADMIN_LABEL_QUEST_START_TIME: 'Start Time',
  ADMIN_LABEL_QUEST_END_TIME: 'End Time',
  ADMIN_QUEST_ACTION_INPUT_PLACEHOLDER_LINK_TEXT: 'Enter text for your link...',
  ADMIN_QUEST_ACTION_INPUT_PLACEHOLDER_URL: 'Enter a URL for this action...',
  ADMIN_BROADCAST_OVERLAY_HELP: 'Select an overlay to broadcast',
  ADMIN_PROMPT_CONFIRM_BLOCK_DELETION:
    'Are you sure you want to delete this block?',
  ADMIN_PROMPT_CONFIRM_IMAGE_CARD_DELETION:
    'Are you sure you want to delete this Image Card?',
  ADMIN_PROMPT_CONFIRM_FAQ_CARD_DELETION:
    'Are you sure you want to delete this FAQ Card?',
  ADMIN_PROMPT_CONFIRM_DELETION: 'Are you sure you want to delete this?',
  ADMIN_PROMPT_CONFIRM_CHANNEL_DELETION:
    'Are you sure you want to delete this channel?',
  ADMIN_PROMPT_CONFIRM_PAGE_DELETION:
    'Are you sure you want to delete this page?',
  ADMIN_PROMPT_CONFIRM_REMOVAL: 'Are you sure you want to remove this?',
  ADMIN_PROMPT_CONFIRM_DOWNLOAD_CLAIMS:
    'Are you sure you want to download claims for this?',
  ADMIN_SELECT_OVERLAY_HELP: 'Select an overlay to preview it',
  ADMIN_CREATE_OVERLAY_HELP: 'Select an overlay to create',
  ADMIN_SCHEDULE_OVERLAY_HELP: 'Manage scheduled overlays',
  ADMIN_LABEL_PAGE_SLUG: 'Channel slug',
  ADMIN_LABEL_PAGE_NAVIGATION_BUTTON: 'Channel navigation icon',
  ADMIN_LABEL_PAGE_CONTENT: 'Content',
  ADMIN_LABEL_LANDING_PAGE_CONTENT: 'Page Content',
  ADMIN_LABEL_PAGE_ADVERTISING: 'Advertising',
  ADMIN_LABEL_PAGE_SETTINGS: 'Page Settings',
  ADMIN_LABEL_ENABLE_TRANSLATE: 'Enable User Language Selection',
  ADMIN_LABEL_ENABLE_GATE: 'Enable Gate',
  ADMIN_LABEL_SETTINGS_TOGGLE: 'Active',
  ADMIN_LABEL_DRAG_AND_DROP_HERE_TO_UPLOAD: 'Drag & drop here to upload',
  ADMIN_LABEL_SAVING_PANEL_OR_CARD_ON_OTHER_CHANNEL_WARNING:
    'Saving a change to this [panel or card] will make the changes live on channels that are using this object.',
  ADMIN_LABEL_DELETE_PANEL_OR_CARD_ON_OTHER_CHANNEL_WARNING:
    'This [panel or card] is currently active. Please remove it from these channels before deleting.',
  ADMIN_LABEL_SELECT_PREVIEW_CARD: 'Select a card to preview it',
  ADMIN_LABEL_ACCESS_CODE_HELP_TEXT:
    'Create access codes for viewers to use when you have Access Codes Only enabled',
  ADMIN_LABEL_SELECT_PREVIEW_PANEL: 'Select a panel to preview it',
  ADMIN_LABEL_SELECT_A_VIDEO_TO_ADD_TO_PLAYLIST:
    'Select a video to add to your playlist',
  ADMIN_LABEL_REPLACE_IMAGE: 'REPLACE IMAGE',
  ADMIN_LABEL_IMAGE_VIEW_TYPE: 'View',
  ADMIN_LABEL_LIST: 'List',
  ADMIN_LABEL_GRID: 'Grid',
  ADMIN_LABEL_LIGHT: 'Light',
  ADMIN_LABEL_DARK: 'Dark',
  ADMIN_LABEL_LIGHT_MODE: 'Light Mode',
  ADMIN_LABEL_DARK_MODE: 'Dark Mode',
  ADMIN_LABEL_CONTENT_BELOW: 'Content Below',
  ADMIN_LABEL_CONTENT_INSIDE: 'Content Inside',
  ADMIN_LABEL_SCHEDULE_TYPE: 'Type',
  ADMIN_LABEL_CHANNELS: 'Channels',
  ADMIN_LABEL_DIRECT_MESSAGES: 'Direct Messages',
  ADMIN_LABEL_ACCEPT_PENDING_MESSAGE: 'Accept?',
  ADMIN_LABEL_ADD_CHANNELS_DESCRIPTION:
    'Channels appear according to their order in the list',
  ADMIN_LABEL_CHANNEL: 'Channel',
  ADMIN_LABEL_MANUAL: 'Manual',
  ADMIN_LABEL_PAGE_LIVE_STREAMS: 'Twitch live streams',
  ADMIN_INFO_PAGE_LIVE_STREAMS:
    'If no content is currently scheduled, the first online stream from this list will be played next. If none are online, offline content will be shown.',
  ADMIN_LABEL_PAGE_OFFLINE: 'Offline',
  ADMIN_INFO_PAGE_OFFLINE:
    'Offline content appears on the channel when there is no scheduled or live content. Video Mode plays a video or playlist on repeat and synchronized for all viewers. Image Mode displays an image.',
  ADMIN_LABEL_PAGE_SCHEDULE: 'Scheduled this week',
  ADMIN_INFO_PAGE_SCHEDULE:
    'Scheduled videos and playlists will play on the page at a specific time.',
  ADMIN_INFO_PAGE_SCHEDULE_KNOWN_ISSUE_TITLE: 'IMPORTANT MESSAGE',
  ADMIN_SETTINGS_WELCOME_TITLE: 'Welcome Screen Settings',
  ADMIN_SETTINGS_ICONS_TITLE: 'Icons Settings',
  ADMIN_SETTINGS_ICONS_UPLOAD_TITLE: 'Icon',
  ADMIN_SETTINGS_ICONS_DESCRIPTION: 'These icons appear on the browser tab.',
  ADMIN_SETTINGS_3P_HELPER_TEXT:
    'Configure integrations with 3rd party providers.',
  ADMIN_SETTINGS_3P_FACEBOOK_APP: 'Facebook App',
  ADMIN_SETTINGS_3P_FACEBOOK_APP_PLACEHOLDER: 'Enter Facebook app...',
  ADMIN_SETTINGS_3P_FACEBOOK_APP_DESCRIPTION:
    'Enter your Facebook App ID to allow users to authenticate with your Facebook app',
  ADMIN_SETTINGS_3P_FACEBOOK_PIXEL: 'Facebook Pixel',
  ADMIN_SETTINGS_3P_FACEBOOK_PIXEL_PLACEHOLDER: 'Enter Facebook pixel...',
  ADMIN_SETTINGS_3P_FACEBOOK_PIXEL_DESCRIPTION:
    'Enter your Facebook Pixel ID to load in your pixel',
  ADMIN_SETTINGS_3P_FACEIT: 'Faceit',
  ADMIN_SETTINGS_3P_FACEIT_PLACEHOLDER: 'Enter Faceit here...',
  ADMIN_SETTINGS_3P_GOOGLE_ANALYTICS: 'Google Analytics',
  ADMIN_SETTINGS_3P_GOOGLE_ANALYTICS_PLACEHOLDER: 'Enter Google Analytics...',
  ADMIN_SETTINGS_3P_GOOGLE_ANALYTICS_DESCRIPTION:
    'Enter your Google Analytics ID to send events to your Google Analytics account',
  ADMIN_SETTINGS_3P_SHOPIFY: 'Shopify',
  ADMIN_SETTINGS_3P_SHOPIFY_PLACEHOLDER: 'Enter Shopify Store here...',
  ADMIN_INFO_PAGE_SEO:
    'Search engines such as Google use this information when displaying search results.',
  ADMIN_LABEL_PAGE_VIDEO_WRAPPER: 'Video wrapper',
  ADMIN_PAGE_NO_CHANNEL_ADDED: 'No Channels Added',
  ADMIN_PAGE_GATE_CHANNEL_ENTITLEMENT_SKU: 'Channel Entitlement (sku value)',
  ADMIN_PAGE_GATE_CHANNEL_SAVING: 'Saving...',
  ADMIN_PAGE_SECTION_CONTENT_PRIORITIES: 'Content Priorities',
  ADMIN_LABEL_REQUIRED: 'Required',
  ADMIN_LABEL_UPLOAD_IN_PROGRESS:
    'Upload in progress. Please do not leave the page',
  ADMIN_LABEL_UPLOAD_FINISHING: 'Finishing... Wait a moment please.',
  ADMIN_LABEL_UPLOAD_SUCCESSFUL:
    'Upload successful. You may close this window now.',
  ADMIN_LABEL_TRANSCODING_IN_PROGRESS:
    'Transcoding in progress. Your video will be available to stream once transcoding is done. Click save to confirm.',
  ADMIN_LABEL_CHANNEL_LANGUAGE: 'Channel Language',
  ADMIN_INFO_CHANNEL_LANGUAGE:
    'Setting a default language for a channel will disable auto-translate, and will instead display translations for the channel text based on the available localized strings. For more information, contact support@maestro.io',
  ADMIN_LOGO_HEADER: 'LOGO',
  ADMIN_LOGO_HEADER_DESCRIPTION:
    'Set your logo for your hub. You can upload an image or use text. A mobile specific image can be set.',
  ADMIN_LOGO_HEADER_LINK: 'More Info',
  ADMIN_LOGO_LINK_LABEL: 'Link',
  ADMIN_LOGO_LINK_DESCRIPTION_EXTERNAL_LINK: 'Enter protocol (e.g. https://) for external websites',
  ADMIN_LABEL_ENTER_A_URL: 'Enter a URL',
  ADMIN_LOGO_LINK_DESCRIPTION: '(Optional) Add a link to your logo.',
  ADMIN_LOGO_TEXT_DESCRIPTION:
    'This text is used anywhere we would use your logo in the absence of an image set below.',
  ADMIN_OFFLINE_IMAGE_UPLOAD_DESCRIPTION:
    'Use .PNG, .JPG, .GIF. Recommended size 1920 X 1080.',
  ADMIN_LOGO_FIELD_TITLE_PLACEHOLDER: 'Enter a title...',
  ADMIN_LOGO_FIELD_URL_PLACEHOLDER: 'Enter a URL...',
  ADMIN_LOGO_IMAGE_LABEL: 'Image',
  ADMIN_SUBSCRIPTION_GATE_SET_DATE_TITLE: 'Set Date',
  ADMIN_SUBSCRIPTION_GATE_SAMPLE_DESCRIPTION:
    'Below are 2 examples of what a password gate page looks like on desktop and mobile devices.',
  ADMIN_GATE_ACCESS_CODE_DEFAULT_VALUE:
    'If you have issues with your access code, please email support@liveevent.com',
  ADMIN_GATE_COLOR_BUTTON: 'Button Color',
  ADMIN_GATE_COLOR_SAMPLE_TITLE: 'Sample',
  ADMIN_LOGO_IMAGE_DESKTOP_LABEL: 'Desktop Image',
  ADMIN_LOGO_IMAGE_MOBILE_LABEL: 'Mobile Image',
  ADMIN_LOGO_IMAGE_DESCRIPTION:
    'Replace the text logo with an image. The max size is 500 x 150 pixels.',
  ADMIN_ACTION_ENTER_A_URL_FOR_YOUR_IMAGE: 'Enter a URL for your image',
  ADMIN_ACTION_EXIT_EDIT_MODE: 'Exit Edit Mode',
  ADMIN_ACTION_EXIT_EDITOR: 'Exit Editor',
  ADMIN_ACTION_SAVE: 'Save',
  ADMIN_ACTION_DONE: 'Done',
  ADMIN_ACTION_REPLACE: 'Replace',
  ADMIN_ACTION_SAVE_TO_LIBRARY: 'Save to Library',
  ADMIN_ACTION_SAVE_TO_FILES: 'Save to Files',
  ADMIN_ACTION_SET_HOME: 'Set home page',
  ADMIN_ACTION_SET_AS_HOME: 'Set as home',
  ADMIN_SETTINGS_WELCOME_DURATION_LABEL: 'Duration',
  ADMIN_SETTINGS_WELCOME_DESCRIPTION:
    'This appears when a user loads up the site for the first time',
  ADMIN_SETTINGS_WELCOME_UPLOAD_LABEL_DESKTOP: 'Desktop Image',
  ADMIN_SETTINGS_WELCOME_UPLOAD_LABEL_MOBILE: 'Mobile Image',
  ADMIN_ACTION_SAVE_SET: 'Save & Set',
  ADMIN_ACTION_CANCEL: 'Cancel',
  ADMIN_ACTION_ADD_SOURCE: 'Add Source',
  ADMIN_ACTION_ADD_PEOPLE: 'Add People',
  ADMIN_ACTION_ADD_PERSON: 'Person',
  ADMIN_ACTION_ADD_GROUP: 'Groups',
  ADMIN_ACTION_ADD_TO_PLAYLIST: 'Add to playlist',
  ADMIN_ACTION_CANCEL_DELETION: 'No, go back',
  ADMIN_ACTION_SELECT_A_CHANNEL_TO_ADD: 'Select a channel to add',
  ADMIN_ACTION_TOGGLE_REGION_VISIBILITY: 'Toggle visibility',
  ADMIN_ACTION_CONFIRM_DELETION: 'Yes, delete',
  ADMIN_ACTION_CONFIRM_DOWNLOAD_CLAIMS: 'Yes, download claims',
  ADMIN_FOOTER_ERROR_REQUIRED: 'Required',
  ADMIN_LABEL_LIVESTREAM: 'Livestream',
  ADMIN_LABEL_VIDEO_ON_DEMAND: 'Video On Demand (VOD)',
  ADMIN_LABEL_VIDEO_TYPE: 'Video Type',
  ADMIN_LABEL_GROUP_DATE: 'Group by date',
  ADMIN_LABEL_ADD_PERSON: 'Add Person',
  ADMIN_LABEL_ADD_GROUP: 'Add Group',
  ADMIN_LABEL_ADD_NEW: 'Add New',
  ADMIN_LABEL_ADD_TO_ENTITLEMENT_TIER: 'Add to entitlement tier',
  ADMIN_LABEL_ADD_VIDEO: 'Add Video',
  ADMIN_EDIT_METADATA: 'Edit Video Metadata',
  ADMIN_EDIT_METADATA_SUBTITLE: 'Add or update metadata for this video',
  ADMIN_EDIT_BULK_METADATA: 'Bulk Edit Video Metadata',
  ADMIN_EDIT_BUL_METADATA_SUBTITLE:
    'Apply metadata to all uploaded videos at once. You can edit further from the Library.',
  ADMIN_LABEL_ADD_ENTITLEMENT: 'Add Entitlement',
  ADMIN_LABEL_ADD_PRICE: 'Add Price',
  ADMIN_LABEL_CURRENCY_DEFAULT: 'Default',
  ADMIN_LABEL_CURRENCY_PRICE: 'Price',
  ADMIN_LABEL_ADD_TICKET_BUNDLE_OR_SUBSCRIPTION:
    'Add Ticket, Bundle Or Subscription',
  ADMIN_LABEL_CREATE_SUBSCRIPTION: 'Create Subscription',
  ADMIN_LABEL_CREATE_TICKET: 'Create ticket',
  ADMIN_LABEL_CREATE_BUNDLE: 'Create bundle',
  ADMIN_LABEL_USE_ENTITLEMENT: 'Use',
  ADMIN_LABEL_ADD_YOUTUBE_PLAYLIST: 'Add YouTube playlist',
  ADMIN_LABEL_PAGE_TICKETS_BUNDLES_SUBSCRIPTIONS_LIBRARY_HELP:
    'Choose an existing ticket, bundle or subscription or create a new one',
  ADMIN_LABEL_SELECT_ENTITLEMENT_LIBRARY_HELP:
    'Select an entitlement from the list or search by name or SKU',
  ADMIN_LABEL_ADD_BUTTON_ENTITLEMENT_LIBRARY_HELP: 'CREATE NEW ENTITLEMENT',
  ADMIN_LABEL_VIDEO_AND_PLAYLIST_LIBRARY_HELP:
    'All your videos, playlists and livestreams. Please select a video to play on your channel or visit your channel page for a more detailed breakdown of your content.',
  ADMIN_LABEL_PAGE_CHANNEL_LIBRARY_HELP: 'Select a channel:',
  ADMIN_LABEL_PAGE_SCHEDULE_LIBRARY_HELP:
    'Select a video or playlist to schedule',
  ADMIN_LABEL_PAGE_LIVESTREAM_LIBRARY_HELP: 'Select a livestream',
  ADMIN_LABEL_PAGE_SUBSCRIPTION_LIBRARY_HELP:
    'Chose an existing subscription or create a new one',
  ADMIN_LABEL_VIDEO_LIBRARY_HELP: 'Select a video',
  ADMIN_LABEL_ALLOW_ANON: 'Allow anonymous chat',
  ADMIN_LABEL_ALLOW_ANON_DESCRIPTION:
    'Users participate in chat without having to login. Users get assigned a random user number and are asked to login after 5 mins of activity.',
  ADMIN_LABEL_BACK: 'Back',
  ADMIN_LABEL_BANNER: 'Banner',
  ADMIN_LABEL_BIO: 'Bio',
  ADMIN_LABEL_PERSON_DESCRIPTION: 'Person description',
  ADMIN_LABEL_GROUP_DESCRIPTION: 'Group description',
  ADMIN_LABEL_CALENDAR: 'Calendar',
  ADMIN_LABEL_HOUR: 'Hour',
  ADMIN_LABEL_CARD: 'Card',
  ADMIN_LABEL_PAGE_IMAGE_UPLOAD_DESCRIPTION:
    'The image for this page. 640x360 px',
  ADMIN_LABEL_CHANNEL_IMAGE_UPLOAD_DESCRIPTION:
    'The image for this channel. 640x360 px',
  ADMIN_LABEL_PAGE_CARD_UPLOAD_DESCRIPTION:
    'The image used to visually represent this page on the site. 1920x1080 px.',
  ADMIN_LABEL_CHANNEL_CARD_UPLOAD_DESCRIPTION:
    'The image used to visually represent this channel on the site. 1920x1080 px.',
  ADMIN_LABEL_CARDS: 'Cards',
  ADMIN_LABEL_SET_CARD: 'Set Card',
  ADMIN_LABEL_CATALOG: 'Catalog',
  ADMIN_LABEL_SET_PANEL: 'Set Panel',
  ADMIN_LABEL_TYPE: 'Type',
  ADMIN_LABEL_TOTAL_POINTS_EARNED: 'Total Points Earned',
  ADMIN_LABEL_ALWAYS_ACTIVE: 'Always Active',
  ADMIN_LABEL_PEOPLE_PANEL_DISPLAY: 'Display',
  ADMIN_LABEL_CHANNEL_LIBRARY: 'Channel Library',
  ADMIN_LABEL_CHANNEL_NAVIGATION: 'Channel Navigation',
  ADMIN_LABEL_CHOOSE_SOMETHING_TO_ADD: 'Choose something to add',
  ADMIN_LABEL_CONTINUE: 'Continue',
  ADMIN_LABEL_DONE: 'Done',
  ADMIN_LABEL_CREATE_A_CHANNEL: 'Create a channel',
  ADMIN_LABEL_CREATE_A_PLAYLIST: 'Create a playlist',
  ADMIN_LABEL_CREATE_A_LIVESTREAM: 'Create a livestream',
  ADMIN_LABEL_CREATE_CUSTOM_PLAYLIST: 'Create Custom Playlist',
  ADMIN_LABEL_CREATE_PROFILES_FOR_INDIVIDUALS:
    'Create profiles for individuals',
  ADMIN_LABEL_CREATE_PROFILES_FOR_TEAMS: 'Create profiles for teams',
  ADMIN_LABEL_CREATE_YOUR_PLAYLIST: 'Create your playlist',
  ADMIN_LABEL_CREATE_YOUR_OWN_OR_ADD_A_PLAYLIST_URL:
    'Create your own or add a playlist URL',
  ADMIN_LABEL_DESCRIPTION: 'Description',
  ADMIN_LABEL_UPLOAD_DATE: 'Upload Date',
  ADMIN_SEO_TITLE_SUBTEXT: 'This is the Meta Title Tag that will be read by search engines',
  CHANNEL_DESCRIPTION_FIELD_DESCRIPTION: 'The description of this channel.',
  PAGE_DESCRIPTION_FIELD_DESCRIPTION: 'The description of this page.',
  'ADMIN_LABEL_PRIMARY_CALL_TO_ACTION_(CTA)': 'Primary Call To Action (CTA)',
  'ADMIN_LABEL_SECONDARY_CALL_TO_ACTION_(CTA)':
    'Secondary Call To Action (CTA)',
  ADMIN_LABEL_PRIMARY_CALL_TO_ACTION: 'Primary Call To Action',
  ADMIN_LABEL_SECONDARY_CALL_TO_ACTION: 'Secondary Call To Action',
  ADMIN_LABEL_IMAGE_BLEED: 'Image Bleed',
  ADMIN_LABEL_IMAGE_BLEED_TOOLTIP:
    'Your image will bleed to the next block to create a background effect.',
  CTA: 'CTA',
  ADMIN_LABEL_ALLOW_NON_ADMIN_LINKS: 'Allow links in chat from non-admins',
  ADMIN_LABEL_DIGITAL_OR_PHYSICAL_GOODS: 'Digital or physical goods',
  ADMIN_LABEL_DISPLAY_STYLE: 'Display Style',
  ADMIN_LABEL_STYLE: 'Style',
  ADMIN_LABEL_DISPLAY_INFORMATION_IN_THE_SIDEBAR:
    'Display information in the sidebar',
  ADMIN_LABEL_DEFAULT_IMAGE_PREVIEW: 'Default Entitlement Gate Background',
  ADMIN_LABEL_DRAG_AND_DROP_CLICK_TO_UPLOAD: 'Click to upload',
  ADMIN_LABEL_DRAG_AND_DROP_TO_UPLOAD: 'Drag & Drop to upload',
  ADMIN_LABEL_OR_DRAG_AND_DROP_TO_UPLOAD: 'or Drag & Drop to upload',
  ADMIN_LABEL_UPLOAD_AN_IMAGE: 'Upload Image',
  ADMIN_LABEL_UPLOAD: 'Upload',
  ADMIN_LABEL_IMAGE_CARD: 'Image Card',
  ADMIN_LABEL_SEARCH_IN_LIBRARY: 'Search library',
  ADMIN_LABEL_DRAG_AND_DROP_SUBSCRIPTION_GATE_MOBILE_IMAGE_SIZE:
    'Max size: 1080 X 1920 <br> RECOMMENDED',
  ADMIN_LABEL_DRAG_AND_DROP_SUBSCRIPTION_GATE_WEB_IMAGE_SIZE:
    'Max size: 1920 X 1080 <br> RECOMMENDED',
  ADMIN_SUBSCRIPTION_GATE_BACKGROUND_IMAGE_WEB_LABEL:
    'Background Image - Desktop',
  ADMIN_SUBSCRIPTION_GATE_BACKGROUND_IMAGE_MOBILE_LABEL:
    'Background Image -  Mobile',
  ADMIN_LABEL_DRAG_AND_DROP_YOUR_VIDEO_HERE: 'Drag and Drop your video here',
  ADMIN_LABEL_DROPDOWN: 'Dropdown',
  ADMIN_DESC_IMPOSSIBLE_ACTION:
    'Impossible actions block users from fully completing a quest',
  ADMIN_LABEL_ALL_CHANNEL_OVERLAY: 'All channels',
  ADMIN_ALL_CHANNEL_OVERLAY_HELP: 'Send your overlay to all channels',
  ADMIN_LABEL_SCHEDULE_OVERLAY: 'Schedule overlay',
  ADMIN_LABEL_STATUS: 'Status',
  ADMIN_LABEL_START_TIME: 'Start Time',
  ADMIN_LABEL_START_DATE: 'Start Date',
  ADMIN_LABEL_CUSTOM_FILTER: 'Custom Filter',
  ADMIN_LABEL_END_DATE: 'End Date',
  ADMIN_LABEL_END_TIME: 'End Time',
  ADMIN_LABEL_ERROR: 'Error',
  ADMIN_LABEL_WARNING: 'Warning',
  ADMIN_LABEL_EVENTS: 'Events',
  ADMIN_LABEL_FETCHING_METADATA: 'Fetching Metadata...',
  ADMIN_LABEL_FILTER: 'Filter',
  ADMIN_LABEL_FILTERED_WORDS: 'Filtered Words',
  ADMIN_LABEL_TIP_NO_REFUNDS: 'Tips are not refundable.',
  ADMIN_LABEL_THANK_YOU_DEFAULT_MESSAGE:
    'I’m able to do what I love because of people like you. Thank you for your support.',
  ADMIN_LABEL_THANK_YOU: 'Thank You Message',
  ADMIN_LABEL_THANK_YOU_HINT:
    'Users see this message after they complete the tipping process.',
  ADMIN_LABEL_HOURS: 'Hours',
  ADMIN_LABEL_NO_MATCHES_FOUND: 'No matches found.',
  ADMIN_LABEL_CHANGES_SAVED_PUBLISH: 'Changes Saved (Requires Publish)',
  ADMIN_LABEL_MINUTES: 'Minutes',
  ADMIN_LABEL_SECONDS: 'Seconds',
  ADMIN_LABEL_FORTNITE_PLAY_BY_PLAY: 'Play-by-Play (Fortnite)',
  ADMIN_DESC_FORTNITE_PLAY_BY_PLAY:
    'Live event stream from an ongoing Fortnite match',
  ADMIN_LABEL_MATCH_ID: 'Match ID',
  ADMIN_LABEL_INPUT_TEXT: 'Text',
  ADMIN_LABEL_INTERACTIVE_ELEMENTS_OVER_THE_VIDEO:
    'Interactive elements over the video',
  ADMIN_LABEL_IMAGE: 'Image',
  ADMIN_LABEL_PAGE_SETTINGS_SAME_IMAGE_TOGGLE:
    'Same as Thumbnail (must upload an image)',
  ADMIN_LABEL_VALIDATING: 'Validating...',
  ADMIN_ROLE_CHAT_ADMIN_DESC:
    'Access to admin chat moderation features for specific channel or all channels if none selected.',
  ADMIN_ROLE_SITE_ADMIN_DESC: 'Full admin access to the entire Site.',
  ADMIN_ROLE_CHAT_ADMIN_ENTIRE_SITE: 'Entire site',
  ADMIN_ROLE_CHAT_ADMIN_SPECIFIC_ACCESS: 'Specific access',
  ADMIN_GATE_ENTITLEMENT_SETTINGS: 'TICKET, BUNDLE OR SUBSCRIPTION SETTINGS',
  ADMIN_GATE_ENTITLEMENT_SUBSCRIPTIONS: 'Create Subscription',
  ADMIN_GATE_ENTITLEMENT_SUBSCRIPTIONS_EDIT: 'Edit Subscription',
  ADMIN_GATE_ENTITLEMENT_TICKETS: 'Create Ticket',
  ADMIN_GATE_ENTITLEMENT_TICKETS_EDIT: 'Edit Ticket',
  ADMIN_GATE_ENTITLEMENT_SUBSCRIPTIONS_DESCRIPTION:
    'Tell your users what to expect when they buy this ticket type',
  ADMIN_GATE_ENABLE_MESSAGE: 'You must enable the gate first',
  ADMIN_LABEL_LINKS: 'Links',
  ADMIN_LABEL_LOCATION: 'Location',
  ADMIN_LABEL_NAME: 'Name',
  ADMIN_ENTITLEMENT_UPDATE_NAME_DESCRIPTION:
    'This is what the viewer will see at the paywall',
  ENTITLEMENT_FATAL_ERROR_WHEN_UPDATING:
    'Fatal error when updating entitlement',
  ADMIN_ENTITLEMENT_ERROR_NAME: 'No name entered',
  ADMIN_ENTITLEMENT_ERROR_SKU: 'No SKU entered',
  ADMIN_ENTITLEMENT_ERROR_PRICE: 'No Price Entered',
  ADMIN_ENTITLEMENT_ERROR_COUNTRY: 'Select Currency',
  ADMIN_ENTITLEMENT_ERROR_NO_DEFAULT: 'Select Default',
  ADMIN_ENTITLEMENT_ERROR_REPEATED_DEFAULT: 'Duplicate Default',
  ADMIN_ENTITLEMENT_ERROR_REPEATED_COUNTRY: 'Duplicate Currency',
  ADMIN_ENTITLEMENT_ERROR_DUPLICATED_SKU: 'The SKU already exists',
  ADMIN_ENTITLEMENT_UPDATE_SKU_DESCRIPTION:
    'The SKU name will only be visible within the Administration menu',
  ADMIN_LABEL_FREQUENCY_DESCRIPTION:
    'Define if this subscription should charge the user monthly or annually',
  ADMIN_LABEL_UPDATED: 'Updated',
  ADMIN_LABEL_SKU: 'SKU',
  ADMIN_TITLE_REFUND_ENTITLEMENT_MODAL: 'Refund Ticket Purchase',
  ADMIN_TITLE_CANCEL_ENTITLEMENT_MODAL: 'Cancel Subscription',
  ADMIN_LABEL_DO_NOT_CANCEL_SUBSCRIPTION_MODAL: 'DON’T CANCEL',
  ADMIN_LABEL_CANCEL_SUBSCRIPTION_MODAL: 'CANCEL SUBSCRIPTION',
  ADMIN_INFO_TITLE_REFUND_ENTITLEMENT_MODAL:
    'Refunds take 5-10 business days to appear on a customer’s statement.',
  ADMIN_LABEL_ERROR_REFUND_SELECT_ENTITLEMENT_MODAL: 'Reason is required',
  ADMIN_LABEL_ERROR_REFUND_VALUE_ENTITLEMENT_MODAL:
    'Refund amount cannot exceed original price',
  ADMIN_LABEL_ERROR_REFUND_INVALID_VALUE_ENTITLEMENT_MODAL:
    'Invalid Refund Amount',
  ADMIN_LABEL_ERROR_CANCEL_ENTITLEMENT_MODAL: 'Refund Choice is required',
  ADMIN_TITLE_BOTTOM_REFUND_ENTITLEMENT_MODAL: 'Keep entitlement',
  ADMIN_INFO_BOTTOM_REFUND_ENTITLEMENT_MODAL:
    'Checking this box will process the refund, but not remove the entitlement. You can manually remove the entitlement from the profile at any time.',
  ADMIN_LABEL_DETAILS_REFUND_ENTITLEMENT_MODAL:
    'Add more details about this refund.',
  ADMIN_LABEL_ADD_MORE: 'Add More',
  ADMIN_LABEL_OPTION_REFUND_DUPLICATE_MODAL: 'Duplicate',
  ADMIN_LABEL_OPTION_REFUND_FRAUDULENT_MODAL: 'Fraudulent',
  ADMIN_LABEL_OPTION_REFUND_REQUEST_CUSTOMER_MODAL: 'Requested by customer',
  ADMIN_LABEL_OPTION_CANCEL_REFUND_MODAL: 'With Refund',
  ADMIN_LABEL_OPTION_CANCEL_NO_REFUND_MODAL: 'No Refund',
  ADMIN_LABEL_RADIO_IMMEDIATELY_CANCEL_MODAL: 'Immediately',
  ADMIN_LABEL_RADIO_SCHEDULE_CANCEL_MODAL: 'End of the current period',
  ADMIN_LABEL_TICKETS_BUNDLES_AND_SUBS: 'Tickets, Bundles and Subs',
  ADMIN_LABEL_NO_IMAGES: 'No Images',
  ADMIN_LABEL_NO_EVENTS: 'No Events',
  ADMIN_LABEL_NO_PEOPLE: 'No People',
  ADMIN_LABEL_NO_GROUPS: 'No Groups',
  ADMIN_LABEL_NO_SOURCES: 'No Sources',
  ADMIN_LABEL_NO_SILENCED_USERS: 'No Silenced Users',
  ADMIN_LABEL_NOT_IN_ANY_ENTITLEMENT_TIERS: 'Not in any entitlement tiers',
  ADMIN_LABEL_POINTS: 'Points',
  ADMIN_LABEL_PEOPLE: 'People',
  ADMIN_LABEL_SILENCED_USERS: 'Silenced Users',
  ADMIN_LABEL_PEOPLE_AND_TEAMS: 'People and teams',
  ADMIN_LABEL_PEOPLE_HELPER_TEXT:
    'Profiles for individuals. Can have links and a small bio.',
  ADMIN_SILENCED_USERS_HELPER_TEXT:
    'Users who appear in the list below have been silenced by an admin.',
  ADMIN_LABEL_PLAYLIST: 'Playlist',
  ADMIN_LABEL_PLAYLISTS: 'Playlists',
  ADMIN_LABEL_LAYOUT: 'Layout',
  ADMIN_LABEL_PLAYER_BEHAVIOR: 'Player Behavior',
  ADMIN_LABEL_IMAGE_BORDER_RADIUS: 'Image Border Radius',
  ADMIN_LABEL_ALIGNMENT: 'Alignment',
  ADMIN_LABEL_SINGLE_COLUMN: 'Single column',
  ADMIN_LABEL_DOUBLE_COLUMN: 'Double column',
  ADMIN_LABEL_DISPLAY_ICON: 'Display icon',
  ADMIN_LABEL_PLAY_ICON: 'Play icon',
  ADMIN_LABEL_CAMERA_ICON: 'Camera icon',
  ADMIN_LABEL_DISPLAY_DURATION: 'Display duration',
  ADMIN_LABEL_LOGIN_BUTTON_TEXT: 'Login button text',
  ADMIN_LABEL_PREDICTION_LOGIN_PROMPT: 'Log in to submit a prediction',
  ADMIN_LABEL_QUICK_LOGIN_PROMPT: 'Log in to make Quick Picks',
  ADMIN_LABEL_LOGIN_TO_PARTICIPATE: 'Log in to Participate',
  ADMIN_POLL_SELECT_CARD_PROMPT: 'Select poll',
  ADMIN_POLL_SELECT_NOTIFICATION_NO_POLL: 'No poll selected',
  ADMIN_POLL_SELECT_NOTIFICATION_LOADING: 'Loading...',
  ADMIN_POLL_SELECT_NOTIFICATION_NOT_FOUND: 'Poll not found',
  ADMIN_POLL_SELECT_NOTIFICATION_UNTITLED: 'Untitled poll',
  ADMIN_LABEL_POLL_TRIVIA_OR_PREDICTIONS: 'Poll, trivia or predictions',
  ADMIN_LABEL_PROFILE_PHOTO: 'Profile Photo',
  ADMIN_LABEL_REWARD_VIEWERS_FOR_DOING_THINGS:
    'Reward viewers for doing things',
  ADMIN_LABEL_FOOTER: 'Footer',
  ADMIN_LABEL_IFRAME_VIDEO: 'Iframe Video',
  ADMIN_LABEL_IFRAME_TAG: 'iframe Tag',
  DISABLE_ON_MOBILE_EMBEDDED: 'Disable on mobile embedded',
  ADMIN_LABEL_MULTIPLE_CHOICE: 'Multiple Choice',
  ADMIN_LABEL_MULTIPLE_CHOICE_DESCRIPTION:
    'Multiple choice are questions that come in the form of polls, trivia, or predictions.',
  ADMIN_LABEL_QUESTS_DESCRIPTION:
    'Choose an existing quest or create a new one',
  ADMIN_LABEL_ADD_IMAGE: 'Add Image',
  ADMIN_LABEL_ADD_A_VIDEO: 'Add a Video',
  ADMIN_LABEL_NEW_IMAGE: 'New Image',
  ADMIN_LABEL_ADD_EVENT: 'Add Event',
  ADMIN_LABEL_NEW_EVENT: 'New Event',
  ADMIN_LABEL_NEW_LINK: 'New link',
  ADMIN_LABEL_NEW_PERSON: 'New Person',
  ADMIN_LABEL_NEW_GROUP: 'New Group',
  ADMIN_LABEL_NEW_VIDEO: 'New Video',
  ADMIN_LABEL_OVERLAY: 'Overlay',
  ADMIN_LABEL_OVERLAYS: 'Overlays',
  ADMIN_LABEL_OVERLAYS_SETTINGS: 'Overlays Settings',
  ADMIN_LABEL_SAVED_CHANGES: 'Saved Changes',
  ADMIN_LABEL_SCHEDULE: 'Schedule',
  ADMIN_LABEL_SCHEDULED_DURATION_IN_MINUTES: 'Scheduled duration (in minutes)',
  ADMIN_LABEL_SMALL_CTAS_IN_THE_SIDEBAR: 'Small CTAs in the sidebar',
  ADMIN_LABEL_SUCCESS: 'Success',
  ADMIN_LABEL_VIDEO_ADDED: 'Video Added To Playlist',
  ADMIN_LABEL_SYSTEM: 'System',
  ADMIN_LABEL_SYSTEM_IMAGE: 'System Image',
  ADMIN_LABEL_TRANSCODING: 'Transcoding',
  ADMIN_LABEL_CHANNELS_TO_INCLUDE: 'Channels to Include',
  ADMIN_LABEL_CHANNELS_SETTINGS: 'Channels Settings',
  ADMIN_LABEL_LANDING_PAGE_SETTINGS: 'Landing Page Settings',
  ADMIN_LABEL_NO_CHANNELS_ADDED: 'No Channels Added',
  ADMIN_LABEL_SPONSOR_IMAGE: 'Sponsor Image',
  ADMIN_LABEL_URL: 'URL',
  ADMIN_LABEL_FULL_URL_TAG: 'Full URL:',
  ADMIN_LABEL_FULL_URL_WITHOUT_COLON_TAG: 'Full URL',
  ADMIN_LABEL_CHANNEL_URL_DESCRIPTION:
    'URLs may only include lower-case letters, numbers, and dashes.',
  ADMIN_LABEL_FOLDER_OR_PARENT_PAGE_TAG: 'Folder or Parent Page',
  ADMIN_LABEL_FOLDER_OR_PARENT_PAGE_DESCRIPTION: 'This is how your page will display in the navigation',
  ADMIN_LABEL_TWEET_URL: 'Tweet URL',
  ADMIN_LABEL_PRICE: 'Price',
  ADMIN_LABEL_PRICE_NO_DECIMALS: 'Non-decimal Currency',
  ADMIN_LABEL_PRICE_MAX_SUPPORTS: 'Max is 6 integers',
  ADMIN_LABEL_PRICE_ERROR: 'Invalid price entered',
  ADMIN_LABEL_MINIMUM_PRICE: 'Price must be above 5',
  ADMIN_LABEL_PRICE_LIMIT_ERROR: 'Price cannot be more than 100000',
  ADMIN_LABEL_PREVIEW_DURATION: 'Preview Duration (MINUTES)',
  ADMIN_LABEL_PREVIEW: 'Preview',
  ADMIN_LABEL_EDIT: 'Edit',
  ADMIN_LABEL_PASSWORD_TOGGLE_BUTTON: 'Change Password',
  ADMIN_LABEL_PREVIEW_DURATION_HELPER:
    'The amount of time a viewer can watch. If multiple entitlements can grant access, the longest duration is chosen.',
  ADMIN_LABEL_FREQUENCY: 'Billing Cycle',
  ADMIN_LABEL_CALENDAR_NO_DATE: 'No Date selected',
  ADMIN_LABEL_FREQUENCY_MULT_DAY:
    'If it’s a mult-day event enter the last day of the event',
  ADMIN_LABEL_PREVIOUS_EXPORTS: 'Export',
  ADMIN_LABEL_FREQUENCY_ONE_TIME: 'One-Time',
  ADMIN_LABEL_AMOUNT_OF_CODES_LABEL:
    'Enter the number of codes you want to create',
  ADMIN_LABEL_FREQUENCY_MONTHLY_RECURRING: 'Monthly',
  ADMIN_LABEL_FREQUENCY_ANNUALLY_RECURRING: 'Annually',
  ADMIN_LABEL_FREQUENCY_MONTHLY: 'Monthly',
  ADMIN_LABEL_FREQUENCY_ANNUALLY: 'Annually',
  ANNUALLY_SAVE_20_PERCENT: 'Annually (save 20%)',
  ADMIN_LABEL_CURRENCY: 'Currency',
  ADMIN_LABEL_PRICING: 'Pricing',
  ADMIN_LABEL_PRICING_DESCRIPTION:
    'Optionally add a unique price for each currency. Users will see the price for their region’s ISO standard currency according their IP address. If there is no price for their currency, users will see the price option you set as default.',
  ADMIN_LABEL_PERSON: 'Person',
  ADMIN_LABEL_SHOW: 'Show',
  ADMIN_LABEL_DISPLAY: 'Display',
  ADMIN_LABEL_USING: 'Using',
  ADMIN_LABEL_CHANGE_RICH_TEXT_FONT_TIP:
    'Customize the theme fonts on the Design tab.',
  ADMIN_LABEL_CTA_SHOW_GROUP: 'Show group',
  ADMIN_LABEL_VIEW_TEAM_STATS: 'View Team Stats',
  ADMIN_LABEL_PANEL: 'Panel',
  ADMIN_LABEL_PANELS: 'Panels',
  ADMIN_LABEL_TIME_ZONE: 'Time Zone',
  ADMIN_LABEL_ENTITLEMENTS: 'Entitlements',
  ADMIN_LABEL_ENGAGEMENT: 'Engagement',
  ADMIN_LABEL_INSIGHTS: 'Insights',
  ADMIN_LABEL_RETENTION: 'Retention',
  ADMIN_LABEL_TRENDS: 'Trends',
  ADMIN_LABEL_COMMUNITY: 'Community',
  ADMIN_LABEL_BILLING: 'Billing',
  ADMIN_LABEL_3RD_PARTY: '3rd Party Apps',
  ADMIN_LABEL_CODE_INJECTION: 'Code Injection',
  ADMIN_LABEL_DEVELOPER_SUBHEADER: 'Developer Settings',
  ADMIN_LABEL_DEVELOPER_TITLE: 'Developer',
  ADMIN_GENERATE_API_KEY_BUTTON: 'Generate API Key',
  ADMIN_GENERATING_API_KEY_BUTTON: 'Generating API Key',
  ADMIN_LABEL_DEVELOPER_API: 'Developer API',
  ADMIN_DEVELOPER_API_KEY_GENERATED_ON: 'Generated on',
  ADMIN_DEVELOPER_REVOKE_QUESTION_TEXT:
    'Are you sure you want to revoke this API key?',
  ADMIN_DEVELOPER_REVOKE_MODAL_TEXT:
    'Any integrations and webhooks you have configured with this API will no longer work. This action cannot be undone.',
  ADMIN_REVOKE_API_KEY_BUTTON: 'Revoke',
  ADMIN_REVOKING_API_KEY_BUTTON: 'Revoking',
  ADMIN_TEXT_API_ACCESS_TITLE: 'API Access',
  ADMIN_TEXT_API_ACCESS_CLIENT_ID: 'Client ID',
  ADMIN_TEXT_API_ACCESS_API_KEY: 'API Key',
  ADMIN_TEXT_API_ACCESS_DESC: `API Keys give you access to Maestro's`,
  ADMIN_LABEL_CONCURRENTS: 'Concurrents',
  ADMIN_LABEL_CUSTOMER_PROFILE: 'Profile',
  ADMIN_LABEL_ICONS: 'Icons',
  ADMIN_LABEL_LOCALIZATION: 'Localization',
  ADMIN_LABEL_LOG_IN: 'Log In',
  ADMIN_LABEL_USER_PROFILES: 'User Profiles',
  ADMIN_LABEL_VIDEO_PLAYER: 'Video Player',
  ADMIN_LABEL_WELCOME_SCREEN: 'Welcome',
  ADMIN_LABEL_POLLS: 'Polls',
  ADMIN_LABEL_PRODUCT: 'Product',
  ADMIN_LABEL_PRODUCTS: 'Products',
  ADMIN_LABEL_SIZE: 'Size',
  ADMIN_LABEL_TEAM: 'Team',
  ADMIN_LABEL_TEAMS: 'Teams',
  ADMIN_LABEL_TUMBLR: 'Tumblr',
  ADMIN_LABEL_QUEST: 'Quest',
  ADMIN_LABEL_QUESTS: 'Quests',
  ADMIN_LABEL_SOURCE: 'Source',
  ADMIN_LABEL_HEADER_COLOR: 'Header Color',
  ADMIN_LABEL_BACKGROUND_COLOR: 'Background Color',
  ADMIN_LABEL_BACKGROUND_COLORS: 'Background Colors',
  ADMIN_LABEL_FAQ_CARDS_COLOR: 'FAQ Cards Color',
  ADMIN_LABEL_ENTITLEMENT_TIER: 'Entitlement Tier',
  ADMIN_LABEL_THUMBNAIL: 'Thumbnail',
  ADMIN_LABEL_DIMENSIONS: makeTranslation('Dimensions: {{width}} x {{height}} px')<{ height: string; width: string; }>(),
  ADMIN_LABEL_METADATA_DURATION: 'Duration (Hour:Min:Sec)',
  ADMIN_LABEL_TWITTER: 'Twitter',
  ADMIN_LABEL_METADATA_TAG_LIMIT: 'You can only have up to 6 tags',
  ADMIN_LABEL_METADATA_NON_ALPHA_NUM_TAG: 'Tags must be alphanumeric',
  ADMIN_LABEL_TWITTER_SOURCE_PLACEHOLDER:
    'Twitter user like @twitter or a #hashtag',
  ADMIN_LABEL_NEW_SOURCE: 'New Source',
  ADMIN_LABEL_UPLOAD_A_VIDEO: 'Upload a video',
  ADMIN_LABEL_UPLOAD_A_VIDEO_OR_ADD_A_VIDEO_URL:
    'Upload a video or add a video url',
  ADMIN_LABEL_VIDEO: 'Video',
  ADMIN_LABEL_VIDEO_ANALYTICS_DESCRIPTION:
    'Track the performance of your videos and streams to see how individual pieces of content are impacting your overall viewership. Filter by date range to see trends over time and optimize your content strategy.',
  ADMIN_LABEL_VIDEOS: 'Videos',
  ADMIN_LABEL_UNIQUES_ENGAGED: 'Uniques Engaged',
  ADMIN_LABEL_TOP_THREE_PANELS: 'Top 3 Most Engaged Panels',
  ADMIN_LABEL_TOP_THREE_OVERLAYS: 'Top 3 Most Engaged Overlays',
  ADMIN_LABEL_VIDEO_LIBRARY: 'Video Library',
  ADMIN_LABEL_PLAYLIST_LIBRARY: 'Playlist Library',
  ADMIN_LABEL_SUBSCRIPTION_LIBRARY: 'Subscriptions',
  ADMIN_LABEL_TICKETS_BUNDLES_AND_SUBSCRIPTION_LIBRARY:
    'Tickets, Bundles and Subscriptions',
  ADMIN_LABEL_VK: 'VKontakte',
  STREAM_URL: 'Stream URL',
  STREAM_KEY: 'Stream Key',
  RESET_STREAM_KEY: 'Reset Stream Key',
  AND: 'and',
  ADMIN_LABEL_STREAM_DETAILS_URL:
    'Your content will appear here to preview once your stream is received from your encoder. Copy and paste the values below into your software and start streaming.',
  ADMIN_LABEL_STREAM_DETAILS_KEY:
    'Never share your stream key with anyone or show it on stream.',
  ADMIN_LABEL_STREAM_DETAILS_IMPORTANT:
    'IMPORTANT: Be sure to stop streaming from your broadcast software after you take your channel offline.',
  ADMIN_LABEL_NEED_HELP: 'Need help?',
  ADMIN_LABEL_NEED_HELP_WITHOUT_QUESTION_MARK: 'Need help',
  ADMIN_LABEL_STREAM_DETAILS: 'Stream Details',
  ADMIN_LABEL_STREAM_URL: 'Stream URL',
  ADMIN_LABEL_STREAM_KEY: 'Stream Key',
  ADMIN_LABEL_STREAM_MODE_SELECT: 'Stream',
  ADMIN_LABEL_STREAM_MODE_SELECT_HEADER: 'How will you stream?',
  ADMIN_LABEL_STREAM_MODE_SELECT_MAESTRO_STUDIO:
    'Go live on your browser and invite guests to your stream',
  ADMIN_LABEL_STREAM_MODE_SELECT_THIRD_PARTY_SOFTWARE:
    'Go live with any broadcast software you want',
  ADMIN_LABEL_REMOVE_SUBSCRIPTION:
    'This entitlement has active subscriptions. Do you want to remove it anyway?',
  ADMIN_LABEL_GROUP: 'Group',
  ADMIN_LABEL_GROUPS: 'Groups',
  ADMIN_LABEL_UNSILENCE: 'Unsilence',
  ADMIN_LABEL_GO_TO_ARCHIVE: 'Go to Archive',
  ADMIN_LABEL_BACK_TO_LIBRARY: 'Back to Library',
  ADMIN_LABEL_MAIN_MENU: 'Main Menu',
  ADMIN_LABEL_BACK_TO_MAIN_MENU: 'Back to Main Menu',
  ADMIN_LABEL_BACK_TO_CHANNELS: 'Back to Channels',
  ADMIN_LABEL_GROUPS_HELPER_TEXT:
    'People grouped together. Can have links, a banner, and a bio.',
  ADMIN_LABEL_DELAY_SECONDS: 'Delay (seconds)',
  ADMIN_PLACEHOLDER_ENTER_TUMBLR: 'Enter your tumblr URL...',
  ADMIN_PLACEHOLDER_ADD_VK: 'Enter your VKontakte URL...',
  ADMIN_PLACEHOLDER_ADD_SOURCE: 'Enter a hashtag or username',
  ADMIN_PLACEHOLDER_SEARCH: 'Search...',
  ADMIN_PLACEHOLDER_SEARCH_USER: 'Search for a user...',
  ADMIN_PLACEHOLDER_SEARCH_FOR_A_TICKET_BUNDLE_OR_SUBSCRIPTION:
    'Search for a ticket, bundle or subscription',
  PLACEHOLDER_SEARCH: 'Search...',
  ADMIN_LABEL_TWITCH_CHAT_DARK: 'Use dark theme',
  ADMIN_LABEL_USE: 'Use',
  ADMIN_LABEL_USE_CARD: 'Use this card',
  ADMIN_LABEL_USE_PANEL: 'Use Panel',
  ADMIN_LABEL_YOU_ARE_ABOUT_TO_ENTER_BROADCAST_MODE:
    'You are about to enter Broadcast Mode',
  ADMIN_LABEL_YOU_ARE_ABOUT_TO_ENTER_EDIT_MODE:
    'You are about to enter Edit Mode',
  ADMIN_PLACEHOLDER_BIO: 'Add a bio here',
  ADMIN_PLACEHOLDER_ENTER_IFRAME_URL: 'Enter Iframe URL here...',
  ADMIN_PLACEHOLDER_ENTER_NAME: 'Enter a name',
  ADMIN_PLACEHOLDER_ENTER_PANEL_NAME: 'Enter panel name',
  ADMIN_PLACEHOLDER_ENTER_DESCRIPTION: 'Enter a description...',
  ADMIN_PLACEHOLDER_ENTER_A_DESCRIPTION: 'Enter a description',
  ADMIN_PLACEHOLDER_ENTER_TEXT: 'Enter text here',
  ADMIN_PLACEHOLDER_SYSTEM_NAME: 'Enter a name',
  ADMIN_PLACEHOLDER_SYSTEM_MESSAGE: 'Enter a message',
  ADMIN_PLACEHOLDER_ENTER_TITLE: 'Enter a title',
  ADMIN_PLACEHOLDER_ENTER_YOUTUBE_PLAYLIST_URL: 'Enter YouTube playlist url',
  ADMIN_PLACEHOLDER_NAME_YOUR_PLAYLIST: 'Name your playlist...',
  ADMIN_ACTION_IMPORT_YOUTUBE_PLAYLIST: 'Import Youtube Playlist',
  ADMIN_PLACEHOLDER_ENTER_URL: 'Enter a URL here',
  ADMIN_PLACEHOLDER_ENTER_YOUTUBE_PlAYLIST_URL: 'Enter Youtube Playlist URL',
  ADMIN_PLACEHOLDER_SKU: 'Enter a sku',
  ADMIN_PLACEHOLDER_ENTER_ACCESS_CODE_AMOUNT: 'e.g 50000',
  ADMIN_PLACEHOLDER_PRICE: 'Enter a price',
  ADMIN_PLACEHOLDER_SEARCH_FOR_A_PANEL: 'Search for a panel...',
  ADMIN_PLACEHOLDER_SEARCH_FOR_A_QUESTION: 'Search for a question...',
  ADMIN_PLACEHOLDER_SEARCH_FOR_A_QUEST: 'Search for a quest...',
  ADMIN_PLACEHOLDER_SEARCH_FOR_A_CHANNEL: 'Search for a channel...',
  ADMIN_LABEL_SEARCH_LIVE_STREAM: 'Search for a livestream...',
  ADMIN_PLACEHOLDER_SEARCH_FOR_A_CARD: 'Search for a card',
  ADMIN_PLACEHOLDER_YOUR_PLAYLIST_IS_EMPTY: 'Your playlist is empty',
  ADMIN_NO_SPONSORS_ADDED: 'No Sponsors Added',
  ADMIN_PANEL_PLAY_BY_PLAY_NAME: 'Play by Play',
  ADMIN_PANEL_CHANNEL_SELECT_INFO: 'Add channel navigation as a panel',
  ADMIN_PANEL_CHANNEL_SELECT_NAME: 'Channel Select',
  ADMIN_PANEL_CHANNEL_SELECT_SECTION_LABEL: 'Kind',
  ADMIN_PANEL_CHANNEL_SELECT_SECTION_BUTTON_AUTOMATIC: 'Automatic',
  ADMIN_PANEL_CHANNEL_SELECT_SECTION_DESCRIPTION_AUTOMATIC:
    'Setting this panel to "Automatic" will gather all your channels together in to the panel.',
  ADMIN_PANEL_CHANNEL_SELECT_SECTION_DESCRIPTION_MANUAL:
    'Setting this panel to "Manual" you can select all your channels in the order you want them to be.',
  ADMIN_PANEL_CHANNEL_SELECT_SECTION_BUTTON_MANUAL: 'Manual',
  ADMIN_PANEL_CHANNEL_SELECT_SECTION_MANUAL_CHANNELS_LABEL:
    'Channels to Include',
  ADMIN_PANEL_CHAT_INFO: 'Full-featured panel complete with moderation tools',
  ADMIN_PANEL_CHAT_NAME: 'Chat',
  ADMIN_PANEL_IFRAME_INFO: 'Add a webpage via iframe to the sidebar',
  ADMIN_PANEL_IFRAME_NAME: 'Iframe',
  ADMIN_PANEL_SOCIAL_INFO: 'Add a Twitter Handle or Hashtag',
  ADMIN_PANEL_SOCIAL_NAME: 'Twitter',
  ADMIN_PANEL_SHOPIFY_INFO: 'Add a Shopify Store panel',
  ADMIN_PANEL_SHOPIFY_NAME: 'Shopify',
  ADMIN_PANEL_PEOPLE_NAME: 'People',
  ADMIN_PANEL_PEOPLE_INFO: 'Add individuals or groups to the sidebar',
  ADMIN_PANEL_PLAYLIST_NAME: 'Playlist',
  ADMIN_PANEL_PLAYLIST_INFO:
    'List all the videos of a playlist from a youtube playlist or from your library',
  ADMIN_PANEL_QUEST_INFO: 'Drive user action and reward them for engagement',
  ADMIN_PANEL_QUESTION_INFO:
    'Allow moderation and upvoting of user submitted questions',
  ADMIN_PANEL_QUEST_NAME: 'Quest',
  PANEL_QUESTION_UNANSWERED: 'OPEN QUESTIONS',
  PANEL_QUESTION_ANSWERED: 'ANSWERED QUESTIONS',
  PANEL_NO_UNANSWERED_QUESTIONS: 'NO OPEN QUESTIONS',
  PANEL_NO_QUESTION_ANSWERED: 'NO ANSWERED QUESTIONS',
  ADMIN_PANEL_TIPS_INFO: 'Allow viewers to show their support',
  ADMIN_PANEL_TIPS_NAME: 'Tips',
  ADMIN_PANEL_TIPS_EDITOR_TITLE: 'TIPS SETTINGS',
  ADMIN_PANEL_TWITCH_CHAT_NAME: 'Twitch Chat',
  ADMIN_PANEL_TWITCH_CHAT_INFO:
    `Add a chat from a Twitch channel, can be your own or someone else's`,
  ADMIN_PANEL_IMAGE_NAME: 'Image',
  ADMIN_PANEL_IMAGE_INFO:
    'Add an image as a panel to your sidebar, the image can be linked',
  ADMIN_PANEL_SCHEDULE_INFO:
    'Build your own schedule or populate it from your existing channels',
  ADMIN_PANEL_SCHEDULE_NAME: 'Schedule',
  ADMIN_PANEL_MULTIPLE_CHOICE_INFO:
    'Display a list of interactive polls, trivia, or predictions',
  ADMIN_PANEL_MULTIPLE_CHOICE_NAME: 'Multiple choice',
  ADMIN_REGION_COUNTDOWN_NAME: 'Countdown',
  ADMIN_REGION_COUNTDOWN_INFO:
    'Set your countdown to an arbitrary point in time, or tie it to your content.',
  ADMIN_REGION_COUNTDOWN_TITLE_INPUT_PLACEHOLDER:
    'Add text to the top of your countdown',
  ADMIN_SIDEBAR_SECTION_CARD: 'Card',
  ADMIN_SIDEBAR_SECTION_CARDS_HELPER:
    'Widgets that appear in the panel area when users arrive on the channel. Publish them before your stream or dynamically. Typically used to post a message, showcase a tweet, or display sponsor creative. 1 active at a time.',
  ADMIN_SIDEBAR_SECTION_PANELS: 'Panels',
  ADMIN_SIDEBAR_SECTION_PANELS_HELPER:
    'Components used to add interactivity and context alongside the stream. Users can switch between the panels you’ve added as they watch. We recommend a maximum of 5 to be active at a time.',
  ADMIN_DESIGN_TAB_TITLE: 'Themes',
  ADMIN_DESIGN_TAB_DESCRIPTION:
    'Choose a theme for your channel or make your own to match your unique look and feel. You can choose between a classic theme or advanced theme and customize them.',
  ADMIN_DESIGN_TAB_ACTIVE_THEME: 'Active theme',
  ADMIN_DESIGN_TAB_CLASSIC_THEMES: 'Classic Themes',
  ADMIN_DESIGN_TAB_CUSTOM_THEMES: 'Custom Themes',
  ADMIN_DESIGN_TAB_ADVANCED_THEMES: 'Advanced Themes',
  ADMIN_DESIGN_TAB_DELETE_THEME_CONFIRMATION_TITLE:
    'Are you sure you want to delete this theme?',
  ADMIN_DESIGN_TAB_DELETE_THEME_CONFIRMATION_DESCRIPTION:
    'You will not be able to undo this action',
  ADMIN_DESIGN_TAB_ACTIVE_ON_PAGE_MODAL_TITLE:
    makeTranslation('This {{feature}} is currently active on these channels:')<{ feature: string }>(),
  ADMIN_DESIGN_TAB_ACTIVATE_THEME_CONFIRMATION_MODAL_DESCRIPTION:
    'Do you want to make this theme the active theme on this channel?',
  ADMIN_DESIGN_TAB_ACTIVE_ON_PAGE_MODAL_DESCRIPTION:
    makeTranslation('You can’t delete {{feature}} that are active on channels.')<{ feature: string }>(),
  ADMIN_DESIGN_TAB_ACTIVE_THEMES_MODAL_BUTTON: 'Okay',
  ADMIN_DESIGN_TAB_ACCENT_COLOR: 'Accent Color',
  ADMIN_DESIGN_TAB_CUSTOMIZE_THEME_DESCRIPTION:
    'Make it your own! Edit a theme to make it look and feel like your unique brand. Once created, you can use it on any channel.',
  ADMIN_DESIGN_TAB_CUSTOMIZE_THEME_TITLE: 'Theme Name',
  ADMIN_DESIGN_TAB_COLORS: 'Colors',
  ADMIN_DESIGN_TAB_ACCENTS: 'Accents',
  ADMIN_DESIGN_TAB_ACCENT_COLOR_TOOLTIP:
    'This is usually your main brand color, we’ll use it for buttons, selectors and to highlight important action elements across your platform.',
  ADMIN_DESIGN_TAB_HIGHLIGHT_COLOR: 'Highlight Color',
  ADMIN_DESIGN_TAB_HIGHLIGHT_COLOR_TOOLTIP:
    'We use this color on your panel icons and other secondary icons across your platform.',
  ADMIN_DESIGN_TAB_SURFACES: 'Surfaces',
  ADMIN_DESIGN_TAB_SURFACE_TONE: 'Surface Tone',
  ADMIN_DESIGN_TAB_SURFACE_TONE_TOOLTIP:
    'Surface is the set of colors we will use for the background of your platform.  ',
  ADMIN_DESIGN_TAB_BACKGROUND: 'Background',
  ADMIN_DESIGN_TAB_DESKTOP_BACKGROUND: 'Desktop Background',
  ADMIN_DESIGN_TAB_MOBILE_BACKGROUND: 'Mobile Background',
  ADMIN_DESIGN_TAB_FONTS: 'Fonts',
  ADMIN_DESIGN_TAB_HEADLINE_FONT: 'Headline Font',
  ADMIN_DESIGN_TAB_HEADLINE_FONT_TOOLTIP:
    'Headline Font is the font you want to use for your titles and section headers',
  ADMIN_DESIGN_TAB_BODY_FONT: 'Body Font',
  ADMIN_DESIGN_TAB_BODY_FONT_TOOLTIP:
    'Body Font is the font you want to use for pharagraphs and regular text across your design',
  ADMIN_DESIGN_TAB_SELECT_FONT_PLACEHOLDER: 'Select a font',
  ADMIN_SETTINGS_DESIGN_LABEL_ACTIVE_COLOR: 'Primary Highlight',
  ADMIN_SETTINGS_DESIGN_LABEL_ACTIVE_COLOR_TEXT: 'Text Color (Active)',
  ADMIN_SETTINGS_DESIGN_SIDEBAR_BACKGROUND_COLOR: 'Panel Selector Background',
  ADMIN_SETTINGS_DESIGN_LABEL_COUNTDOWN_COLOR: 'Countdown Text Color',
  ADMIN_SETTINGS_DESIGN_LABEL_CHANNEL_BACKGROUND_COLOR:
    'Channel Background Color',
  ADMIN_SETTINGS_DESIGN_LABEL_SIDEBAR_TITLE_BACKGROUND_COLOR:
    'Panel Header Background',
  ADMIN_SETTINGS_DESIGN_LABEL_SIDEBAR_TITLE_COLOR: 'Panel Icons',
  ADMIN_SETTINGS_DESIGN_LABEL_TITLE_FONT_FAMILY: 'Header Font',
  ADMIN_SETTINGS_DESIGN_LABEL_BODY_COLOR: 'Body Text Color',
  ADMIN_SETTINGS_DESIGN_LABEL_SIDEBAR_CONTENT_COLOR: 'Panel Text',
  ADMIN_SETTINGS_DESIGN_LABEL_SIDEBAR_BODY_BACKGROUND_COLOR:
    'Panel Content Background',
  ADMIN_SETTINGS_DESIGN_LABEL_CONTENT_FONT: 'Content Font',
  ADMIN_SETTINGS_DESIGN_LABEL_PAGE_BACKGROUND_COLOR: 'Background Color',
  ADMIN_SETTINGS_DESIGN_LABEL_FOOTER_COLOR: 'Link/Text Color',
  ADMIN_SETTINGS_DESIGN_LABEL_TEXT_COLOR: 'Text Color',
  SURFACE_1: 'Surface 1',
  SURFACE_2: 'Surface 2',
  SURFACE_3: 'Surface 3',
  SURFACE_4: 'Surface 4',
  SURFACE_5: 'Surface 5',
  SURFACE_6: 'Surface 6',
  ACCENT_PRIMARY: 'Accent Primary',
  HIGHLIGHT_PRIMARY: 'Highlight Primary',
  ADMIN_SETTINGS_DESIGN_LABEL_SOCIAL_LINK_COLOR: 'Social Link Color',
  ADMIN_SETTINGS_DESIGN_LABEL_GRID_COLOR: 'Grid Text Color',
  ADMIN_SETTINGS_DESIGN_LABEL_CHANNEL_SELECT_COLOR: 'Channel Select Color',
  ADMIN_SETTINGS_DESIGN_MORE_INFO: 'More Info',
  ADMIN_SETTINGS_DESIGN_CHANNEL_HELPER_TEXT:
    'Decide the look of your channel, select colors for your titles, body text, change the style of your panels, buttons, add background images and so much more.',
  ADMIN_SETTINGS_DESIGN_PAGE_BACKGROUND_IMAGE: 'Background Image',
  ADMIN_SETTINGS_DESIGN_SECTION_COLORS: 'Colors',
  ADMIN_SETTINGS_DESIGN_SECTION_SIDEBAR: 'Panel',
  ADMIN_SETTINGS_DESIGN_SECTION_PAGE: 'Channel',
  ADMIN_SETTINGS_DESIGN_SECTION_FONTS: 'Fonts',
  ADMIN_SETTINGS_DESIGN_SECTION_FONTS_UPLOAD: 'Upload Custom Font',
  ADMIN_SETTINGS_DESIGN_SECTION_LOGO: 'Logo & Title',
  ADMIN_SETTINGS_DESIGN_SECTION_LOGO_WEB: 'Desktop',
  ADMIN_SETTINGS_DESIGN_SECTION_LOGO_MOBILE: 'Mobile',
  ADMIN_LABEL_GLOBAL_SETTINGS: 'Global Settings',
  ADMIN_LABEL_CHANNEL_SETTINGS: 'Channel Settings',
  ADMIN_LABEL_CURRENT_CHANNEL_SETTINGS: 'Current Channel Settings',
  ADMIN_LABEL_CURRENT_CHANNEL: 'Current Channel',
  ADMIN_LABEL_ADMIN: 'Admin',
  ADMIN_LABEL_PRIMARY_DETAILS_UPDATED: 'Payment method details updated!',
  ADMIN_LABEL_GO_BACK: 'Go Back',
  ADD_AND_CONTINUE: 'Add and Continue',
  ADMIN_LABEL_KEYWORDS: 'Keywords',
  ADMIN_LABEL_KEYWORDS_FIELD_DESCRIPTION: 'A comma separated list of keywords',
  ADMIN_LABEL_PURCHASED: 'Joined',
  ADMIN_LABEL_CANCEL_SUBSCRIPTION: 'Cancel Subscription',
  ADMIN_LABEL_CANCEL_SUBSCRIPTION_CONFIRMATION:
    'Are you sure you want to cancel your subscription?',
  ADMIN_LABEL_CANCEL_SUBSCRIPTION_CONFIRMATION_SUBTITLE:
    'Your subscription will be canceled on',
  ADMIN_LABEL_CANCEL_SUBSCRIPTION_CONFIRMATION_IMMEDIATE:
    'Your subscription will be canceled immediately',
  ADMIN_LABEL_ACTIVE: 'Active',
  ADMIN_LABEL_INVALID: 'Check Credentials',
  ADMIN_LABEL_EXPIRED: 'Expired',
  ADMIN_LABEL_CANCEL_SCHEDULE: 'Cancels on',
  ADMIN_LABEL_EDITOR: 'Editor',
  ADMIN_LABEL_LOGO: 'Logo',
  ADMIN_LABEL_LOGO_SETTINGS: 'Logo Settings',
  ADMIN_LABEL_STREAMING: 'Stream',
  ADMIN_YOUR_CHANNEL_IS_OFFLINE: 'Your Channel is Offline',
  ADMIN_LABEL_EDIT_GATE: 'Edit Gate',
  ADMIN_LABEL_DESIGN: 'Design',
  ADMIN_LABEL_ENGAGE_AND_MONETIZE: 'Engage & Monetize',
  ADMIN_LABEL_ANALIZE_AND_OPTIMIZE: 'Analyze & Optimize',
  ADMIN_LABEL_EARNINGS: 'Earnings',
  ADMIN_LABEL_MANAGE: 'Manage',
  ADMIN_LABEL_ADMINS: 'Admins',
  ADMIN_LABEL_OPTION: 'Option',
  ADMIN_LABEL_ADMINS_SETTINGS: 'Admins Settings',
  ADMIN_LABEL_ADMIN_SETTINGS: 'Admin Settings',
  ADMIN_LABEL_INVITE_SETTINGS: 'Invitation Settings',
  ADMIN_LABEL_SETTINGS: 'Settings',
  ADMIN_LABEL_SITE_SETTINGS: 'Site Settings',
  ADMIN_LABEL_WIZARD: 'Wizard',
  ADMIN_LABEL_SUPER_ADMIN: 'Super Admin',
  ADMIN_LABEL_ANALYTICS: 'Analytics',
  ADMIN_LABEL_GREETING: 'Hello,',
  ADMIN_SPONSORS: 'Admin Sponsors',
  ADMIN_SALES_REP_CONTACT_ME: 'Have a sales rep contact me',
  ADMIN_SALES_REP_CONTACT_ME_LEARN_MORE: 'Want to learn more?',
  ADMIN_UPGRADE_SITE: `I'm interested in upgrading my site`,
  ADMIN_TEXT_LIVE_STREAM_DESC:
    'Click on the live stream that you want to push live to view the URL and stream key',
  ADMIN_TITLE_LIVE_STREAMS: 'Live Streams',
  ADMIN_LABEL_UNKNOWN_MODEL: 'Unknown model',
  ADMIN_LABEL_MODEL_CREATION_ERROR: 'Error creating model',
  NOT_FOUND: 'Not found',
  ADMIN_LABEL_INVALID_TOKEN: 'Token is invalid',
  ADMIN_LABEL_START_WITH_HTTPS: 'Start with https://',
  ADMIN_LABEL_MALFORMED_URL: 'Malformed URL',
  ADMIN_LABEL_INVALID_IFRAME_SOURCE: 'Invalid iframe source',
  ADMIN_LABEL_MP4_UNSUPPORTED: 'Can\'t load mp4 video',
  ADMIN_LABEL_HLS_LOAD_ERROR: 'Unable to load HLS video',
  ADMIN_LABEL_VIDEO_NOT_FOUND: 'Video not found',
  ADMIN_LABEL_METADATA_FETCH_ERROR: 'Unable to fetch metadata',
  SEARCH_ALL_PAGES: 'Search all pages',
  SITE_STRUCTURE: 'Site Structure',
  ADMIN_SITE_STRUCTURE_TOOLTIP: 'Your main navigation menu will follow the site structure by default. You can change this in your navigation block settings.',
  UNPUBLISHED_CHANGES: 'Unpublished Changes',
  CREATE_POWERFUL_OFFLINE_EXPERIENCES: 'Create powerful offline experiences',
  GO_LIVE_WITH_AN_INTERACTIVE_VIDEO_EXPERIENCE: 'Go live with an interactive video experience',
  FOLDER: 'Folder',
  ORGANIZE_YOUR_PAGES_AND_LIVE_CHANNELS: 'Organize your pages and live channels',
  SET_AS_HOMEPAGE: 'Set as Homepage',
  DUPLICATE_PAGE: 'Duplicate Page',
  ADMIN_SITE_STRUCTURE_CARDS_MENU_PAGE_HIDE: 'Hide Page',
  ADMIN_SITE_NAVIGATION_CARDS_MENU_PAGE_DELETE_CONFIRMATION_SUBTITLE: 'This will delete the Page and move all of its contents to the main directory',
  ADMIN_PROMPT_CONFIRM_FOLDER_DELETION: 'Are you sure you want to delete this Folder?',
  ADMIN_PROMPT_CONFIRM_FOLDER_DELETION_DESCRIPTION: 'This will delete the Folder and move all of its contents to the main directory',
  DUPLICATE_CHANNEL: 'Duplicate Channel',
  ADMIN_SITE_STRUCTURE_CARDS_MENU_CHANNEL_HIDE: 'Hide Channel',
  RENAME_FOLDER: 'Rename Folder',
  DELETE_FOLDER: 'Delete Folder',
  CREATE_SUB_PAGE: 'Create Sub-Page',
  CREATE_SUB_CHANNEL: 'Create Sub-Channel',
  ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_PAGE_CONFIRMATION_TITLE: 'Are you sure you want to change the URL of this page?',
  ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_PAGE_CONFIRMATION_SUBTITLE: 'Changing the URL structure of a page that has already been published can cause broken links.',
  ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_CHANNEL_CONFIRMATION_TITLE: 'Are you sure you want to change the URL of this channel?',
  ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_CHANNEL_CONFIRMATION_SUBTITLE: 'Changing the URL structure of a channel that has already been published can cause broken links.',
  ADMIN_NAVIGATION_BLOCK_TITLE: 'Navigation',
  ADMIN_NAVIGATION_BLOCK_SUBTITLE: 'Which menu is displayed?',
  ADMIN_NAVIGATION_BLOCK_INFO: 'Your navigation menu will follow the site structure by default. You can change that here.',
  ADMIN_NAVIGATION_BLOCK_APPLY: 'Apply Navigation',
  ADMIN_NAVIGATION_BLOCK_DROPDOWN_SELECT: 'Select an option or create one',
  ADMIN_NAVIGATION_BLOCK_DROPDOWN_MENU_ACTION_DUPLICATE_NAVIGATION: 'Duplicate',
  ADMIN_NAVIGATION_BLOCK_DROPDOWN_MENU_ACTION_RENAME: 'Rename',
  ADMIN_NAVIGATION_BLOCK_DROPDOWN_HIT_ENTER: 'hit enter',
  ADMIN_NAVIGATION_BLOCK_DROPDOWN_MENU_ACTION_DELETE_NAVIGATION: 'Delete',
  ADMIN_NAVIGATION_BLOCK_DROPDOWN_CREATE_CUSTOM_NAVIGATION: 'Create Custom Navigation',
  ADMIN_NAVIGATION_BLOCK_DROPDOWN_CREATE_CUSTOM_NAVIGATION_DYNAMIC: makeTranslation(`Create '{{navName}}'`)<{ navName: string }>(),
  EXTERNAL_LINKS: 'External Links',
  ADD_EXTERNAL_LINK: 'Add External Link',
  DELETE_LINK: 'Delete Link',
  DELETE_LINK_CONFIRMATION: 'Are you sure you want to delete this link?',
  ADMIN_NAVIGATION_BLOCK_CUSTOM_SECTION_TITLE: 'Menu Items',
  ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_SELECT: 'Select an option or create one',
  ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_FOLDER: 'Create Folder',
  ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_FOLDER_DYNAMIC: makeTranslation(`Create Folder '{{folderName}}'`)<{ folderName: string }>(),
  ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_LINK: 'Create Link',
  ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_CREATE_LINK_DYNAMIC: makeTranslation(`Create Link '{{linkName}}'`)<{ linkName: string }>(),
  ADMIN_NAVIGATION_BLOCK_CUSTOM_DROPDOWN_PLACEHOLDER: 'Add menu item',
  ADMIN_NAVIGATION_BLOCK_CUSTOM_MENU_ITEM_ACTIONS_RENAME: 'Rename',
  ADMIN_NAVIGATION_BLOCK_CUSTOM_MENU_ITEM_ACTIONS_REMOVE: 'Remove',
  ADMIN_NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUT_NAME_PLACEHOLDER: 'Enter Link Name',
  ADMIN_NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUT_URL_PLACEHOLDER: 'Enter Link URL',
  ADMIN_NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUT_ERROR_MESSAGE: 'Link error. Please Try again',
  ADMIN_NAVIGATION_BLOCK_CARDS_MENU_CUSTOM_NAVIGATION_DELETE: 'Are you sure you want to delete this custom navigation menu?',
  ADMIN_NAVIGATION_BLOCK_CARDS_MENU_CUSTOM_NAVIGATION_ITEM_DELETE_TITLE: 'Are you sure you want to delete this item from the navigation?',
  ADMIN_NAVIGATION_BLOCK_CARDS_MENU_CUSTOM_NAVIGATION_ITEM_DELETE_SUBTITLE: 'This will delete the item and move all of its contents to the main directory',
  AMAZON_BENEFIT: 'Amazon Benefit',
  LABEL_ACCESS_CODE_METHOD: makeTranslation('Access Code: {{accessCode}}')<{ accessCode: string }>(),
  LABEL_PURCHASED_METHOD: makeTranslation('Purchased: {{price}}')<{ price: string }>(),
  LABEL_REFUNDED_DATE: makeTranslation('Refunded on {{month}} {{day}}, {{year}}')<{
    day: number,
    month: string,
    year: number,
  }>(),
  LABEL_CANCELED_DATE: makeTranslation('Canceled on {{month}} {{day}}, {{year}}')<{
    day: number,
    month: string,
    year: number,
  }>(),
  LABEL_SUB_CANCELED_DATE_PAST: makeTranslation('Subscription was canceled on {{month}} {{day}}, {{year}}')<{
    day: number,
    month: string,
    year: number,
  }>(),
  LABEL_SUB_CANCELED_DATE_FUTURE: makeTranslation('Subscription will be canceled on {{month}} {{day}}, {{year}}')<{
    day: number,
    month: string,
    year: number,
  }>(),
  LABEL_CLONED_CHANNEL_DEFAULT_NAME: makeTranslation('Copy of {{originalName}}')<{ originalName: string }>(),
  LABEL_CLONED_CHANNEL_DEFAULT_SLUG: makeTranslation('{{originalSlug}}-copy')<{ originalSlug: string }>(),
  LABEL_CANCELED: 'Canceled',
  LABEL_EXPIRED: 'Expired',
  LABEL_TICKET_REMOVED: 'Ticket Removed',
  LABEL_MANUALLY_GRANTED: 'Manually granted',
  LABEL_GRANTED_BY_API: 'Granted by API',
  LABEL_PLAYER_TYPE_AMAZON: 'Amazon',
  LABEL_PLAYER_TYPE_DAILYMOTION: 'Dailymotion',
  LABEL_PLAYER_TYPE_FACEBOOK: 'Facebook',
  LABEL_PLAYER_TYPE_FILE: 'File',
  LABEL_PLAYER_TYPE_IFRAME: 'Iframe',
  LABEL_PLAYER_TYPE_LIVESTREAM: 'Livestream',
  LABEL_PLAYER_TYPE_MIXCLOUD: 'Mixcloud',
  LABEL_PLAYER_TYPE_SOUNDCLOUD: 'Soundcloud',
  LABEL_PLAYER_TYPE_STREAMABLE: 'Streamable',
  LABEL_PLAYER_TYPE_TWITCH: 'Twitch',
  LABEL_PLAYER_TYPE_VIMEO: 'Vimeo',
  LABEL_PLAYER_TYPE_WISTIA: 'Wistia',
  LABEL_PLAYER_TYPE_YOUTUBE: 'Youtube',
  LABEL_ADD: 'Add',
  LABEL_CANCEL: 'Cancel',
  LABEL_HEADING_1: 'Heading 1',
  LABEL_HEADING_2: 'Heading 2',
  LABEL_HEADING_3: 'Heading 3',
  LABEL_HEADING_4: 'Heading 4',
  LABEL_HEADING_5: 'Heading 5',
  LABEL_HEADING_6: 'Heading 6',
  LABEL_BLOCKQUOTE: 'Blockquote',
  LABEL_CODE: 'Code',
  LABEL_BLOCK_TYPE: 'Block Type',
  LABEL_NORMAL: 'Normal',
  LABEL_COLOR_PICKER: 'Color Picker',
  LABEL_TEXT: 'Text',
  LABEL_HIGHLIGHT: 'Highlight',
  LABEL_EMBEDDED: 'Embedded',
  LABEL_EMBEDDED_LINK: 'Embedded Link',
  LABEL_ENTER_LINK: 'Enter link',
  LABEL_EMOJI: 'Emoji',
  LABEL_FONT: 'Font',
  LABEL_FONT_SIZE: 'Font Size',
  LABEL_HISTORY: 'History',
  LABEL_UNDO: 'Undo',
  LABEL_REDO: 'Redo',
  LABEL_IMAGE: 'Image',
  LABEL_FILE_UPLOAD: 'File Upload',
  LABEL_URL: 'URL',
  LABEL_DROP_THE_FILE_OR_CLICK_TO_UPLOAD: 'Drop the file or click to upload',
  LABEL_BOLD: 'Bold',
  LABEL_ITALIC: 'Italic',
  LABEL_UNDERLINE: 'Underline',
  LABEL_STRIKETHROUGH: 'Strikethrough',
  LABEL_MONOSPACE: 'Monospace',
  LABEL_SUPERSCRIPT: 'Superscript',
  LABEL_SUBSCRIPT: 'Subscript',
  LABEL_LINK_TITLE: 'Link Title',
  LABEL_LINK_TARGET: 'Link Target',
  LABEL_OPEN_LINK_IN_NEW_WINDOW: 'Open link in new window',
  LABEL_LINK: 'Link',
  LABEL_UNLINK: 'Unlink',
  LABEL_LIST: 'List',
  LABEL_UNORDERED: 'Unordered',
  LABEL_ORDERED: 'Ordered',
  LABEL_INDENT: 'Indent',
  LABEL_OUTDENT: 'Outdent',
  LABEL_REMOVE: 'Remove',
  LABEL_TEXT_ALIGN: 'Text Align',
  LABEL_LEFT: 'Left',
  LABEL_CENTER: 'Center',
  LABEL_RIGHT: 'Right',
  LABEL_JUSTIFY: 'Justify',
  ADMIN_TEXT_BILLING_DESC: 'View your bills and payouts',
  ADMIN_TEXT_CODE_INJECTION_DESC: 'Add code to your site.',
  ADMIN_TEXT_CODE_INJECTION_WARN:
    'WARNING: only use this if you know what you are doing.',
  ADMIN_TEXT_CODE_INJECTION_HELP: 'Learn more about using code injection.',
  ADMIN_CUSTOM_MODAL_TEXT: 'Used to explain the benefits of creating an account',
  ADMIN_TERMS_OF_SERVICE_URL: 'Terms of service url',
  TERMS_OF_SERVICE: 'Terms of Service',
  ADMIN_TERMS_OF_SERVICE_URL_DESCRIPTION:
    'Defaults to https://www.maestro.io/legal/tou if left blank',
  ADMIN_PRIVACY_POLICY_URL: 'Privacy policy url',
  ADMIN_PRIVACY_POLICY_URL_DESCRIPTION:
    'Defaults to https://www.maestro.io/legal/privacy if left blank',
  ADMIN_MARKETING_OPTIN_TOGGLE: 'Enable Marketing Opt-in',
  ADMIN_MARKETING_OPTIN_LABEL: 'Marketing opt-in message',
  ADMIN_MARKETING_OPTIN_DESCRIPTION:
    'Message shown for marketing opt-in toggle switch',
  ADMIN_CUSTOM_MODAL_TEXT_LABEL: 'Custom Modal Text',
  ADMIN_SHOPIFY_CONNECT_ERROR:
    'There was an error connecting your Shopify store to Maestro. Please try again from Shopify',
  ADMIN_PROMPT_CONFIRM_SHOPIFY_CONNECT:
    'Please click "Yes" to confirm the connection to your Shopify store. In doing so, you agree to a small commission charge on transactions made though Maestro.',
  ADMIN_SHOPIFY_CONNECT_SUCCESS:
    'Store successfully connected.',
  ADMIN_EARNINGS_DASHBOARD_TITLE_TEXT: 'Great Job Team!,',
  ADMIN_EARNINGS_DASHBOARD_SUBTITLE_TEXT: 'here is your earnings overview',
  ADMIN_EARNINGS_DASHBOARD: 'Great Job Team!, here is your earnings overview',
  ADMIN_EARNINGS_DASHBOARD_HEADER_TITLE: 'Analyze & Optimize',
  ADMIN_EARNINGS_DASHBOARD_HEADER_SUBTITLE: 'Earnings Dashboard',
  ADMIN_EARNINGS_BREAKDOWN_HEADER_TITLE: 'Earnings Breakdown by Time',
  ADMIN_ENTITLEMENTS_HEADER_TITLE: 'Entitlements',
  ADMIN_ENTITLEMENTS_HEADER_CONTENT_LABEL: 'TOTAL',
  ADMIN_ENTITLEMENTS_TICKETS: 'Tickets',
  ADMIN_ENTITLEMENTS_CHANGE: 'Compared to previous month',
  ADMIN_ENTITLEMENTS_SOLD: 'Sold',
  ADMIN_ENTITLEMENTS_SUBSCRIPTIONS: 'Subscriptions',
  ADMIN_ENTITLEMENTS_NO_ENTITLEMENTS_FIRST_TEXT: 'There are no',
  ADMIN_ENTITLEMENTS_NO_ENTITLEMENTS_LAST_TEXT: 'transactions to display',
  ADMIN_TIME_PERIOD_ALL_TIME_TITLE: 'Time Period',
  ADMIN_TIMEZONE_TITLE: 'Time zone',
  ADMIN_TIMEZONE_INFO:
    'Timezone is for the location detected when you created your account.',
  ADMIN_INVITE_NEW_ADMIN: 'Invite new admin',
  ADMIN_INVITE_AVAILABLE_SEATS: 'Available Seats: ',
  ADMIN_INVITE_NEED_MORE_SEATS: 'Need more seats?',
  ADMIN_INVITE_UPGRADE_PLAN: 'Upgrade Plan',
  ADMIN_ADDED: 'New Admin Added',
  ADMIN_ALREADY_EXIST:
    'An account with this email address already exists, and has now been made an Admin.',
  INVITE_INVALID_LINK: 'Your link is invalid. Please check and try again.',
  INVITE_CONSUMED_LINK: 'This invite has already been used',
  INVITE_EXPIRED:
    'Your administration invitation has expired. You will need to contact your administrator to obtain an admin role. ',
  INVITE_CONSUMED:
    'This invitation has already been used. Please contact your administrator to obtain an admin role. ',
  INVITE_NOT_VALID:
    'Invitation is not valid anymore. Please contact your administrator to obtain an admin role.',
  GRANTED_ADMIN: 'You have been granted with an admin role.',
  ADMIN_INVITE: 'Invite admin',
  ADMIN_INVITATIONS: 'Invitations',
  ADMIN_RESEND: 'Resend',
  ADMIN_ADD_COLABORATOR:
    'Add the email address of the person you want to invite as an admin and select the role they should have. Alternatively you can select the role and send them the invite link directly. Invitations are valid for 24hrs, but can be re-sent.',
  ADMIN_SELECT_ROLE: 'Select a role',
  ADMIN_EMAIL_ADRESS: 'Email address',
  ADMIN_ROLE_MODERATOR:
    'Access to moderation features on specific pages or channels.',
  ADMIN_ROLE_PRODUCER: 'Producer',
  ADMIN_ROLE_PRODUCER_DESC: 'Everything a producer needs to operate your site. Access to chat moderation tools, overlays, panels, design, navigation, editor, and publish functions.',
  ADMIN_ROLE_USER_DESC: 'No special access of any kind.',
  ADMIN_ROLE_USER: 'User',
  ADMIN_SEND_INVITE: 'Send invite',
  ADMIN_COPY_LINK: 'Copy link',
  ADMIN_LINK_COPIED: 'Link copied to clipboard',
  ADMIN_EARNINGS_TICKETS_INFO:
    'Earnings received from the face value of tickets sold during the selected time period',
  ADMIN_EARNINGS_SUBSCRIPTIONS_INFO:
    'Earnings received from the face value of subscription transactions during the selected time period',
  ADMIN_EARNINGS_SHOPIFY_INFO:
    'Earnings received from the face value of items sold during the selected time period',
  ADMIN_EARNINGS_TIPPING_INFO:
    'Earnings received from the face value of tips made during the selected time period.',
  NO_ITEMS: 'No items',
  ITEM: 'Item',
  ITEMS: 'Items',
  PAY_NOW: 'Pay Now',
  ADMIN_EARNINGS_DASHBOARD_EARNED_FROM: 'EARNED FROM',
  ADMIN_EARNINGS_DASHBOARD_TICKETS_SOLD: 'Tickets Sold',
  ADMIN_EARNINGS_DASHBOARD_SUBSCRIPTIONS_SOLD: 'Subscriptions',
  ADMIN_EARNINGS_DASHBOARD_PRODUCTS_SOLD: 'Total Products Sold',
  ADMIN_EARNINGS_DASHBOARD_ITEMS_SOLD: 'Total Items Sold',
  ADMIN_EARNINGS_DASHBOARD_TOTAL_CUSTOMERS: 'Total Customers',
  ADMIN_EARNINGS_DASHBOARD_TOTAL_TRANSACTIONS: 'Total Transactions',
  ADMIN_EARNINGS_DASHBOARD_AVERAGE_PRODUCTS: 'Average Products per Transaction',
  ADMIN_EARNINGS_DASHBOARD_TOTAL_TIPS: 'Total Tips',
  ADMIN_EARNINGS_DASHBOARD_FREQUENT_AMOUNT: 'Most Frequent Amount',
  ADMIN_EARNINGS_DASHBOARD_UNIQUE_TIPPERS: 'Unique Tippers',
  ADMIN_THEMES_CUSTOMIZE_THEME: 'Customize Theme',
  ADMIN_THEMES_SET_DEFAULT: 'Set as default',
  ADMIN_THEMES_DELETE_THEME: 'Delete theme',
  ADMIN_THEMES_SET_AS_DEFAULT: 'Set as default',
  ADMIN_THEMES_Delete_THEME: 'Delete theme',
  ADD_TO_CALENDAR: 'Add to calendar',
  DEFAULT_EVENT_TITLE: 'Event',
  DEFAULT_EVENT_TIME: 'TBD',
  AMPERSAND: '&',
  AN_EMAIL_CONTAINING_VERIFICATION_INSTRUCTIONS_WAS_SENT_TO:
    'An email containing verification instructions was sent to',
  AN_EMAIL_CONTAINING_VERIFICATION_INSTRUCTIONS_WILL_BE_SENT_TO:
    'An email containing verification instructions will be sent to',
  ANSWER: 'Answer',
  AUDIENCE_POLL: 'Audience Poll',
  AVAILABLE: 'Available',
  BY: 'by',
  BACK: 'Back',
  NEXT: 'Next',
  BIO: 'Bio',
  BILLING_SUBMIT_BILLING_INFO: 'Continue with payment',
  BILLING_SAVE_PAYMENT: 'SAVE',
  BILLING_INVALID_INPUT: 'invalid input (minimum length or invalid characters)',
  BILLING_SUBMIT_PAYMENT: 'Submit Payment',
  ACTION_PAY_WITH: 'Pay with',
  ACTION_BUY_NOW: 'BUY NOW',
  ACTION_BUY_FOR: 'BUY FOR',
  TICKET_BUTTON: 'Buy',
  SUBSCRIPTION_BUTTON: 'Subscribe',
  BUNDLE_BUTTON: 'Buy',
  SUBSCRIPTION_MONTH_PRICE_SUFFIX: '/Month',
  SUBSCRIPTION_YEAR_PRICE_SUFFIX: '/Year',
  BUY: 'Buy',
  OR_PAY_WITH_CARD: 'or pay with card',
  CANCEL: 'Cancel',
  REFUND: 'Refund',
  REFUNDED: 'Refunded',
  REMOVED: 'Removed',
  REASON: 'Reason',
  DETAILS: 'Details',
  CHANGE: 'Change',
  CHANNEL_STATUS_LIVE: 'LIVE',
  CHANNEL_STATUS_LIVE_DEFAULT: 'LIVE',
  CHANNEL_STATUS_OFFLINE: 'Offline',
  CHAT_LOGIN_PROMPT:
    `You've been assigned a random username. You can change it by clicking 'Log In' in the top-right corner or below`,
  CHAT_DUPLICATE_MESSAGE: `Don't send the same message more than once.`,
  CHAT_LONG_MESSAGE: 'Messages must be 240 characters or less.',
  CHAT_SILENCED_USER: `You've been silenced by an admin.`,
  CHAT_TOO_FAST: `Slow down! You're sending messages too fast`,
  CHAT_WELCOME_BACK: 'Welcome back!',
  CITY: 'City',
  CLAIM_YOUR_PRIZE: 'CLAIM YOUR PRIZE',
  CLAIMED: 'Claimed',
  CLEAR_CHAT: 'Clear chat',
  CLEAR_CHAT_PROMPT: 'Are you sure you want to clear the chat?',
  CLOSE: 'Close',
  CLOSE_LOWERCASE: 'close',
  CLOSE_PANEL: 'Close panel',
  EMPTY_NAME: 'Name is required',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  PASSWORD_CHANGE_SUCCESS: 'Password updated!',
  INVALID_LENGTH_PASSWORD: 'Must be at least 8 characters',
  PASSWORD_MUST_MATCH: 'Both passwords must match',
  INVALID_CURRENT_PASSWORD: 'Current password is incorrect, try again',
  COPIED: 'Copied',
  COPIED_SUCCESS_LABEL: 'Copied!',
  CORRECT: 'Correct',
  CONTINUE_WITH_CURRENT_DEVICES: 'Continue with current devices',
  COUNTRY: 'Country',
  BILLING_SERVICE_CHARGE: 'Service Charge',
  BILLING_AMOUNT_TICKET: 'Ticket Price',
  BILLING_AMOUNT_SUBSCRIPTION: 'Subscription Price',
  BILLING_TAX: 'Tax',
  BILLING_COUNTRY: 'Country',
  BILLING_STATE: 'State',
  BILLING_PROVINCE: 'Province',
  AREA_CODE: 'Zip Code',
  BILLING_ERROR_MESSAGE_EXPIRATION_DATE:
    'invalid input (invalid characters or wrong format)',
  CODE_INJECTION_ERROR:
    'Injection exceeds 500 KB limit. Please reduce the size or use a self-hosted URL in script tags.',
  CODE_INJECTION_NOTE:
    'Note: Maximum file size is 500 KB. If your injection exceeds 500 KBs, you may insert a self-hosted URL in script tags.',
  CREATE_AN_ACCOUNT: 'CREATE AN ACCOUNT',
  CREATED_ON: 'Created on',
  CREATE_ACCOUNT: 'Create Account',
  CREATE_ACCOUNT_MESSAGE:
    'Create an account to chat with the community and interact with the livestream.',
  CURRENTLY_WATCHING_ON: 'Currently watching on',
  CURRENT_LOCATION: 'Current location',
  DAY: 'Day',
  DEFAULT_PROFILE: 'Profile',
  DID_NOT_CLAIM: 'DID NOT CLAIM',
  DISMISS: 'Dismiss',
  DONE: 'Done',
  EDIT_PROFILE: 'Edit Profile',
  EMAIL: 'Email',
  CONFIRMED_EMAIL: 'Confirm Email',
  CONFIRMED_EMAIL_DOES_NOT_MATCH:
    `Email addresses don't match, please try again.`,
  CONFIRMED_PASSWORD: 'Confirm Password',
  CONFIRMED_PASSWORD_DOES_NOT_MATCH:
    'Passwords don’t match, please update and try again.',
  EMAIL_IS_NOT_VALID: 'Email is not valid',
  EMBED: 'Embed',
  EMBED_VIDEO: 'Embed video',
  ENGAGED: 'Engaged',
  NON_ENGAGED: 'Non-engaged',
  ENTER_CODE: 'Enter Code',
  LABEL_TASK: 'Task',
  LABEL_POINTS: 'Points',
  LABEL_KILLS: 'Kills',
  LABEL_ISLAND_VISITS: 'Island Visits',
  LABEL_SKELETONS: 'Skeletons',
  TOTAL_AUDIENCE: 'Total Audience',
  ENGAGED_AUDIENCE: 'Engaged Audience',
  'NON-ENGAGED_AUDIENCE': 'Non-engaged Audience',
  OR_CONTINUE_WITH: 'Or continue with',
  ENGAGEMENT: 'Engagement',
  TOPLINE_ENGAGEMENT: 'Top Line Engagement',
  AVERAGE_WATCH_TIME_PER_USER: 'Average Watch Time per user',
  AVERAGE_REVENUE_TIME_PER_USER: 'Average Revenue per user',
  ENGAGEMENT_IMPACT_DESCRIPTION:
    'Highlights of your audience engagement with your content.',
  ENGAGEMENT_BREAKDOWN_DESCRIPTION:
    'Engagement is any interaction with interactive features, such as panels, cards, and overlays.',
  ENGAGEMENT_BREAKDOWN: 'Engagement Breakdown',
  ENTER_PASSWORD: 'Enter your password',
  ENTER_DESIRED_PASSWORD: 'Enter your desired password',
  ENTER_EMAIL: 'Enter email',
  ENTER_PASSWORD_TO_ACCESS_THIS_SITE: 'Enter password to access this site',
  ENTER_YOUR_EMAIL: 'Enter your email',
  ENTER_YOUR_EMAIL_ADDRESS: 'Enter your email address',
  ENTER_YOUR_EMAIL_FOR_RESET: 'Enter your email to reset your password.',
  ENTER_YOUR_CLIENT_ID: 'Client ID',
  ENTRY_SUBMITTED: 'ENTRY SUBMITTED',
  ERROR_VIDEO_PLAYBACK: 'A playback error occurred.',
  EXPIRED: 'Expired',
  EARN_POINTS: 'Earn Points!',
  EARN_POINTS_LABEL: 'How to earn points',
  EARN_POINTS_DESCRIPTION:
    'Complete the actions from the list below and earn the corresponding number of points. Note that these may change from time to time so check back often!',
  FALLBACK: ' ',
  FACEBOOK_URL: 'Facebook Url',
  PROFILE_NAME: 'Name',
  FIRST_NAME: 'First Name',
  FORGOT_PASSWORD: 'I forgot my password',
  FORGOT_PASSWORD_TITLE: 'Forgot password?',
  FORGOT_PASSWORD_CHECK_EMAIL_TITLE: 'Check your email',
  FORGOT_PASSWORD_CHECK_EMAIL_DESCRIPTION:
    'We have sent password recovery instructions to ',
  ACCEPT_TERMS_BELOW: 'Accept Terms Below To Log In',
  GDPR_PROMPT:
    'This site uses cookies for analytics, personalized content, and ads. By clicking accept, you agree to our ',
  GDPR_LINK: 'Cookie Policy.',
  ADMIN_ACTION_OK: 'Ok',
  ADMIN_ERROR_INVALID_IMAGE: 'Error: Invalid image chosen.',
  ADMIN_ERROR_INVALID_EMAIL: 'Invalid support email',
  PAGE_VISIBILITY_TITLE: 'Visibility',
  PAGE_VISIBILITY_DESCRIPTION: 'Choose who can see this page',
  PUBLIC: 'Public',
  PAGE_VISIBILITY_PUBLIC_DESCRIPTION: 'Anyone can see this page',
  PRIVATE: 'Private',
  PAGE_VISIBILITY_PRIVATE_DESCRIPTION: 'Only admins can see this page',
  ADS: 'Ads',
  SITE_ADS_DESCRIPTION: 'Optionally enable video ads on non-YouTube/Twitch content on this entire Site. More granular settings (for example, on individual Channels) will take priority.',
  SITE_WIDE_AD_SETTINGS: 'Site-wide Ad Settings',
  PAGE_ADS_DESCRIPTION: 'Optionally enable video ads on non-YouTube/Twitch content on this channel.',
  PAGE_ADS_OPTION_INHERIT: 'Inherit configuration from Site Settings',
  PAGE_ADS_OPTION_DISABLE: 'Disable ads on this channel',
  PAGE_ADS_OPTION_OVERRIDE: 'Override Site Settings configuration with a specific URL on this channel',
  ENABLE_PRE_ROLL_ADS: 'Enable Pre-Roll Ads',
  VIDEO_AD_SERVING_TEMPLATE_URL: 'Video Ad Serving Template (VAST) URL',
  VIDEO_AD_SERVING_TEMPLATE_URL_DESCRIPTION: 'Find this in the admin interface of your platform of choice, e.g. Google Ad Manager',
  ADMIN_ERROR_INVALID_URL: 'Invalid support URL',
  ADMIN_ERROR_REQUIRED_ENTITLEMENT_GATE:
    'Required when entitlement gate is active',
  ADMIN_ERROR_CREATE_STREAM_CHANNEL: 'Error: Failed to create livestream',
  ADMIN_ERROR_SET_LIVESTREAM_URL: 'Error: Failed to set livestream URL',
  ADMIN_ERROR_SET_INGEST_URL: 'Error: Failed to get ingest URL & stream key',
  ADMIN_ERROR_STREAM_ONLINE_FAILED: 'Error: Failed to start livestream',
  ADMIN_ERROR_STREAM_OFFLINE_FAILED:
    'Error: Failed to stop recording livestream',
  ADMIN_ERROR_GENERIC: 'An error occurred. Please try again later.',
  ADMIN_ERROR_PLAYLIST_IMPORT:
    'Please add youtube playlists by using the playlist import utility',
  ADMIN_ERROR_ADD_STREAM_METADATA:
    'Error: Failed to add stream metadata. Please try again later.',
  ADMIN_ERROR_CSV_FILE_UPLOAD: 'Error validating file - Please ensure the headers are the same as the original file.',
  ANSWER_QUESTION_PROMPT:
    'Are you sure you want to mark this question answered?',
  EDIT_PAYMENT_METHOD: 'Edit Payment Method',
  DEVICES: 'devices',
  DEVICES_AT_ONCE: 'devices at once',
  GRID_PLAYLIST_VIDEOS: 'Videos',
  GRID_PLAYLIST_COUNT_VIDEOS: makeTranslation('{{count}} Videos')<{ count: string }>(),
  HASHTAG_LABEL: 'Hashtag',
  HASHTAG_TEXT: 'Hashtag Text',
  HASHTAG_TWEET: 'Hashtag Tweet Message',
  HAVE_AN_ACCESS_CODE: 'Have an access code?',
  LABEL_ACCESS_CODE: 'Access Code',
  BOUGHT_TICKET_DIFFERENT_ACCOUNT: 'Purchased ticket with a different account?',
  SWITCH_ACCOUNT: 'Switch Account',
  INCORRECT: 'Incorrect',
  IN_ORDER_TO_SIGN_UP_FOR_ENTITLEMENTS_YOU_WILL_NEED_TO_VERIFY_YOUR_EMAIL:
    'In order to sign up for entitlements, you will need to verify you email address.',
  INSTAGRAM_USERNAME: 'Instagram Username',
  ICON: 'Icon',
  ICON_URL: 'Icon URL',
  IMAGE: 'Image',
  IMAGE_URL: 'Image URL',
  INVALID_ACCESS_CODE: 'Invalid access code. Please try again.',
  LABEL_YOUR_RANK: 'Your Rank',
  LABEL_NOT_RANKED: 'Not Ranked',
  LABEL_SEASON_STATS: 'Season Stats',
  DESC_LEADERBOARD_EMPTY: 'Leaderboard is empty right now, check again later!',
  LANGUAGE: 'Language:',
  LINK: 'Link',
  LINKS: 'Links',
  LIVE: 'Live',
  LOADING_SHOPIFY_CHECKOUT: 'Loading Shopify checkout...',
  LOADING: 'Loading…',
  LOADING_ADMIN: 'Loading admin…',
  LOADING_QUESTIONS: 'Loading Questions...',
  LOCKED: 'Locked',
  LOG_IN: 'Log in',
  LOG_IN_TO_GAIN_ACCESS: 'Log In to Gain Access',
  LOG_IN_TO_GAIN_ACCESS_DESCRIPTION:
    'We’re glad you’re here, please click to continue.',
  LOG_IN_GATE_INFO: 'Only users who log in can view this page',
  LOG_IN_GATE_NONE: 'All users can view this page',
  LOG_IN_TO_DO_QUESTS: 'Log in to complete quests',
  LOG_IN_WITH: makeTranslation('Log in with {{provider}}')<{ provider: string; }>(),
  LOG_IN_OR_SIGN_UP: 'Login/Signup',
  AMAZON: 'Amazon',
  ADOBE: 'Adobe',
  USBC: 'USBC',
  FACEBOOK: 'Facebook',
  BATTLENET: 'Battle.net',
  GOOGLE: 'Google',
  EPIC: 'Epic',
  UMG: 'UMG',
  MICROSOFT: 'Microsoft',
  PLAYSTATION: 'Playstation',
  UBISOFT: 'Ubisoft',
  CORNELL: 'Cornell',
  LOGIN_FAILED: 'Login failed. Please try again',
  LOGIN_FAILED_WRONG_PASSWORD: 'Invalid password, please try again.',
  LABEL_LEADERBOARD_LOGIN_PROMPT: 'Log in to earn points',
  LABEL_ERROR_MODAL_MESSAGE: 'Oh Snap!',
  LABEL_SUCCESS_MODAL_MESSAGE: 'Success!',
  MANAGE_DEVICES: ' Manage Devices',
  SELECT_RANGE: 'Select Range',
  MANAGE_MY_SUBSCRIPTION: 'Manage My Subscription',
  MANAGE_MY_ENTITLEMENT: 'Manage My Entitlement',
  MOBILE_CHANNEL_SELECT_DRAWER_TITLE: 'Browse channels',
  MONTH: 'Month',
  MORE: 'More',
  MORE_INFO: 'More Info',
  MUST_BE_AN_ADMIN_TO_ACCESS_THIS_AREA:
    'You must be an admin to access this area',
  NAME: 'Name',
  CHANNEL_NAME_DESCRIPTION: 'The name of this channel',
  PAGE_NAME_DESCRIPTION: 'The name of this page',
  NAVIGATION: 'Navigation',
  NEW_LINK: 'New Link',
  NO_ACCOUNT: 'No Account?',
  NO_LINKS: 'No Links',
  NOT_AVAILABLE: 'NOT AVAILABLE',
  NOW_PLAYING: 'Now Playing',
  OR: 'OR',
  OK: 'OK',
  ON: 'on',
  OFF: 'off',
  CLICK: 'click',
  CLICK_HERE: 'Click Here',
  ENTER_CARD_NUMBER: 'Enter card number',
  CLICK_TO_UNMUTE: 'CLICK TO UNMUTE',
  CARD_NUMBER: 'CARD NUMBER',
  CVC: 'CVC',
  CONFIRM_SECURITY_CODE_CVC: 'Confirm security code (CVC)',
  EXP: 'Exp',
  CODE: 'Code',
  PASSWORD: 'Password',
  PASSWORD_NOT_MATCH_CONDITIONS: 'Password must be at least 8 characters',
  PASSWORD_MISMATCH: 'Passwords do not match',
  PASSWORD_DOES_NOT_EXIST: 'You must include a password.',
  PASSWORD_RESET_SUCCESS: 'Success! Password changed. Click OK to log in.',
  PAYMENT_FLOW_BUY_TICKET: 'Buy Ticket',
  PAYMENT_FLOW_BUY_SUBSCRIPTION: 'Buy Subscription',
  GUARANTEED_SAFE_AND_SECURE_CHECKOUT:
    'Guaranteed <b>safe & secure</b> checkout',
  PAYMENT_METHOD: 'Payment Method',
  PAYMENT_METHOD_LABEL_PRIMARY: 'Primary',
  PAYMENT_METHOD_LABEL_MAKE_PRIMARY: 'Make Primary',
  PAYMENT_CONFIRMATION: 'Payment Confirmation',
  PAYMENT_ERROR_MESSAGE: 'Your transaction was declined. \n Please check your card details and ensure there are sufficient funds \n before trying your transaction again.',
  NEW_PAYMENT_METHOD_ERROR: makeTranslation('Unable to submit new payment method: {{errorMessage}}')<{ errorMessage: string }>(),
  DYNAMIC_ERROR_MESSAGE: makeTranslation('{{errorMessage}}')<{ errorMessage: string }>(),
  SAVED_PAYMENT_METHOD: 'Saved payment method',
  PINNED_QUESTIONS: 'PINNED QUESTION',
  PLAY_BY_PLAY: 'PLAY BY PLAY',
  FREE_PREVIEW_TIME_LEFT: 'Free preview time left',
  ALREADY_PURCHASED_QUESTION: 'Already purchased?',
  ALREADY_ENTERED_YOUR_CODE: 'Already entered your code?',
  ENTER_ACCESS_CODE: 'ENTER ACCESS CODE',
  PLEASE_ENTER_YOUR_DATE_OF_BIRTH: 'Please enter your date of birth',
  PLEASE_VERIFY_YOUR_EMAIL_ADDRESS: 'Please verify your email address',
  POLL: 'Poll',
  POLL_RESULTS: 'Poll Results',
  POST: 'Post',
  POWERED_BY_MAESTRO: 'Powered by Maestro',
  POWERED_BY: 'Powered by',
  MAESTRO_INC: 'Maestro Interactive, Inc. ',
  PREDICTION: 'Prediction',
  PREVIEW: 'PREVIEW',
  PRIVACY_POLICY: 'Privacy Policy',
  PRIZE_CLAIMED: 'PRIZE CLAIMED',
  PROFILE_PICTURE: 'Profile Picture',
  QUEST_NEED_HELP: 'Need Help?',
  QUEST_OFFICIAL_RULES: 'Official Rules',
  QUEST_REWARD_PREFIX: 'Reward:',
  MATCH_SCOREBOARD_HELPER: 'No match data available.',
  PLEASE_VERIFY_YOUR_EMAIL_TO_ACCESS:
    `Please verify your email to access all of this site's features.`,
  RAFFLE_ACTIVE_LOGGED_IN: 'A giveaway has begun!',
  RAFFLE_ACTIVE_LOGGED_OUT:
    'A giveaway has started. Log in now to enter the next one!',
  RAFFLE_ENTRY_FAILURE: 'An error occurred.',
  RAFFLE_ENTRY_SUBMITTING: 'Entering giveaway…',
  RAFFLE_ENTRY_SUCCESS: `You're entered to win!`,
  RAFFLE_PICKING: 'A winner is being chosen…',
  RAFFLE_STARTING_LOGGED_IN:
    'A giveaway will begin soon. Watch here to participate!',
  RAFFLE_STARTING_LOGGED_OUT:
    'A giveaway will begin soon. Log in now to participate!',
  RAFFLE_WINNER_NOBODY: 'Nobody entered. Maybe next time!',
  RAFFLE_WINNER_OTHER_FRAGMENT: 'won the giveaway!',
  RAFFLE_WINNER_REWARD_DEFAULT: `It's a secret to everyone!`,
  RAFFLE_WINNER_REWARD_PREFIX: 'Reward:',
  RAFFLE_WINNER_YOU:
    'Congrats, you won the giveaway! You will be contacted soon!',
  RAFFLES_YOUVE_WON: `Giveaways you've won`,
  REGISTER_FAILED: 'Registration failed.  Please try again',
  REMOVE: 'Remove',
  REMOVE_CHAT_MESSAGE_PROMPT: 'Are you sure you want to remove this message?',
  REMOVE_ONE_DEVICE_TO_ADD: 'Remove one device to add this one',
  RESEND_VERIFICATION_EMAIL: 'Re-Send verification email',
  RESULTS: 'Results',
  RETWEET: 'Retweet',
  REWARD: 'Reward',
  ROW_TITLE: 'Row Title',
  REWARDS_YOUVE_RECEIVED: `Rewards you've received`,
  SCHEDULE: 'Schedule',
  SEARCH_FOR_YOUR_CHANNEL: 'Search for your channel',
  SELECT: 'Select',
  SEE_RESULTS: 'See Results',
  SEE_NEW_QUICK_PICKS: 'See new League Picks',
  SEE_NEW_QUESTIONS: 'See new questions',
  SELECT_A_PROFILE_PICTURE: 'Select a profile picture',
  SELECT_A_SERVICE_ADD_TO_CAL: 'Select a service to set a calendar reminder',
  SEND_VERIFICATION_EMAIL: 'Send verification email',
  SHARE: 'Share',
  SHARE_TO_FACEBOOK: 'Share to Facebook',
  CREDIT_CARD: 'Credit Card',
  CREDIT_CARD_NUMBER: 'Card Number',
  SAVE_PAYMENT_METHOD: 'Save payment method',
  MAKE_PRIMARY_PAYMENT_METHOD: 'Make primary payment method',
  EXPIRATION: 'Expiration',
  SHOP_NOW: 'Shop Now',
  SHOPIFY_CARD_NUMBER: 'Card number',
  SHOPIFY_CARD_EXP_DATE: 'Expiration MM/YY',
  SHOPIFY_CARD_CVC: 'CVC',
  SHOPIFY_FORM_FIRST_NAME: 'First Name',
  SHOPIFY_FORM_LAST_NAME: 'Last Name',
  SHOPIFY_FORM_ADDRESS: 'Address',
  SHOPIFY_FORM_CITY: 'City',
  SHOPIFY_FORM_STATE: 'State',
  SHOPIFY_FORM_COUNTRY: 'Country',
  SHOPIFY_FORM_ZIP_CODE: 'Zip Code',
  SHOPIFY_FORM_PHONE: 'Phone',
  SHOPIFY_FORM_EMAIL: 'Email',
  SHOPIFY_NO_IMAGE: 'No image',
  SHOW_MORE_MESSAGES: 'More messages below',
  SIGN_UP: 'SIGN UP',
  SIGNUP_WITH: makeTranslation('Sign up with {{provider}}')<{ provider: string }>(),
  SILENCE: 'Silence',
  SILENCE_CHAT_USER_PROMPT: 'Are you sure you want to silence this user?',
  CONFIRM_INVITE_TO_STAGE: 'Are you sure you want to invite this guest?',
  INVITE_TO_STAGE_EXPLANATION: 'They will be sent to the waiting room',
  STUDIO_INVITE_RECEIVED_TITLE: 'You’ve been invited to join the stream!',
  STUDIO_INVITE_RECEIVED_MESSAGE: 'Would you like to join?',
  SKIP: 'Skip',
  SOCIAL_LABEL: 'Social',
  SORRY_NO_ACCESS_AT_THIS_TIME:
    `We're sorry. You may not access this content at this time.`,
  SORRY_WRONG_PASSWORD: 'Invalid password, please try again.',
  SOUNDCLOUD_USERNAME: 'Soundcloud Username',
  STATE: 'State',
  SUBMIT: 'Submit',
  SUCCESS: 'Success',
  SUBMITED: 'SUBMITTED',
  SUBSCRIBE: 'Subscribe',
  SUCCESS_PLEASE_CHECK_YOUR_EMAIL_AGAIN:
    'Success. Please check your email once again.',
  TAP_TO_UNMUTE: 'TAP TO UNMUTE',
  TBD: 'TBD',
  TERMS_OF_USE: 'Terms of Use',
  TEXT: 'Text',
  TRIVIA: 'Trivia',
  TWEET: 'Tweet',
  TYPE_A_MESSAGE: 'Type a message',
  PURCHASE: 'PURCHASE',
  THANKS_PURCHASE: 'Thank you for your purchase!',
  OPTIONAL_URL: 'URL (optional)',
  UPLOAD_ZONE_BUTTON_REMOVE: 'Remove',
  UPLOAD_ZONE_ERROR_MAX_FILES: 'Exceeded maximum number of files',
  UPLOAD_ZONE_ERROR_SIZE_LIMIT: 'Exceeded maximum file size',
  UPLOAD_ZONE_ERROR_EXACT_SIZE: 'Image must match size above',
  UPLOAD_ZONE_ERROR_FILE_TYPE: 'Unsupported file type',
  UPLOAD_ZONE_ERROR_DEFAULT: 'Oops! Something went wrong. Please try again.',
  UPLOAD_ZONE_ERROR_MISSING_FILE: 'Oops! Looks like the file isn’t attached. Please try your upload again.',
  UPLOAD_ZONE_ERROR_UNSUPPORTED_FILE_TYPE: 'Oops! Looks like this isn’t a supported file type. Please ensure the file is an image (png, jpg, jpeg, gif or webp) and try your upload again.',
  UPLOAD_ZONE_ERROR_UNSUPPORTED_FILE_EXTENSION: 'Oops! Looks like this isn’t a supported file extension. Please ensure the file is an image (png, jpg, jpeg, gif or webp) and try your upload again.',
  UPLOAD_ZONE_ERROR_INVALID_FILE_NAME: 'Oops! Looks like your file contains unsupported characters. File names can include numbers, letters, and a - or _. Please rename your file and try again.',
  URL: 'URL',
  URL_MUST_BEGIN_WITH_HTTPS: 'The URL must begin with https://',
  URL_MUST_BEGIN_WITH_HTTP_HTTPS: 'The URL must begin with http:// or https://',
  VIEW_MORE: 'View More +',
  VIEW_POST: 'View Post',
  VIEW_STATS: 'View Stats',
  VIEW_YOUR_PRIZE: 'View Your Prize',
  VIEWED: 'Viewed',
  WELCOME_BACK: 'Welcome back',
  LOGIN_WELCOME_BACK: makeTranslation('Welcome back, {{userName}}')<{ userName: string; }>(),
  WATCH_ON_HUB: 'Watch On Hub',
  YEAR: 'Year',
  YOU_MAY_WATCH_ON_UP_TO: 'You may watch on up to',
  MATCH_PANEL_NAME: 'Match Stats',
  VICTORY_ROYALES: 'Victory Royales',
  VISIBLE: 'Visible',
  AVERAGE_PLACEMENT: 'Average Placement',
  AVERAGE_ELIMINATIONS: 'Average Eliminations',
  REGION: 'Region',
  WEEK_QUALIFIED: 'Week Qualified',
  PREVIEW_TIME_REMAINING: 'Preview Time Remaining',
  TIME_REMAINING: 'Time Remaining',
  TOTAL_ELIMINATIONS: 'Total Eliminations',
  TOTAL_POINTS: 'Total Points',
  LABEL_PRESENTED_BY: 'Presented by',
  LABEL_MONEY_EARNED: 'Money Earned',
  LABEL_SERIES_POINTS: 'Total Series Points',
  LABEL_AVERAGE_PLACEMENT: 'Average Leaderboard Placement by Week',
  LABEL_AVERAGE_POINTS: 'Average Points Per Week',
  LABEL_TOTAL_WEEKS: 'Total Weeks Participated',
  LABEL_AVERAGE_SCORE: 'Average Score Per Week',
  LABEL_DEFAULT_CHAT_BOT_NAME: 'Chat Bot',
  SPOTLIGHT_GRADIENT_CHECK: 'Include Image Gradient',
  WATCH_ON_THIS_DEVICE: 'Watch on this device',
  VIEWERSHIP_BREAKDOWN_TITLE: 'Viewership Breakdown',
  VIEWERSHIP_BREALDOWN_DESCRIPTION:
    'The following metrics are based on sessions instead of unique viewers.',
  ENGAGEMENT_IMPACT: 'Engagement Impact',
  TOP_FIVE_COUNTRIES: 'Top 5 Countries',
  TECHNOLOGY: 'Technology',
  DEMOGRAPHICS: 'Demographics',
  ADMIN_INSIGHTS_LABLE_AUDIENCE: 'Audience',
  ADMIN_INSIGHTS_LABLE_WATCH_BEHAVIOR: 'Watch Behavior',
  ADMIN_INSIGHTS_LABLE_REVENUE: 'Earnings',
  ADMIN_INSIGHTS_LABLE_UNIQUE_VIEWERS: 'Unique Viewers',
  ADMIN_INSIGHTS_LABLE_LOGGED_IN_USERS: 'Logged in Users',
  ADMIN_INSIGHTS_LABLE_ANONYMUS_DEVICES: 'Anonymous Devices',
  ADMIN_INSIGHTS_LABLE_PEAK_CONCURRENTS: 'Peak Concurrents',
  ADMIN_INSIGHTS_LABLE_AVERAGE_CONCURRENTS: 'Average Concurrents',
  ADMIN_INSIGHTS_LABLE_VIEWERS_HOURS: 'Viewers Hours',
  ADMIN_INSIGHTS_LABLE_LIVE_HOURS: 'Live Hours',
  ADMIN_INSIGHTS_LABLE_ON_DEMAND_HOURS: 'On Demand Hours',
  ADMIN_INSIGHTS_LABLE_AVERAGE_WATCH_TIME: 'Average Watch Time',
  ADMIN_INSIGHTS_LABLE_SESSIONS: 'Sessions',
  ADMIN_INSIGHTS_LABLE_BROADCAST_HOURS: 'Broadcast Hours',
  ADMIN_INSIGHTS_LABLE_GROSS_REVENUE: 'Gross Revenue',
  ADMIN_INSIGHTS_LABLE_NET_REVENUE: 'Net Revenue',
  ADMIN_INSIGHTS_LABLE_MONETIZED_UNIQUES: 'Monetized Uniques',
  ADMIN_INSIGHTS_LABLE_AVERAGE_REVENUE_ARPU:
    'Average Revenue Per Unique (ARPU)',
  ADMIN_INSIGHTS_LABLE_AVERAGE_REVENUE_ARPPU:
    'Average Revenue Per Paying Unique (ARPPU)',
  ADMIN_LABEL_BUNDLE_EDIT_ERROR: `You can't edit a bundle`,
  REMOVE_SHOPIFY_BLOCK_PRODUCT_TITLE: 'Are you sure you want to remove this product fom the block?',
  REMOVE_PAYMENT_METHOD_CONFIRM:
    'Are you sure you want to remove your payment method?',
  REMOVE_PAYMENT_METHOD_ERROR:
    'You must have a payment method on your profile while you have an active subscription',
  USER_PROFILE_MEMBER_SINCE: 'Member since',
  USER_PROFILE_TITLE: 'User Profile',
  USER_PROFILE_SETTINGS: 'Profile Settings',
  YOU_CAN_NOT_EDIT_THIS: 'You can not edit this',
  BUNDLE_FATAL_ERROR_WHEN_CREATING: 'Fatal error when creating bundle',
  BUNDLE_SUBTOTAL: 'Bundle Subtotal',
  SUBTOTAL: 'Subtotal',
  TAXES_AND_SHIPPING_CHECKOUT_INFO: 'Taxes & shipping calculated at checkout',
  SHIPPING_CHECKOUT_INFO: 'Shipping calculated at checkout',
  CONTINUE_TO_CHECKOUT: 'Continue to Checkout',
  ADD_TO_CART: 'Add to cart',
  ECOMMERCE_SIZE_CHART: 'Size Chart',
  ECOMMERCE_CHANGE_SELECTION: 'Change Selection',
  ECOMMERCE_NO_PRODUCT_VARIANTS_ERROR: 'Could not find product variants.',
  ECOMMERCE_NO_VARIANT_IN_PRODUCT_ERROR: 'Could not find variant from product variants.',
  ECOMMERCE_REMOVE_SOLD_OUT_ITEMS_ERROR: 'Please remove sold out item(s) from your cart to proceed to checkout.',
  ECOMMERCE_ZERO_QUANTITY_ITEM_ERROR: 'Please make sure the items in your cart have a quantity over 0.',
  BUNDLE_SKU_DESCRIPTION: 'This field will only be visible to you',
  BUNDLE_DESCRIPTION:
    'Tell your users what to expect when they buy this bundle.',
  BUNDLE_PRICE_DESCRIPTION:
    'Bundle prices are calculated as the sum of the prices for all of the Tickets and Products that are included in the bundle.',
  BUNDLE_PRICE_ERROR: 'You must add at least one Ticket and one Product',
  BUNDLE_PRICE_ERROR_MISSING_PRODUCT: 'You must add at least one Product',
  BUNDLE_PRICE_ERROR_MISSING_TICKET: 'You must add at least one Ticket',
  BUNDLE_PRICE_ERROR_CURRENCY_MISMATCH:
    'All products and tickets must be in the same currency',
  BUNDLE_IMAGE: 'Bundle Image',
  BUY_TICKET_BUNDLE: 'Buy Ticket Bundle',
  INCLUDED_TICKETS: 'Included Ticket(s)',
  INCLUDED_PRODUCTS: 'Included Product(s)',
  ADD_TICKET: 'Add Ticket',
  ADD_PRODUCT: 'Add Product',
  TOTAL: 'Total',
  TOTAL_DUE: 'Total Due',
  SERVICE: 'Service',
  WARNING_USING_MOBILE_AS_ADMIN:
    `'You're now an admin. But you need to be on a desktop/laptop device to operate as an admin' `,
  USER_NAME: 'Username',
  CONTINUE_WITH_OATH: 'Or continue with',
  LOADING_BILING_DATA: 'Loading billing data',
  SAVE_AND_TIP: 'Save & Tip',
  SAVING_CARD: 'Saving card',
  DETAIL_VIEW: 'Detail View',
  ADMIN_EARNINGS_ECOMMERCE: 'Ecommerce',
  ADMIN_EARNINGS_ECOMMERCE_COMING_SOON: 'Coming Soon',
  ADMIN_EARNINGS_EXCLUDING_TAX: 'excl. tax',
  ADMIN_EARNINGS_TOTAL: 'TOTAL',
  ADMIN_EARNINGS_TOTAL_PRODUCTS_SOLD: 'Total Products Sold',
  ADMIN_EARNINGS_UNITS_SOLD: 'Units Sold',
  ADMIN_EARNINGS_TOP_PRODUCT: 'TOP PRODUCT',
  ADMIN_EARNINGS_NO_TRANSACTIONS:
    'There are no ecommerce transactions to display',
  ADMIN_EARNINGS_NO_DATA_TRANSACTIONS: 'There are no transactions to display',
  ADMIN_TOTAL_EARNINGS: 'Total Earnings Breakdown',
  ADMIN_TOTAL_EARNINGS_TOTAL: 'Total',
  ADMIN_TOTAL_EARNINGS_TICKETS: 'Tickets',
  ADMIN_TOTAL_EARNINGS_SUBSCRIPTIONS: 'Subscriptions',
  ADMIN_TOTAL_EARNINGS_ECOMMERCE: 'Ecommerce',
  ADMIN_TOTAL_EARNINGS_TIPS: 'Tips',
  ADMIN_TOTAL_EARNINGS_COMPARED: 'Compared to Previous',
  ADMIN_TOTAL_EARNINGS_UNAVAILABLE: 'No Comparison Available',
  ADMIN_TOTAL_EARNINGS_NONE: 'No earnings to display',
  PICKER_SELECT_QUARTER: 'Select Quarter',
  PICKER_SELECT_WEEK: 'Select Week',
  PICKER_SELECT_MONTH: 'Select Month',
  PICKER_SELECT_DATE: 'Select Date',
  LABEL_BILLING_ADDRESS: 'Billing Address',
  LABEL_SHIPPING_ADDRESS: 'Shipping Address',
  LABEL_SHIPPING_ADDRESS_REQUIRED: 'Please complete your shipping address',
  LABEL_SHIPPING_METHOD: 'Shipping Method',
  FREE: 'Free',
  LABEL_COUPON_CODE: 'Coupon Code',
  VALID_EMAIL_ADDRESS: 'Please enter a valid email address',
  VALID_PASSWORD: 'Please enter a valid password',
  LABEL_DISCOUNT: 'Coupon Code',
  LABEL_SUMMARY: 'Summary',
  LABEL_SHIPPING_COST: 'Shipping',
  SALES_TAX: 'Sales Tax',
  YOUR_CART: 'Your Cart',
  MY_CART: 'My Cart',
  GO_BACK: 'Go Back',
  GO_BACK_TO_CART: 'Go Back to Cart',
  EMPTY_CART_TITLE: 'Your cart is empty.',
  EMPTY_CART_DESCRIPTION: `You haven't added any products yet.`,
  REMOVE_CART_ITEM_CONFIRM: 'Are you sure you want to remove this item?',
  QTY: 'QTY',
  LABEL_TAXES: 'Taxes',
  CHAT_MESSAGE_ITEM_ADMIN: '(Admin)',
  LABEL_TOTAL_AMOUNT: 'Total',
  CHAT_PANEL_CLEAR_CHAT: 'Clear Chat',
  CHAT_PANEL_EXPORT_CHAT: 'Export Chat',
  TIP_MESSAGE: 'Add a personalized message to the creator (optional)',
  TIP_INFO_MESSAGE: 'Your amount and message will be public',
  TIP_TEXT_THANK_YOU: 'Thank You!',
  TIP_MESSAGE_REMOVED: 'Message Removed',
  TIP_LOG: 'Tip Log',
  TIP_TOP_SUPPORTERS: 'Top Supporters',
  TIP_NO_TOP_SUPPORTERS_YET: 'No top supporters yet. Tip to be the first one!',
  TIP_SEE_ALL: 'See All',
  PROCESSING_PAYMENT: 'Your payment is being processed',
  PAYMENT_METHOD_FAILED: 'Payment method failed',
  SYSTEM_ERROR: 'System Error',
  CRASH_PAGE_TITLE: 'Oops! Something went wrong.',
  CRASH_PAGE_SUBTITLE: 'Please try refreshing or come back later',
  '404_ERROR': '404 Error',
  NOT_FOUND_PAGE_TITLE: 'Oops! Page not found.',
  NOT_FOUND_PAGE_SUBTITLE: '404 - Page Not Found',
  NOT_FOUND_PAGE_BODY:
    'The channel you are looking for may have been removed, had its name changed, or is temporarily unavailable',
  NOT_FOUND_PAGE_LOGO_TEXT: 'maestro',
  LABEL_HOW_MUCH_DO_YOU_WANT_TO_TIP: 'How much do you want to tip?',
  LABEL_OR_ENTER_CUSTOM_AMOUNT: 'Or enter custom',
  LABEL_SHOW_YOUR_SUPPORT: 'Show your support',
  LABEL_TIP: 'TIP',
  LABEL_NO_TIPS_YET: 'No tips yet!',
  LABEL_PROCESSING: 'Processing',
  LABEL_LOGIN_TO_TIP: 'Log in to Tip',
  LABEL_GIVE_TIP: 'Give Tip',
  HIDE_MESSAGE: 'Hide message',
  SHOW_MESSAGE: 'Show message',
  ACCESS_GATE_DATE_AND_TIME_MODAL_TITLE: 'Event Date And Time',
  ACCESS_GATE_DATE_AND_TIME_REGION_TITLE: 'date & time',
  ACCESS_GATE_TITLE_REGION_TITLE: 'Title',
  ACCESS_GATE_SUBTITLE_REGION_TITLE: 'Subtitle',
  ACCESS_GATE_ACCESS_CONTROL_REGION_TITLE: 'access control',
  ACCESS_GATE_SET_UP_ACCESS_BUTTON_TITLE: 'Set Up Access',
  ACCESS_GATE_ADMIN_LABEL_HOUR: 'Time',
  ACCESS_GATE_ADMIN_LABEL_CALENDAR: 'Date',
  ACCESS_GATE_ADMIN_CHANGE_BACKGROUND: 'Change Background',
  ACCESS_GATE_ADMIN_BACKGROUND_COLOR: 'Background Color',
  ACCESS_GATE_ADMIN_TITLE_COLOR: 'Title Color',
  ACCESS_GATE_ADMIN_FTUE_TITLE: 'Gate',
  ACCESS_GATE_ADMIN_FTUE_DESCRIPTION:
    'Control access to your channel by requiring the user to log in, enter a password, purchase a ticket or subscription, or provide an access code you gave them. With the editor active, simply click on a region to change it.',
  COPYRIGHT: makeTranslation('Copyright © {{year}} Maestro. All rights reserved.')<{ year: string }>(),
  ADMIN_PURCHASE_PLAN_CANCEL:
    `Don't worry, you can cancel or change your plan anytime`,
  ADMIN_PURCHASE_PLAN_TERMS:
    'By signing up, you are agreeing to the Maestro’s ',
  ADMIN_PURCHASE_PLAN_NEXT_BILL: 'Next bill',
  ADMIN_LABEL_TODAY: 'Today',
  ADMIN_LABEL_APPLY: 'Apply',
  ADMIN_PURCHASE_PLAN_DUE_TODAY: 'Due Today',
  ADMIN_PURCHASE_PLAN_TITLE: 'Purchase Plan',
  ADMIN_UPGRADE_PLAN_TITLE: 'Upgrade your Plan',
  ADMIN_UPGRADE_PLAN_BEST_VALUE: 'BEST VALUE',
  ADMIN_UPGRADE_PLAN_DOWNGRADE_BUTTON: 'SUBMIT TICKET TO DOWNGRADE YOUR PLAN',
  ADMIN_UPGRADE_PLAN_UPGRADE_TO_CUSTOM_PLAN_BUTTON: 'Have a Maestro team member contact me',
  ADMIN_UPGRADE_PLAN_SEE_PLAN_DETAILS: 'See full plan details',
  ADMIN_UPGRADE_PLAN_WANT_MORE: 'Want to keep more?',
  ADMIN_UPGRADE_PLAN_TERM_MONTHLY: 'Monthly',
  ADMIN_UPGRADE_PLAN_TERM_ANNUALLY: 'Annual (save 20%)',
  ADMIN_PURCHASE_PLAN_SUBMIT: 'Purchase',
  ADMIN_UPGRADE_PLAN_PLAN_CARD_CURRENT_PLAN: 'You are currently in this plan',
  ADMIN_UPGRADE_PLAN_PLAN_CARD_TICKETS_AND_SUBS: 'Tickets & Subscriptions',
  ADMIN_UPGRADE_PLAN_PLAN_CARD_SHOPIFY: 'Shopify',
  ADMIN_UPGRADE_PLAN_PLAN_CARD_TIPS: 'Tips',
  ADMIN_UPGRADE_PLAN_SUCCESS_TITLE: 'Success!',
  ADMIN_UPGRADE_PLAN_SUCCESS_DESCRIPTION: 'You have been upgraded to',
  ADMIN_UPGRADE_PLAN_CUSTOMER_SUPPORT_EMAIL_SUCCESS_DESCRIPTION: 'We will be in touch to discuss the details of your Custom Plan soon.',
  ADMIN_DOWNGRADE_PLAN_CUSTOMER_SUPPORT_EMAIL_SUCCESS_DESCRIPTION: 'We will be in touch soon to process your plan change.',
  ADMIN_UPGRADE_PLAN_SUCCESS_OKAY: 'OKAY',
  ADMIN_UPGRADE_PLAN_FREE: 'Free',
  ADMIN_UPGRADE_PLAN_MONTHLY_PRICE: makeTranslation('${{amount}}/month')<{ amount: number }>(),
  ADMIN_UPGRADE_PLAN_ANNUAL_PRICE: makeTranslation('${{amount}}/year')<{ amount: number }>(),
  ADMIN_UPGRADE_PLAN_KEEP_AMOUNT: makeTranslation('Keep {{amount}}%')<{ amount: number }>(),
  ADMIN_UPGRADE_PLAN_ONE_MAX_ADMIN: '1 Admin',
  ADMIN_UPGRADE_PLAN_UNLIMITED_MAX_ADMINS: 'Unlimited',
  ADMIN_UPGRADE_PLAN_MAX_ADMINS: makeTranslation('{{amount}} Admins')<{ amount: number }>(),
  ADMIN_UPGRADE_PLAN_LANDING_PAGE_WITHOUT_GATE_ERROR: 'Only Custom plans allow admins to add uploaded videos to non-gated pages. Please upgrade your plan.',
  ADMIN_UPGRADE_PLAN_LANDING_CHANNEL_WITHOUT_GATE_ERROR: 'Only Custom plans support streaming without a gate. Please upgrade your plan to host free streams.',
  ADMIN_UPGRADE_PLAN_LANDING_PASSWORD_GATE_ERROR: 'Only Custom plans allow admins to use a password gate on their channel. Please upgrade your plan.',
  ADMIN_UPGRADE_PLAN_LANDING_ACCESSCODE_GATE_ERROR: 'Only Custom plans allow admins to use a access code gate on their channel. Please upgrade your plan.',
  ADMIN_UPGRADE_PLAN_LANDING_LOGIN_GATE_ERROR: 'Only Custom plans allow admins to use a log in gate on their channel. Please upgrade your plan.',
  ADMIN_UPGRADE_PLAN_EXCEEDED_MAX_AMOUNT_OF_CHANNELS: 'You have exceeded the maximum amount of pages for your current plan. Please upgrade your plan to create more pages.',
  ADMIN_UPGRADE_PLAN_EXCEEDED_MAX_AMOUNT_OF_ADMINS: 'You have exceeded the maximum amount of admin accounts for your current plan. Please upgrade your plan.',
  ADMIN_UPGRADE_PLAN_WHEN_PAGE_HAS_UPLOADED_AND_YT_OR_TWITCH_CONTENT: 'With your plan, you can either have only YouTube/Twitch embeds with no gate or uploaded content behind a gate. Please remove one or the other, and try again',
  WELCOME_PANEL_TITLE: 'Your site is ready. Make it yours.',
  WELCOME_PANEL_ADMIN_CONTROLS:
    'Visit this site on your laptop or desktop to access your admin controls.',
  WELCOME_PANEL_MAKE_YOUR_SITE:
    'Pick a theme, choose panels, go live, and start making money on your own site!',
  WELCOME_PANEL_EMAIL_LINK: 'EMAIL ME A LINK',
  WELCOME_PANEL_EMAIL_SENT: 'EMAIL SENT',
  WELCOME_PANEL_EMAIL_ERROR: 'Failed to send email, please try again',
  WELCOME_PANEL_DIDNT_RECEIVED_EMAIL: `Didn't receive an email?`,
  WIZARD_ONBOARDING_TITLE: 'Welcome to Maestro!',
  WIZARD_ONBOARDING_DESCRIPTION:
    'You’re a couple minutes away from setting up your money-making machine. Help us learn a bit about you and your goals so we can get you set up in no time.',
  WIZARD_ONBOARDING_SUBTITLE: 'What type of creator are you?',
  WIZARD_ONBOARDING_SUBTITLE_DESCRIPTION:
    'Check the option that best represents you',
  WIZARD_HOME_TITLE: 'How do you want to make money?',
  WIZARD_HOME_SUBTITLE: 'Choose the monetization model that you’d like to use.',
  WIZARD_PICK_A_THEME_TITLE: 'Pick a Theme',
  WIZARD_PICK_A_THEME_SUBTITLE:
    'We’ll streamline your way to get up and running. After this step you’ll be able to further customize before going live.',
  WIZARD_SELECT_CHANNEL_TITLE: 'Set Up Live Shopping',
  WIZARD_SELECT_CHANNEL_SUBTITLE:
    'Which channel would you like to add live shopping to?',
  WIZARD_SET_UP_SUBSCRIPTION_ONLY_LIVESTREAM_TITLE:
    'Set Up Your Subscriber-Only Stream',
  WIZARD_SET_UP_SUBSCRIPTION_ONLY_LIVESTREAM_SUBTITLE:
    `Provide some basic info about the channel that you'd like to create.`,
  WIZARD_SET_UP_SUBSCRIPTION_VOD_TITLE:
    'Set Up Your Subscription Video On Demand Service',
  WIZARD_SET_UP_SUBSCRIPTION_VOD_SUBTITLE:
    `Provide some basic info about the subscription that you'd like to create.`,
  WIZARD_SET_UP_TICKETED_EVENT_TITLE: 'Set Up Your Ticketed Event',
  WIZARD_SET_UP_TICKETED_EVENT_SUBTITLE:
    `Provide some basic info about the event that you're going to host.`,
  WIZARD_SET_UP_TICKETED_EVENT_BUTTON_TITLE: 'Ticketed Event',
  WIZARD_SET_UP_TICKETED_EVENT_BUTTON_DESCRIPTION:
    'Host a one-time event that requires viewers to purchase a ticket.',
  WIZARD_SET_UP_TICKETED_EVENT_NAME_LABEL: 'What is the name of your event?',
  WIZARD_SET_UP_TICKETED_EVENT_NAME_PLACEHOLDER: 'The Main Event!',
  WIZARD_SET_UP_TICKETED_EVENT_NAME_ERROR_KEY: 'This field is required',
  REQUIRED_FIELD: 'Required field',
  WIZARD_SET_UP_TICKETED_EVENT_DATE_LABEL: 'When is your event?',
  WIZARD_SET_UP_TICKETED_EVENT_PRICE_LABEL: 'How much are tickets?',
  WIZARD_SET_UP_TICKETED_EVENT_PRICE_ERROR_KEY: 'Choose a price',
  WIZARD_SET_UP_SUBSCRIPTION_VOD_BUTTON_TITLE: 'Subscription Video On Demand',
  WIZARD_SET_UP_SUBSCRIPTION_VOD_BUTTON_DESCRIPTION:
    'Create a collection that requires viewers to be active subscribers.',
  WIZARD_SET_UP_SUBSCRIPTION_ONLY_LIVESTREAM_BUTTON_TITLE:
    'Subscriber-Only Live Streams',
  WIZARD_SET_UP_SUBSCRIPTION_ONLY_LIVESTREAM_BUTTON_DESCRIPTION:
    'Create private streams that require a subscription to access.',
  WIZARD_SET_UP_LIVE_SHOPPING_BUTTON_TITLE: 'Live Shopping',
  WIZARD_SET_UP_LIVE_SHOPPING_BUTTON_DESCRIPTION:
    'Host live streams where users shop while they watch. Shopify required.',
  WIZARD_EXPLORE_ON_MY_OWN_BUTTON_TITLE: 'Explore On My Own',
  WIZARD_EXPLORE_ON_MY_OWN_BUTTON_DESCRIPTION:
    'Start off with default settings and customize on my own.',
  WIZARD_I_DONT_WANT_TO_MAKE_MONEY_BUTTON_TITLE: 'I Don’t Want To Make Money',
  WIZARD_I_DONT_WANT_TO_MAKE_MONEY_BUTTON_DESCRIPTION:
    'Set up free experiences for my audience.',
  WIZARD_END_LIVESTREAM_TITLE: 'The Live Experience',
  WIZARD_END_COLLECTION_PAGE_TITLE: 'The Collection Page',
  WIZARD_END_EXPLORE_TITLE: 'Explore',
  WIZARD_END_TICKETED_EVENT_DESCRIPTION:
    'This is where your viewers will arrive after getting through your gate. Make it your own using the Editor or do a practice run by clicking Stream. When you’re ready, start promoting your event. You’ll be making money in no time!',
  WIZARD_END_SUBSCRIPTION_VOD_DESCRIPTION:
    'This is what your viewers will see after they pay for your subscription. Customize your page and add your video content using the Editor on the nav bar.',
  WIZARD_END_SUBSCRIPTION_VOD_DESCRIPTION_CONTINUED:
    'When you’re ready, start promoting your subscription service. You’ll be making money in no time!',
  WIZARD_END_LIVE_SHOPPING_DESCRIPTION:
    'We created a Shopify panel and added it to your channel. Connect a Shopify store to start selling your merchandise.',
  WIZARD_END_LIVE_SHOPPING_DESCRIPTION_CONTINUED:
    'When you’re ready, start promoting your channel. You’ll be making money in no time!',
  WIZARD_END_SUBSCRIBER_ONLY_LIVESTREAMS_DESCRIPTION:
    'This is what your viewers will see after they pay for your subscription. Customize your channel by clicking Editor on the nav bar. Click on Stream to run a test stream.',
  WIZARD_END_SUBSCRIBER_ONLY_LIVESTREAMS_DESCRIPTION_CONTINUED:
    'When you’re ready, start promoting your subscription service. You’ll be making money in no time!',
  WIZARD_END_EXPLORE_CHANNEL_DESCRIPTION:
    'This is your channel experience. This is what your viewers will see if they come to your channel.',
  WIZARD_END_EXPLORE_CHANNEL_DESCRIPTION_CONTINUED:
    'Customize your channel by clicking on Editor on the nav bar. After that, run a test stream by clicking on Stream from a laptop or desktop.',
  WIZARD_END_EXPLORE_PAGE_DESCRIPTION:
    'This is your content experience. This is what your viewers will see if they come to your page.',
  WIZARD_END_EXPLORE_PAGE_DESCRIPTION_CONTINUED:
    'Customize your page and add your video content using the Editor on the nav bar.',
  WIZARD_END_EXPLORE_DESCRIPTION_END:
    'When you’re ready, promote your content on your social channels to bring your audience to your new site!',
  WIZARD_END_STREAM_ACTION_ITEM: 'Do a practice stream',
  WIZARD_END_OPEN_EDITOR_ACTION_ITEM: 'Customize with the editor',
  WIZARD_END_EDIT_PAGE_ACTION_ITEM: 'Edit the page',
  WIZARD_END_PROMOTE_EVENT_ACTION_ITEM: 'Promote your event',
  WIZARD_END_PROMOTE_CHANNEL_ACTION_ITEM: 'Promote your channel',
  WIZARD_END_PROMOTE_SITE_ACTION_ITEM: 'Promote your site',
  WIZARD_END_PROMOTE_SERVICE_ACTION_ITEM: 'Promote your service',
  WIZARD_END_SHOPIFY_STORE_ACTION_ITEM: 'Connect your Shopify store',
  WIZARD_END_GO_TO_DESKTOP_ACTION_ITEM:
    'You’re all set! Continue your journey on a laptop or desktop.',
  WIZARD_END_GOT_IT: 'GOT IT',
  WIZARD_BEHOLD_THE_GATE_TITLE: 'Behold... The Gate!',
  WIZARD_BEHOLD_THE_GATE_DESCRIPTION:
    'We set up a new channel for you. This is what your viewers will see when they arrive. Edit your gate by clicking on Access from the nav bar. ',
  WIZARD_SUBSCRIBER_ONLY_INPUT_LABEL_CHANNEL_NAME:
    'What is the name of your channel?',
  WIZARD_SUBSCRIBER_ONLY_INPUT_PLACEHOLDER_CHANNEL_NAME:
    'Pick a name for your channel here.',
  WIZARD_SUBSCRIBER_ONLY_INPUT_LABEL_SUBSCRIPTION_NAME:
    'What is the name of the subscription?',
  WIZARD_SUBSCRIBER_ONLY_INPUT_PLACEHOLDER_SUBSCRIPTION_NAME:
    'Fan Club, VIP, etc.',
  WIZARD_SUBSCRIBER_ONLY_INPUT_LABEL_SUBSCRIPTION_PRICE:
    'How much is the subscription per month?',
  WIZARD_SUBSCRIPTION_INPUT_PLACEHOLDER: 'Enter custom',
  WIZARD_SUBSCRIPTION_INPUT_PLACEHOLDER_MOBILE: 'Custom',
  WIZARD_SUBSCRIPTION_VOD_INPUT_LABEL_PAGE_NAME:
    'What is the name of your page?',
  WIZARD_SUBSCRIPTION_VOD_INPUT_PLACEHOLDER_PAGE_NAME:
    'Pick a name for your page here.',
  WIZARD_SUBSCRIPTION_VOD_INPUT_LABEL_SUBSCRIPTION_NAME:
    'What is the name of the subscription?',
  WIZARD_SUBSCRIPTION_VOD_INPUT_PLACEHOLDER_SUBSCRIPTION_NAME:
    'Fan Club, VIP, etc.',
  WIZARD_SUBSCRIPTION_VOD_INPUT_LABEL_SUBSCRIPTION_PRICE:
    'How much is the subscription?',
  WIZARD_ERROR_REQUIRED_FIELD: 'This field is required.',
  WIZARD_ERROR_CHOOSE_PRICE: 'Choose a price.',
  ACCESS_BACKGROUND_CONTROL_MODAL_TITLE: 'Background Settings',
  ADMIN_LABEL_USE_GRADIENT_MASK: 'Use Gradient Mask',
  ADMIN_LABEL_USE_GRADIENT_MASK_TOOLTIP:
    'Adds a horizontal gradient to the image to make text areas more legible',
  VIDEO_LIBRARY_LIST_GO_TO_VIDEO_ACTION: 'Go To Video',
  VIDEO_LIBRARY_DROP_ZONE_GHOST_CARD: 'Thevideoiwanttoupload.mp4',
  VIDEO_LIBRARY_DROP_ZONE_DRAG_LABEL: 'Drag video here',
  VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_FILE_LABEL: 'Drop mp4 files here or',
  VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_DEVICE_LABEL: 'from your device',
  VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_CLICK_SELECT: 'click to select',
  VIDEO_LIBRARY_DROP_ZONE_DRAG_FORMAT_ERROR:
    'This format is not supported. Please upload only .mp4 files',
  VIDEO_LIBRARY_DROP_ZONE_DRAG_SIZE_ERROR: 'Oops! Your video exceeds the 50GB size limit. Please try again',
  ADMIN_WARNING_LEAVING_STUDIO: 'Are you sure you want to leave Studio?',
  ADMIN_PANEL_V2_CHAT_DESCRIPTION: `Maestro's own chat panel with moderation tools and optional anonymous chat support`,
  ADMIN_PANEL_V2_SETTINGS_BUTTON_TOOLTIP: 'Settings',
  ADMIN_ACTION_CONFIRM: 'Confirm',
  ADMIN_ANALYTICS_POLL_RESULT: 'Poll Result',
  ADMIN_ANALYTICS_QUESTION_AND_ANSWER: 'Question and Answer',
  ADMIN_ANALYTICS_BRACKETS: 'Brackets',
  ADMIN_ANALYTICS_PEOPLE_GROUPS: 'People Groups',
  ADMIN_ANALYTICS_INFO_PANEL: 'Info Panel',
  ADMIN_ANALYTICS_QUICK_PICK: 'Quick Pick',
  TWITCH: 'Twitch',
  ADMIN_ANALYTICS_NO_RESULTS: 'No Results',
  ADMIN_DATE_RANGE_LAST_24H: 'Last 24 Hours',
  ADMIN_DATE_RANGE_LAST_X_DAYS: makeTranslation('Last {{x}} Days')<{ x: string }>(),
  ADMIN_LABEL_CUSTOM: 'Custom',
  ADMIN_LABEL_X_HOURS: makeTranslation('{{x}} hours')<{ x: string }>(),
  ADMIN_LABEL_1_HOUR: `1 hour`,
  ADMIN_LABEL_X_MINUTES: makeTranslation('{{x}} minutes')<{ x: string }>(),
  ADMIN_LABEL_1_MINUTE: `1 minute`,
  ADMIN_ANALYTICS_VIEWS: 'Views',
  ADMIN_ANALYTICS_WATCH_TIME: 'Watch Time',
  ADMIN_ANALYTICS_DATE_ADDED: 'Date Added',
  ADMIN_CHANNEL_LIBRARY_ADDED: 'Added',
  ADMIN_CHANNEL_LIBRARY_LAST_MODIFIED: 'last modified',
  ADMIN_CHANNEL_LIBRARY_IS_OPEN: 'Is Open',
  ADMIN_INVITE_TAB_SITE_ADMIN: 'Site Admin',
  ADMIN_INVITE_TAB_MODERATOR: 'Moderator',
  ADMIN_DATE_RANGE_PICKER_SELECT_PERIOD: 'Select Period',
  ADMIN_QUARTER_PICKER_TIP: 'Select the quarter for filtering and displaying your earnings overview',
  ADMIN_WEEK_PICKER_TIP: 'Select the Week for filtering and displaying your earnings overview',
  ADMIN_SHOPIFY_PANEL_EDITOR_SELECT_COLLECTION: 'Select a collection...',
  ADMIN_SHOPIFY_PANEL_EDITOR_SELECT_PRODUCT: 'Select a product...',
  ADMIN_MULTI_SELECTOR_NO_OPTIONS_SELECTED: 'No Options Selected',
  ADMIN_MULTI_SELECTOR_NO_OPTIONS_AVAILABLE: 'No options',
  ADMIN_CHANNEL_SLUG_EDITION_BLOCKED: `The URL can't be changed while the channel is live.`,
  ADMIN_POLL_EDITOR_ADD_OPTION: 'Add option',
  ADMIN_POLL_EDITOR_REMOVE_OPTION: 'Remove option',
  ADMIN_POLL_EDITOR_CORRECT_OPTION: 'Correct option',
  LABEL_NEW_PREDICTION: 'New Prediction',
  LABEL_NEW_TRIVIA: 'New Trivia',
  ADMIN_LABEL_BROADCASTED: 'Broadcasted',
  ADMIN_QUEST_EDITOR_SELECT_PANEL: 'Select a panel',
  ADMIN_QUEST_EDITOR_ANY_CHANNEL: 'Any Channel',
  ADMIN_QUEST_EDITOR_SELECT_CHANNEL: 'Select a Channel',
  ADMIN_QUEST_EDITOR_QUEST_DESCRIPTION_EXPLANATION: 'Tell your users what quests are and why they should participate',
  ADMIN_QUEST_EDITOR_CLAIMED_MESSAGE: 'Claimed Message',
  ADMIN_QUEST_EDITOR_REWARD_VALUE: 'Reward value',
  ADMIN_QUEST_EDITOR_LIFESPAN_EXPLANATION:
    'Quest defaults to Always Active. Use the selector below to specify when the quest should be available and when it should close.',
  ADMIN_QUEST_EDITOR_REWARD_EXPLANATION: 'What will the winner(s) receive as a reward',
  ADMIN_QUEST_EDITOR_REWARD_TYPE: 'Reward type',
  ADMIN_QUEST_EDITOR_REWARD_IMAGE: 'Reward image',
  ACTION_SAVE_AND_ADD: 'Save & add',
  ADMIN_QUICKPICK_EDITOR_ADD_OPTION: 'Add option',
  ADMIN_QUICKPICK_EDITOR_REMOVE_OPTION: 'Remove option',
  CLOSED: 'Closed',
  OPEN: 'Open',
  ADMIN_RENDERER_EDITOR_ADD_REFERENCE: 'Add reference',
  ADMIN_RENDERER_EDITOR_ADD_MULTIPLE_CHOICE: 'Add multiple choice',
  ADMIN_RENDERER_EDITOR_QUEST_NOT_FOUND: 'Quest not found',
  ADMIN_RENDERER_EDITOR_NO_QUEST_SELECTED: 'No quest selected',
  ADMIN_RENDERER_EDITOR_UNTITLED_QUEST: 'Untitled quest',
  ADMIN_RENDERER_EDITOR_UNTITLED_PANEL: 'Untitled panel',
  ADMIN_RENDERER_EDITOR_ADD_QUICKPICK: 'Add Quick Pick',
  ADMIN_SELECT_AN_ANSWER: 'Select an answer',
  ADMIN_SEARCH_USERS_NO_USERS_FOUND: 'No users found',
  ADMIN_SEARCH_USERS_TIP:
    'Start typing in a user name or email address to find the person you want to add a role for.',
  ADMIN_SEARCH_USERS_FIND_A_USER: 'find a user',
  ADMIN_LABEL_FREQUENCY_YEARLY: 'Yearly',
  ADMIN_THIRDPARTY_TAB_ECOMMERCE_PROVIDERS: 'Ecommerce Providers',
  ADMIN_THIRDPARTY_TAB_ANALYTICS_PROVIDERS: 'Analytics Providers',
  ADMIN_THIRDPARTY_STREAM_PROVIDERS_CONNECTION_FAILED: 'The connection failed. Please check your credentials and try again.',
  ADMIN_THIRDPARTY_STREAM_PROVIDERS_CONNECTION_SUCCESSFUL:
    makeTranslation('The connection test to {{provider}} was successful. Click Make Active to switch to this Streaming Provider.')<{ provider: string }>(),
  ADMIN_THIRDPARTY_STREAM_PROVIDERS_ACCOUNT_ADDED:
    makeTranslation('{{provider}} is now the active Streaming Provider')<{ provider: string }>(),
  ADMIN_THIRDPARY_STREAM_PROVIDERS_CAN_ADD_ACCOUNT: 'Something went wrong when activating this account:',
  ADMIN_THIRDPARY_STREAM_PROVIDERS_MAKE_ACTIVE: 'Make active',
  ADMIN_THIRDPARY_STREAM_PROVIDERS_TEST: 'Test',
  ADMIN_THIRDPARY_STREAM_PROVIDERS_TESTING: 'Testing...',
  ADMIN_THIRDPARY_STREAM_PROVIDERS_MAESTRO_DEFAULT: 'Maestro (default)',
  ADMIN_THIRDPARY_STREAM_PROVIDERS_TITLE: 'Streaming Providers',
  ADMIN_LABEL_CHAT_MODERATOR: 'Chat Moderator',
  ADMIN_LABEL_PRODUCER: 'Producer',
  ADMIN_GATE_DROPDOWN_GATE_TYPE: 'Gate Type',
  ADMIN_GATE_DROPDOWN_CLICK_TO_SELECT: 'Click to select your preferred gate',
  ADMIN_INVITE_CARD_INVITATION_DELETED: 'Invitation Deleted!',
  EXPIRED_ONE_DAY_AGO: 'Expired 1 day ago',
  EXPIRED_X_DAYS_AGO: makeTranslation('Expired {{days}} days ago')<{ days: string }>(),
  EXPIRED_ONE_HOUR_AGO: 'Expired 1 hour ago',
  EXPIRED_X_HOURS_AGO: makeTranslation('Expired {{hours}} hours ago')<{ hours: string }>(),
  EXPIRED_X_MINUTES_AGO: makeTranslation('Expired {{minutes}} minutes ago')<{ minutes: string }>(),
  EXPIRED_WITHIN_LAST_MINUTE: 'Expired within the last minute',
  ADMIN_THIRD_PARTY_CONNECT: 'CONNECT',
  ADMIN_THIRD_PARTY_DISCONNECT: 'DISCONNECT',
  PROGRESS_BAR_X_PERCENT: makeTranslation('{{x}} %')<{ x: string }>(),
  ADMIN_UPLOAD_ZONE_UPLOAD_AN_IMAGE_OF_EXACT_DIMENSIONS: makeTranslation('Upload an image of {{width}} x {{height}}')<{ height: number; width: number; }>(),
  ADMIN_UPLOAD_ZONE_UPLOAD_AN_IMAGE_OF_MAX_DIMENSIONS: makeTranslation('Upload an image of Max {{width}} x {{height}}')<{ height: number; width: number; }>(),
  ADMIN_UPLOAD_ZONE_UPLOAD_IMAGE_SIZE: makeTranslation('Size: {{width}} x {{height}}')<{ height: number; width: number; }>(),
  ADMIN_UPLOAD_ZONE_UPLOAD_IMAGE_MAX_SIZE: makeTranslation('Max size: {{width}} x {{height}}')<{ height: number; width: number; }>(),
  ADMIN_UPLOAD_ZONE_WIDTH_MUST_BE_X: makeTranslation('Width must be exactly {{width}}px')<{ width: string }>(),
  ADMIN_UPLOAD_ZONE_HEIGHT_MUST_BE_X: makeTranslation('Height must be exactly {{height}}px')<{ height: string }>(),
  ADMIN_UPLOAD_ZONE_WIDTH_MUST_BE_AT_LEAST_X: makeTranslation('Width must be at least {{width}}px')<{ width: string }>(),
  ADMIN_UPLOAD_ZONE_HEIGHT_MUST_BE_AT_LEAST_X: makeTranslation('Height must be at least {{height}}px')<{ height: string }>(),
  ADMIN_USER_INVITE_EMAIL_VALIDATION_ERROR_EMPTY: 'Please provide an email to send invite',
  ADMIN_USER_INVITE_EMAIL_VALIDATION_ERROR: 'Email format is not supported, please check and try again',
  ADMIN_USER_INVITE_MUST_SELECT_CHANNEL: 'Must select at least 1 channel',
  ADMIN_USER_INVITE_EMAIL_INVITATION_SENT_ERROR: 'This email has already been used in an invitation. Please check the Invitations list',
  ADMIN_USER_INVITE_EMAIL_REGISTERED_ERROR: 'This email is already a registered admin',
  ADMIN_USER_INVITE_EMAIL_REGISTERED_SUCCESS: 'An account with this email address already exists, and has now been made an admin.',
  ADMIN_USER_INVITE_SENT: 'Invite sent!',
  ADMIN_LABEL_VIDEO_ARCHIVED_ERROR: 'Video archived',
  ADMIN_PLAYLIST_EDITOR_INVALID_YOUTUBE_URL: 'Error: Please use a valid playlist url',
  ADMIN_LABEL_SELECT_CREATE_TAG: 'Select or Create a Tag',
  ADMIN_MESSAGE_VIDEO_ADDED_TO_PLAYLIST: 'Video added to playlist!',
  ADMIN_VIDEO_LIBRARY_OR_USE_VIDEO_URL: 'Or use a Video URL',
  ADMIN_VIDEO_LIBRARY_INCORRECT_URL_FORMAT: 'Incorrect URL format',
  ADMIN_VIDEO_LIBRARY_URL_PLACEHOLDER: 'Enter a link from a popular service like YouTube, Twitch, Vimeo, or an m3u8 URL',
  ADMIN_VIDEO_EDIT_FORM_EMBED_CODE: 'Embed code',
  ADMIN_VIDEO_LIBRARY_USE_EMBEDDED_VIDEO: 'Use an Embedded Video',
  ADMIN_VIDEO_LIBRARY_USE_EMBEDDED_VIDEO_DESC: 'Embed your player of choice using an embed code.',
  ADMIN_VIDEO_LIBRARY_ADD_EMBEDDED_VIDEO: 'Add Embedded Video',
  ADMIN_VIDEO_LIBRARY_EMBEDDED_VIDEO: 'Embedded video',
  ADMIN_ACTION_BULK_EDIT: 'Bulk Edit',
  ADMIN_ACTION_BULK_DELETE: 'Bulk Delete',
  ADMIN_VIDEO_LIBRARY_NO_VIDEOS: 'You dont have any videos to display. Please upload or add videos.',
  ADMIN_LABEL_X_DIMENSIONS: makeTranslation('Dimensions: {{x}}')<{ x: string }>(),
  ADMIN_ANALYTICS_REVENUE: 'Revenue',
  ADMIN_LABEL_ROLE: 'Role',
  ADMIN_PROFILE_LAST_LOGIN: 'Last Login Date',
  ADMIN_PROFILE_USER_ID: 'User ID',
  ADMIN_ERROR_NETWORK_ERROR: 'Network Error',
  ADMIN_ERROR_SETTING_UP_STREAM_ERROR: 'There was a problem setting up your stream.',
  ADMIN_ERROR_STREAM_CREDENTIALS_UNAVAILABLE: 'Stream credentials unavailable. Please try again or check your 3rd party apps settings',
  ADMIN_ERROR_INVALID_CARD_NUMBER: 'Invalid card number',
  ADMIN_LABEL_AMAZON_BENEFIT_TYPE_SINGLE: 'Single Benefit',
  ADMIN_LABEL_AMAZON_BENEFIT_TYPE_MULTIPLE: 'Multiple Benefits',
  ADMIN_LABEL_COMMUNITY_FILTERS_TITLE: 'Filters',
  ADMIN_LABEL_COMMUNITY_TIME_FILTER_TITLE: 'Time',
  ADMIN_LABEL_COMMUNITY_INPUT_ENTER_VALUE: 'Enter Value',
  ADMIN_LABEL_PLEASE_TRY_AGAIN: 'Please try again',
  ADMIN_LABEL_RETRY: 'Retry',
  ADMIN_LABEL_DATE_GRANTED: 'Date Granted',
  ADMIN_LABEL_RECEIPT: 'Receipt',
  ADMIN_TAGS_PLACEHOLDER: 'Enter or Create a Tag',
  ADMIN_LABEL_METHOD: 'Method',
  ADMIN_LABEL_TAGS: 'Tags',
  ADMIN_LABEL_ACTIONS: 'Actions',
  ADMIN_LABEL_ENTITLEMENT: 'Entitlement',
  ADMIN_LABEL_SELECT_PROPERTY: 'Select Property...',
  ADMIN_LABEL_FILTER_BY: 'Filter by',
  ADMIN_LABEL_START: 'Start',
  ADMIN_LABEL_END: 'End',
  ADMIN_LABEL_REPEAT: 'Repeat',
  ADMIN_LABEL_REPEATING: 'Repeating',
  ADMIN_LABEL_COUNTDOWN: 'Countdown',
  ADMIN_LABEL_BETA: 'Beta',
  ADMIN_ACTION_VIEW_PROFILE: 'View Profile',
  ADMIN_LABEL_AMOUNT_VIDEOS: makeTranslation('{{amount}} videos')<{ amount: string }>(),
  ADMIN_LABEL_DISCOUNT: 'Discount',
  ADMIN_LABEL_ACTION: 'Action',
  ADMIN_LABEL_RECEIPT_NUMBER: 'Receipt number',
  ADMIN_LABEL_JOINED: 'Joined',
  ADMIN_LABEL_SERVICE: 'Service',
  ADMIN_LABEL_PREMIUM: 'Premium',
  ADMIN_LABEL_VIEWER: 'Viewer',
  ADMIN_LABEL_VIEWERS: 'Viewers',
  ADMIN_LABEL_HERE: 'Here',
  ADMIN_LABEL_ADDED: 'Added',
  ADMIN_LABEL_AVATAR_IMAGE_FOR_X: makeTranslation('Avatar image for {{x}}')<{ x: string }>(),
  ADMIN_LABEL_EMPTY: 'Empty',
  ADMIN_LABEL_KIND: 'Kind',
  ADMIN_LABEL_IMAGE_AND_TEXT: 'Image & Text',
  ADMIN_LABEL_NO_RESULTS: 'No results',
  LABEL_IMAGE_AND_TEXT: 'Image & Text',
  ADMIN_LABEL_COVER: 'Cover',
  ADMIN_LABEL_CONTAIN: 'Contain',
  ADMIN_LABEL: 'Label',
  ADMIN_LABEL_IMAGE_AND_TEXT_SETTINGS: 'Image & Text Settings',
  ADMIN_LABEL_VIDEO_SPOTLIGHT_SETTINGS: 'Video Spotlight Settings',
  ADMIN_LABEL_IMAGE_SETTINGS: 'Image Settings',
  ADMIN_LABEL_IMAGE_GALLERY_SETTINGS: 'Image Gallery Settings',
  ADMIN_LABEL_SHOPIFY_BLOCK_SETTINGS: 'Shopify Block Settings',
  ADMIN_LABEL_FORTNITE_LEADERBOARD_SETTINGS: 'Fortnite Leaderboard Settings',
  ADMIN_LABEL_TEXT_ALIGNMENT: 'Text Alignment',
  START_CREATING_YOUR_INTERACTIVE_EXPERIENCE: 'Start creating your Interactive experience',
  ADMIN_PANEL_V2_CARDS_SECTION_TITLE: 'Cards',
  ADMIN_PANEL_V2_CARDS_SECTION_INFORMATION: 'Widgets that appear in the sidebar when users arrive on the channel. 1 active at a time.',
  ADMIN_PANEL_V2_PANELS_SECTION_ACTIVE_PANELS: 'Active Panels',
  ADMIN_PANEL_V2_PANELS_SECTION_ACTIVE_PANELS_EMPTY: 'No Active Panels',
  ADMIN_PANEL_V2_PANELS_SECTION_AVAILABLE_PANELS_EMPTY: 'No Available Panels',
  ADMIN_PANEL_V2_PANELS_SECTION_AVAILABLE_PANELS: 'Available Panels',
  ADMIN_OVERLAYS_ROTATE_YOUR_PHONE: 'Please rotate your phone',
  ADMIN_MODAL_VIDEO_METADATA_SELECTED: 'Videos Selected',
  ADMIN_MODAL_CHANGE_ROLE: 'Change Role',
  ADMIN_MODAL_SHOPIFY_PLEASE_CONNECT_PRIVILEGED_ACCOUNT: 'To connect a Shopify store, please log in with an account with site administrator privileges',
  ADMIN_MODAL_STREAM_CONNECTION_ISSUES: 'Connection issue detected',
  ADMIN_MODAL_STREAM_DISCONNECTED_PLEASE_RECONNECT: 'Your stream has disconnected from the source. Please check and reconnect.',
  ADMIN_MODAL_STREAM_RECONNECTED: 'Stream has been reconnected',
  ADMIN_MODAL_STREAM_UNTIL_STREAM_WILL_END: 'Until stream will end',
  ADMIN_MODAL_CHECKING_ITEMS_CURRENTLY_IN_USE: 'Checking if items are currently in use...',
  ADMIN_MODAL_CONFIRM_DELETE_X_VIDEOS: makeTranslation('Are you sure you want to delete these {{x}} videos from your library?')<{ x: string }>(),
  ADMIN_MODAL_YOU_WILL_NOT_BE_ABLE_TO_UNDO_ACTION: 'You will not be able to undo this action',
  ADMIN_VIDEO_GATE_SUBSCRIBE_NOW: 'Subscribe Now',
  ADMIN_ITEMS_IN_DELETE_CONFIRMATION_MODAL: 'One or more of the selected items appear in',
  ADMIN_IMAGE_GALLERY_UPLOAD_SIZE: makeTranslation('Upload size: {{uploadSize}}')<{ uploadSize: string; }>(),
  ADMIN_IMAGE_GALLERY_DESKTOP_ASPECT_RATIO: makeTranslation('Desktop {{aspectRatio}}')<{ aspectRatio: string; }>(),
  ADMIN_IMAGE_GALLERY_MOBILE_ASPECT_RATIO: makeTranslation('Mobile {{aspectRatio}}')<{ aspectRatio: string; }>(),
  ADMIN_LABEL_SAME_AS_DESKTOP: 'Same as Desktop',
  ADMIN_LABEL_IMAGE_ASPECT_RATIO: 'Image Aspect Ratio',
  ADMIN_BILLING_ITEM_ENTITLEMENT_PRICE: 'Entitlement Price',
  ADMIN_BILLING_ITEM_SUBSCRIPTION: 'Subscription',
  ADMIN_STREAM_MODE_THIRD_PARTY: '3rd Party Software',
  ADMIN_IMAGE_OFFLINE_IMAGE: 'Offline Image',
  ADMIN_VIDEO_OFFLINE_VIDEO: 'Offline Video',
  ADMIN_TOOLBAR_PUBLISH_TOOLTIP: 'Publish this page to be able to stream',
  ADMIN_STREAM_MODE_SETTING_UP_STREAM: 'Setting up stream',
  ADMIN_FOOTER_SPONSORS_TITLE: 'Sponsors',
  ADMIN_BILLING_TOTAL_AMOUNT: 'Total Amount',
  ADMIN_BILLING_WRONG_EMAIL: 'Wrong email?',
  ADMIN_BILLING_RECEIPT_HAS_BEEN_SENT_TO_X_EMAIL: makeTranslation('A receipt has been sent to {{x}}')<{ x: string }>(),
  ADMIN_BILLING_YOUR_EMAIL: 'your email.',
  ADMIN_BILLING_INVALID_EXPIRATION_DATE: 'Invalid expiration date',
  ADMIN_BILLING_INVALID_POSTAL_CODE: 'Invalid postal code',
  ADMIN_PANEL_CHAT_PREVIEW_MESSAGE: `I’ve been waiting for this all week!`,
  ADMIN_PROFILE_VIEWER_HAS_NOT_PURCHASED_SUBSCRIPTION: 'This viewer has not purchased a subscription',
  ADMIN_PROFILE_VIEWER_HAS_NOT_PURCHASED_TICKET: 'This viewer has not purchased a ticket',
  ADMIN_COMMUNITY_ALL_TIME: 'All Time',
  ADMIN_COMMUNITY_CHOOSE_TIME_RANGE: 'Choose Time Range',
  ADMIN_COMMUNITY_EXPORT_RESULTS: 'Export Results',
  ADMIN_COMMUNITY_RESULT_LOGIN_RANGE: makeTranslation('Login Range: {{formattedNumber}} user')<{ formattedNumber: string }>(),
  ADMIN_COMMUNITY_RESULT_LOGIN_RANGE_PLURAL: makeTranslation('Login Range: {{formattedNumber}} users')<{ formattedNumber: string }>(),
  ADMIN_COMMUNITY_GENERATING_EXPORT: 'Generating Export',
  ADMIN_COMMUNITY_DOWNLOAD_RESULTS: 'Download Results',
  ADMIN_COMMUNITY_COULD_NOT_RETRIEVE_DATA: 'Could not retrieve data for selected filters',
  ADMIN_COMMUNITY_LONG_WAIT_TEXT: "This is taking longer than expected due to the amount of data. We'll have your results soon.",
  ADMIN_COMMUNITY_SEE_ALL_RESULTS: 'To see all results, please export this list',
  ADMIN_COMMUNITY_NO_USERS_FOUND: 'No users were found',
  ADMIN_COMMUNITY_TYPE_TICKET: 'Ticket',
  ADMIN_COMMUNITY_TYPE_BUNDLE: 'Bundle',
  ADMIN_EARNINGS_TRAILING_X_MONTHS: makeTranslation('Trailing {{x}} months')<{ x: string }>(),
  ADMIN_SHOPIFY_SHOPPING_CART_EMPTY: 'Your shopping cart is empty!',
  ADMIN_SHOPIFY_HAVENT_MADE_CHOICE: 'Looks like you have not made your choice',
  ADMIN_SHOPIFY_START_SHOPPING: 'START SHOPPING',
  ADMIN_SHOPIFY_SAME_AS_BILLING_ADDRESS: 'Same as Billing Address',
  BILLING_ADDRESS_IS_SAME_AS_SHIPPING: 'Billing address is same as shipping',
  ADMIN_SHOPIFY_THANK_YOU_ORDER: 'Thank you for your order',
  ADMIN_SHOPIFY_SENT_EMAIL_TRANSACTION: 'We sent you an email with all the details of this transaction',
  ADMIN_SHOPIFY_CARD_NUMBER_INCOMPLETE: 'Your card number is incomplete',
  ADMIN_SHOPIFY_ENTER_EXPIRATION_DATE: 'Please enter an expiration date',
  ADMIN_SHOPIFY_ENTER_CVC_CODE: 'Please enter a CVC code',
  ADMIN_SHOPIFY_CARD_NUMBER_LABEL: 'Credit/Debit Card Number',
  ADMIN_ACCESS_PANEL_ALREADY_HAVE_PASS: 'I already have a pass',
  ADMIN_OVERLAY_COUNTDOWN_AT_X: makeTranslation('Tune in to watch live at {{x}}')<{ x: string }>(),
  ADMIN_FAQ_BRAND_TITLE_FAQ: 'Your brand title FAQ',
  ADMIN_FAQ_FREQUENTLY_ASKED_QUESTIONS: 'Frequently Asked Questions (FAQs)',
  ADMIN_SEO_CHANNEL_TITLE: 'watch',
  ADMIN_SEO_CHANNEL_DESC: 'Tune into the live stream!',
  ADMIN_SEO_CHANNEL_KEYWORDS_PLACEHOLDER: 'live stream',
  ADMIN_FEATURE_GATE_NOTICE: `If you'd like to use this feature on your site, send us a request by clicking the button below.`,
  ADMIN_FEATURE_GATE_NOTICE_SUBTITLE: 'You no longer have access to this feature.',
  ADMIN_FEATURE_APPLE_PAY: 'Apple Pay',
  ADMIN_FEATURE_AMAZON_BENEFIT: 'Amazon Benefit Gate',
  ADMIN_FEATURE_GENERATE_CAPTIONS: 'Generate Captions',
  ADMIN_FEATURE_GOOGLE_PAY: 'Google Pay',
  ADMIN_FEATURE_HIDE_COPYRIGHT: 'Hide Copyright',
  ADMIN_FEATURE_HIDE_GDPR: 'Hide GDPR',
  ADMIN_FEATURE_HIDE_MAESTRO_LOGO: 'Hide Maestro Logo',
  ADMIN_FEATURE_INSIGHTS_ANALYTICS: 'Insights Analytics',
  ADMIN_FEATURE_MAESTRO_SUPPORT: 'Maestro Support',
  ADMIN_FEATURE_SECURE_STREAMS: 'Secure Streams',
  ADMIN_FEATURE_VOD_UPLOADS: 'Vod Uploads',
  ADMIN_FEATURE_ACHIEVEMENTS_TITLE: 'Build your community',
  ADMIN_FEATURE_ACHIEVEMENTS_DESC: 'Let viewers earn points for engaging with your content',
  ADMIN_FEATURE_ACHIEVEMENTS_REASON_1: 'Rewarding viewer loyalty encourages them to return to your site',
  ADMIN_FEATURE_ACHIEVEMENTS_REASON_2: 'Viewers can compete with each other for placement on the leaderboard',
  ADMIN_FEATURE_ACHIEVEMENTS_TITLE_2: 'Increase engagement',
  ADMIN_FEATURE_ACHIEVEMENTS_REASON_3: 'Reward viewers for answering polls, completing quests, and more',
  ADMIN_FEATURE_BILLING_REASON: 'View your invoices and payment methods',
  ADMIN_FEATURE_BILLING_DESC: 'Configure your billing details and automate your payouts',
  ADMIN_FEATURE_SHOPIFY_REASON: 'Sell your products on your Maestro-powered site',
  ADMIN_FEATURE_SHOPIFY_DESC: 'Configure your shopify store',
  ADMIN_FEATURE_STREAMING_DESC: 'Enable white-label livestreaming for your site',
  ADMIN_FEATURE_STREAMING_REASON_1: 'Stream exclusively to your site without relying on other platforms',
  ADMIN_FEATURE_STREAMING_REASON_2: 'Automatically convert each stream to a VOD when it ends',
  ADMIN_FEATURE_VOD_UPLOADS_DESC: 'Upload and store your videos directly in Maestro',
  ADMIN_FEATURE_VOD_UPLOADS_REASON_1: 'Manage all of your video content in one place',
  ADMIN_FEATURE_DEVELOPER_DESC: 'Add custom code, register for an API Key, and subscribe to Webhooks',
  ADMIN_FEATURE_DEVELOPER_REASON_1: 'Add custom CSS styling or Javascript',
  ADMIN_FEATURE_DEVELOPER_REASON_2: 'Register for an Api Key.',
  ADMIN_FEATURE_DEVELOPER_REASON_3: 'Subscribe to webhooks from Maestro Services.',
  ADMIN_OVERLAY_PREVIEW_PREDICTION_NAME: 'prediction example',
  ADMIN_OVERLAY_PREVIEW_PREDICTION_QUESTION: 'Which team will be ahead by halftime?',
  ADMIN_OVERLAY_PREVIEW_PREDICTION_ANSWER_1: 'Blue Team',
  ADMIN_OVERLAY_PREVIEW_PREDICTION_ANSWER_2: 'Red Team',
  ADMIN_OVERLAY_PREVIEW_POLL_QUESTION: 'Should I stream weekly or daily?',
  ADMIN_OVERLAY_PREVIEW_POLL_TITLE: 'sarah example',
  ADMIN_OVERLAY_PREVIEW_POLL_ANSWER_1: 'Weekly',
  ADMIN_OVERLAY_PREVIEW_POLL_ANSWER_2: 'Daily',
  ADMIN_OVERLAY_PREVIEW_SHOPIFY_ITEM: 'Limited Edition Cotton Black Maestro T-Shirt',
  ADMIN_OVERLAY_PREVIEW_SHOPIFY_ITEM_SIZE: 'm',
  ADMIN_OVERLAY_PREVIEW_HIGHLIGHT_DESC: 'Lebron James makes two point alley-oop layup (Javale McGee assists)',
  ADMIN_OVERLAY_PREVIEW_MESSAGE: 'Kicking off Group A stage! Four teams will compete and two will move on to the semi-finals',
  ADMIN_OVERLAY_PREVIEW_TRIVIA_TITLE: 'Example trivia',
  ADMIN_OVERLAY_PREVIEW_TRIVIA_QUESTION: 'What generation makes up the majority of my audience?',
  ADMIN_OVERLAY_PREVIEW_TRIVIA_ANSWER_1: 'Generation X',
  ADMIN_OVERLAY_PREVIEW_TRIVIA_ANSWER_2: 'Generation Y',
  ADMIN_OVERLAY_PREVIEW_TRIVIA_ANSWER_3: 'Generation Z',
  ADMIN_OVERLAY_PREVIEW_TRIVIA_ANSWER_4: 'Generation Alpha',
  ADMIN_OVERLAY_PREVIEW_TWEET_TEXT: 'Congratulations to South Korea, the #OWWC2018 champions! https://t.co/txTaLRmZvU',
  ADMIN_OVERLAY_PREVIEW_TWEET_USER_NAME: 'Overwatch League',
  ADMIN_OVERLAY_PREVIEW_TWEET_USER_SCREENAME: 'overwatchleague',
  ADMIN_DEFAULT_PAGE_SEO_TITLE: 'New page',
  ADMIN_GATE_DEFAULT_SUBTITLE: `Subtitle of the gate goes here. Tell your audience what to expect!
The better you can tease your audience, the higher the likelihood that they will get through the gate.`,
  ADMIN_GATE_DEFAULT_TITLE: 'Title of the Gate',
  ADMIN_COMMUNITY_RESULT_NO_USERS: 'Results: No Users',
  ADMIN_COMMUNITY_RESULT_ONE_USER: makeTranslation('Results: {{formattedNumber}} User')<{ formattedNumber: string }>(),
  ADMIN_COMMUNITY_RESULT_SOME_USERS: makeTranslation('Results: {{formattedNumber}} Users')<{ formattedNumber: string }>(),
  ADMIN_COMMUNITY_RESULT_MANY_USERS: makeTranslation('Results: {{formattedNumber}}+ Users')<{ formattedNumber: string }>(),
  ADMIN_COMMUNITY_RESULT_EXPORT_TO_SEE: 'To see all results, please export this list.',
  ADMIN_COMMUNITY_DOWNLOAD_TO_SEE_FULL_LIST: makeTranslation('To see the full list of results, click the {{exportButton}} button at the top.')<{ exportButton: ReactNode }>(),
  ADMIN_RELATIVE_TIME_X_MINUTES_AGO: makeTranslation('{{minutes}} min. ago')<{ minutes: number }>(),
  ADMIN_RELATIVE_TIME_X_HOURS_AGO: makeTranslation('{{hours}} hour(s) ago')<{ hours: number }>(),
  ADMIN_SHORT_RELATIVE_TIME_X_YEARS_AGO: makeTranslation('{{time}}y ago')<{ time: number }>(),
  ADMIN_SHORT_RELATIVE_TIME_X_MONTHS_AGO: makeTranslation('{{time}}mo ago')<{ time: number }>(),
  ADMIN_SHORT_RELATIVE_TIME_X_DAYS_AGO: makeTranslation('{{time}}d ago')<{ time: number }>(),
  ADMIN_SHORT_RELATIVE_TIME_X_HOURS_AGO: makeTranslation('{{time}}h ago')<{ time: number }>(),
  ADMIN_SHORT_RELATIVE_TIME_X_MINUTES_AGO: makeTranslation('{{time}}m ago')<{ time: number }>(),
  ADMIN_SHORT_RELATIVE_TIME_X_SECONDS_AGO: makeTranslation('{{time}}s ago')<{ time: number }>(),
  ADMIN_ACTION_SCHEDULE_VIDEO: 'Schedule Video',
  ADMIN_PLACEHOLDER_YOUR_BRAND: 'Your Brand',
  ADMIN_BILLING_FORM_CARDHOLDER_NAME_PLACEHOLDER: 'Name as appears on the card',
  ADMIN_BILLING_FORM_SELECT_COUNTRY_PLACEHOLDER: 'Select Country',
  ADMIN_BILLING_FORM_CARD_EXPIRATION_PLACEHOLDER: 'MM / YY',
  ADMIN_CHANNEL_CONTENT_CHANGE_VIDEO: 'Change video',
  ADMIN_CHANNEL_CONTENT_CHANGE_PLAYLIST: 'Change playlist',
  ADMIN_CHANNEL_SELECT_ADD_CHANNEL: 'Add channel',
  ADMIN_VIDEO_SPOTLIGHT_IN_LINE: 'In-Line',
  ADMIN_VIDEO_SPOTLIGHT_VIDEO_PAGE: 'Video Page',
  ADMIN_IMAGE_BLOCK_DEFAULT_TITLE: 'Image Title',
  ADMIN_IMAGE_BLOCK_DEFAULT_CTA_TEXT: 'CALL TO ACTION',
  ADMIN_TEXT_BLOCK_DEFAULT_CTA_TEXT: 'PRIMARY CTA',
  ADMIN_HERO_BLOCK_DEFAULT_SECONDARY_CTA_TEXT: 'Secondary CTA',
  ADMIN_HERO_BLOCK_DEFAULT_TITLE: 'Add Your Hero Title here',
  ADMIN_FAQ_TITLE: 'Frequently Asked Questions (FAQs)',
  ADMIN_IMAGE_AND_TEXT_BLOCK_DEFAULT_CTA: 'Learn More',
  ADMIN_IMAGE_EDITOR_RECOMMENDED_SIZE: makeTranslation('Recommended size {{width}}x{{height}}px')<{ height: any; width: any; }>(),
  ADMIN_UPLOAD_MAX_FILE_SIZE_MB: makeTranslation('{{size}} MB max')<{ size: any }>(),
  ADMIN_RICHTEXT_TABLE_INSERT_ROW_ABOVE: 'Add Row Above',
  ADMIN_RICHTEXT_TABLE_INSERT_ROW_BELOW: 'Add Row Below',
  ADMIN_RICHTEXT_TABLE_INSERT_COLUMN_LEFT: 'Add Column Left',
  ADMIN_RICHTEXT_TABLE_INSERT_COLUMN_RIGHT: 'Add Column Right',
  ADMIN_RICHTEXT_TABLE_DELETE_ROW: 'Delete Row',
  ADMIN_RICHTEXT_TABLE_DELETE_COLUMN: 'Delete Column',
  LABEL_DEFAULT_PERSON_BIO: 'As TOKiMONSTA, California producer Jennifer Lee’s charted a fascinating career over the past seven years, and Lune Rouge is her exhilarating next step. The third proper long-player from TOKiMONSTA is both a logical progression of her sound and a surprising left turn from an artist that rarely ceases to surprise. A smoky, patient, and altogether lovely collection, Lune Rouge mixes modern sensibilities with old-school sounds to make for a heady brew of moonlit music.',
  LABEL_DEFAULT_PERSON_NAME: 'tokimonsta',
  LABEL_DEFAULT_PERSON_TITLE: 'Musician',
  LABEL_WEBSITE: 'Website',
  LABEL_DEFAULT_POLL_QUESTION: 'Who is your favorite tank to play?',
  LABEL_DEFAULT_POLL_OPTION_1: 'D.Va',
  LABEL_DEFAULT_POLL_OPTION_2: 'Orisa',
  LABEL_DEFAULT_POLL_OPTION_3: 'Reinhardt',
  LABEL_DEFAULT_POLL_OPTION_4: 'Roadhog',
  LABEL_DEFAULT_POLL_OPTION_5: 'Winston',
  LABEL_DEFAULT_POLL_OPTION_6: 'Zarya',
  LABEL_DEFAULT_IMAGE_CARD_TITLE: 'MVP of 2018',
  LABEL_DEFAULT_MESSAGE_CARD_MESSAGE: 'Share the stream if you’re enjoying it!',
  LABEL_DEFAULT_MESSAGE_CARD_TITLE: 'Welcome!',
  LABEL_DEFAULT_PERSON_CARD_TITLE: 'Jennifer Lee',
  LABEL_DEFAULT_POLL_CARD_TITLE: 'Audience Poll',
  LABEL_SUNDAY: 'Sunday',
  LABEL_MONDAY: 'Monday',
  LABEL_TUESDAY: 'Tuesday',
  LABEL_WEDNESDAY: 'Wednesday',
  LABEL_THURSDAY: 'Thursday',
  LABEL_FRIDAY: 'Friday',
  LABEL_SATURDAY: 'Saturday',
  LABEL_SHORT_SUNDAY: 'Su',
  LABEL_SHORT_MONDAY: 'Mo',
  LABEL_SHORT_TUESDAY: 'Tu',
  LABEL_SHORT_WEDNESDAY: 'We',
  LABEL_SHORT_THURSDAY: 'Th',
  LABEL_SHORT_FRIDAY: 'Fr',
  LABEL_SHORT_SATURDAY: 'Sa',
  LABEL_SHORT_JANUARY: 'Jan',
  LABEL_SHORT_FEBRUARY: 'Feb',
  LABEL_SHORT_MARCH: 'Mar',
  LABEL_SHORT_APRIL: 'Apr',
  LABEL_SHORT_MAY: 'May',
  LABEL_SHORT_JUNE: 'Jun',
  LABEL_SHORT_JULY: 'Jul',
  LABEL_SHORT_AUGUST: 'Aug',
  LABEL_SHORT_SEPTEMBER: 'Sep',
  LABEL_SHORT_OCTOBER: 'Oct',
  LABEL_SHORT_NOVEMBER: 'Nov',
  LABEL_SHORT_DECEMBER: 'Dec',
  LABEL_JANUARY: 'January',
  LABEL_FEBRUARY: 'February',
  LABEL_MARCH: 'March',
  LABEL_APRIL: 'April',
  LABEL_MAY: 'May',
  LABEL_JUNE: 'June',
  LABEL_JULY: 'July',
  LABEL_AUGUST: 'August',
  LABEL_SEPTEMBER: 'September',
  LABEL_OCTOBER: 'October',
  LABEL_NOVEMBER: 'November',
  LABEL_DECEMBER: 'December',
  LABEL_DATE_FORMAT_FULL: makeTranslation('{{month}} {{day}}, {{year}}')<{ day: number, month: string, year: number }>(),
  LABEL_DATE_FORMAT_MONTH_YEAR: makeTranslation('{{month}}, {{year}}')<{ month: string, year: number }>(),
  ICON_NAME_AIRPORT: 'Airport',
  ICON_NAME_AMAZON: 'Amazon',
  ICON_NAME_AMEX: 'Amex',
  ICON_NAME_ANDROID: 'Android',
  ICON_NAME_ANGELLIST: 'Angellist',
  ICON_NAME_APPLE: 'Apple',
  ICON_NAME_AREA_CHART: 'Area Chart',
  ICON_NAME_ARROW_COMBO: 'Arrow Combo',
  ICON_NAME_ART_GALLERY: 'Art Gallery',
  ICON_NAME_ARTICLE: 'Article',
  ICON_NAME_AT: 'At',
  ICON_NAME_ATTENTION: 'Attention',
  ICON_NAME_AWARD: 'Award',
  ICON_NAME_BANDCAMP: 'Bandcamp',
  ICON_NAME_BAR_CHART: 'Bar Chart',
  ICON_NAME_BAR: 'Bar',
  ICON_NAME_BASKET: 'Basket',
  ICON_NAME_BEATPORT: 'Beatport',
  ICON_NAME_BEHANCE: 'Behance',
  ICON_NAME_BICYCLE: 'Bicycle',
  ICON_NAME_BLOCK: 'Block',
  ICON_NAME_BOOK_OPEN: 'Book Open',
  ICON_NAME_BOOK: 'Book',
  ICON_NAME_BOOKMARK: 'Bookmark',
  ICON_NAME_BOOKMARKS: 'Bookmarks',
  ICON_NAME_BOX: 'Box',
  ICON_NAME_BRUSH: 'Brush',
  ICON_NAME_BUG: 'Bug',
  ICON_NAME_BUS: 'Bus',
  ICON_NAME_CAB: 'Cab',
  ICON_NAME_CALENDAR: 'Calendar',
  ICON_NAME_CAMERA: 'Camera',
  ICON_NAME_CAMPSITE: 'Campsite',
  ICON_NAME_CANCEL_THIN: 'Cancel Thin',
  ICON_NAME_CANCEL: 'Cancel',
  ICON_NAME_CHAT: 'Chat',
  ICON_NAME_CHECK_EMPTY: 'Check Empty',
  ICON_NAME_CHECK: 'Check',
  ICON_NAME_CHROME: 'Chrome',
  ICON_NAME_CIRCLE_FILLED_CHECK: 'Circle Filled Check',
  ICON_NAME_CIRCLE_CHECK: 'Circle Check',
  ICON_NAME_CIRCLE_EMPTY: 'Circle Empty',
  ICON_NAME_CIRCLE_THIN: 'Circle Thin',
  ICON_NAME_CIRCLE_X: 'Circle X',
  ICON_NAME_CIRCLE: 'Circle',
  ICON_NAME_CLOCK: 'Clock',
  ICON_NAME_CLOUD: 'Cloud',
  ICON_NAME_CLOUDAPP: 'Cloudapp',
  ICON_NAME_CODE: 'Code',
  ICON_NAME_COG: 'Cog',
  ICON_NAME_COGS: 'Cogs',
  ICON_NAME_COLUMNS: 'Columns',
  ICON_NAME_COMMENT: 'Comment',
  ICON_NAME_CREDIT_CARD: 'Credit Card',
  ICON_NAME_CROP: 'Crop',
  ICON_NAME_DAILYMOTION: 'Dailymotion',
  ICON_NAME_DIGG: 'Digg',
  ICON_NAME_DISCOVER: 'Discover',
  ICON_NAME_DOC_LANDSCAPE: 'Doc Landscape',
  ICON_NAME_DOCS: 'Docs',
  ICON_NAME_DOLLAR: 'Dollar',
  ICON_NAME_DOWN_OPEN_THIN: 'Down Open Thin',
  ICON_NAME_DOWN_OPEN: 'Down Open',
  ICON_NAME_DOWNLOAD: 'Download',
  ICON_NAME_DROPBOX: 'Dropbox',
  ICON_NAME_EMAIL: 'Email',
  ICON_NAME_EURO: 'Euro',
  ICON_NAME_EXCHANGE: 'Exchange',
  ICON_NAME_EYE: 'Eye',
  ICON_NAME_FACEBOOK_SQUARED: 'Facebook Squared',
  ICON_NAME_FACEBOOK: 'Facebook',
  ICON_NAME_FAST_BW: 'Fast Bw',
  ICON_NAME_FAST_FW: 'Fast Fw',
  ICON_NAME_FERRY: 'Ferry',
  ICON_NAME_FILM: 'Film',
  ICON_NAME_FIRE: 'Fire',
  ICON_NAME_FLAG: 'Flag',
  ICON_NAME_FLICKR: 'Flickr',
  ICON_NAME_FLIGHT: 'Flight',
  ICON_NAME_FOOD: 'Food',
  ICON_NAME_FOOTBALL: 'Football',
  ICON_NAME_GAMEPAD: 'Gamepad',
  ICON_NAME_GARDEN: 'Garden',
  ICON_NAME_GIT: 'Git',
  ICON_NAME_GITHUB_CIRCLED: 'Github Circled',
  ICON_NAME_GITHUB_SQUARED: 'Github Squared',
  ICON_NAME_GITHUB: 'Github',
  ICON_NAME_GLOBE: 'Globe',
  ICON_NAME_GOOGLE: 'Google',
  ICON_NAME_GOOGLEPLUS_CIRCLED: 'Googleplus Circled',
  ICON_NAME_GOOGLEPLUS_SQUARED: 'Googleplus Squared',
  ICON_NAME_GOOGLEPLUS: 'Googleplus',
  ICON_NAME_GROOVESHARK: 'Grooveshark',
  ICON_NAME_HAPPY: 'Happy',
  ICON_NAME_HEADPHONES: 'Headphones',
  ICON_NAME_HEART_EMPTY: 'Heart Empty',
  ICON_NAME_HEART: 'Heart',
  ICON_NAME_HELIPORT: 'Heliport',
  ICON_NAME_HELP_CIRCLED: 'Help Circled',
  ICON_NAME_HELP: 'Help',
  ICON_NAME_HTML5: 'Html5',
  ICON_NAME_ICON_FILTER: 'Icon Filter',
  ICON_NAME_IE: 'Ie',
  ICON_NAME_INFO_CIRCLED: 'Info Circled',
  ICON_NAME_INFO: 'Info',
  ICON_NAME_INSTAGRAM: 'Instagram',
  ICON_NAME_ITUNES: 'Itunes',
  ICON_NAME_LASTFM_CIRCLED: 'Lastfm Circled',
  ICON_NAME_LASTFM: 'Lastfm',
  ICON_NAME_LEAF: 'Leaf',
  ICON_NAME_LEFT_OPEN_BIG: 'Left Open Big',
  ICON_NAME_LEFT_OPEN_MINI: 'Left Open Mini',
  ICON_NAME_LEFT_OPEN: 'Left Open',
  ICON_NAME_LINE_CHART: 'Line Chart',
  ICON_NAME_LINK: 'Link',
  ICON_NAME_LINKEDIN_CIRCLED: 'Linkedin Circled',
  ICON_NAME_LINKEDIN_SQUARED: 'Linkedin Squared',
  ICON_NAME_LINKEDIN: 'Linkedin',
  ICON_NAME_LIST_BULLET: 'List Bullet',
  ICON_NAME_LIST: 'List',
  ICON_NAME_LOCATION: 'Location',
  ICON_NAME_LOCK_OPEN: 'Lock Open',
  ICON_NAME_LOCK: 'Lock',
  ICON_NAME_MAP: 'Map',
  ICON_NAME_MASTERCARD: 'Mastercard',
  ICON_NAME_MEDKIT: 'Medkit',
  ICON_NAME_MEGAPHONE: 'Megaphone',
  ICON_NAME_MIC: 'Mic',
  ICON_NAME_MINUS_CIRCLE: 'Minus Circle',
  ICON_NAME_MINUS: 'Minus',
  ICON_NAME_MIXCLOUD: 'Mixcloud',
  ICON_NAME_MOBILE: 'Mobile',
  ICON_NAME_MONITOR: 'Monitor',
  ICON_NAME_MOVE: 'Move',
  ICON_NAME_NEWSPAPER: 'Newspaper',
  ICON_NAME_NOTE_BEAMED: 'Note Beamed',
  ICON_NAME_NOTE: 'Note',
  ICON_NAME_OK: 'Ok',
  ICON_NAME_PAUSE: 'Pause',
  ICON_NAME_PAW: 'Paw',
  ICON_NAME_PAYPAL: 'Paypal',
  ICON_NAME_PENCIL: 'Pencil',
  ICON_NAME_PHONE: 'Phone',
  ICON_NAME_PICTURE: 'Picture',
  ICON_NAME_PICTURES: 'Pictures',
  ICON_NAME_PIE_CHART: 'Pie Chart',
  ICON_NAME_PINTEREST_CIRCLED: 'Pinterest Circled',
  ICON_NAME_PINTEREST_SQUARED: 'Pinterest Squared',
  ICON_NAME_PINTEREST: 'Pinterest',
  ICON_NAME_PLAY_1: 'Play 1',
  ICON_NAME_PLAY_CIRCLED: 'Play Circled',
  ICON_NAME_PLAY: 'Play',
  ICON_NAME_PLAYSTV: 'Playstv',
  ICON_NAME_PLUS_CIRCLE: 'Plus Circle',
  ICON_NAME_PLUS_THIN: 'Plus Thin',
  ICON_NAME_PLUS: 'Plus',
  ICON_NAME_PODCAST: 'Podcast',
  ICON_NAME_POUND: 'Pound',
  ICON_NAME_POWER: 'Power',
  ICON_NAME_QUOTE_LEFT: 'Quote Left',
  ICON_NAME_QUOTE_RIGHT: 'Quote Right',
  ICON_NAME_REDDIT: 'Reddit',
  ICON_NAME_REPLY: 'Reply',
  ICON_NAME_RESIDENT_ADVISOR: 'Resident Advisor',
  ICON_NAME_RESIZE_FULL: 'Resize Full',
  ICON_NAME_RESIZE_SMALL: 'Resize Small',
  ICON_NAME_RETWEET: 'Retweet',
  ICON_NAME_RIGHT_HAND: 'Right Hand',
  ICON_NAME_RIGHT_OPEN_BIG: 'Right Open Big',
  ICON_NAME_RIGHT_OPEN_MINI: 'Right Open Mini',
  ICON_NAME_RIGHT_OPEN: 'Right Open',
  ICON_NAME_ROAD: 'Road',
  ICON_NAME_ROCKET: 'Rocket',
  ICON_NAME_RSS: 'Rss',
  ICON_NAME_SCHOOL: 'School',
  ICON_NAME_SEARCH: 'Search',
  ICON_NAME_SHARE: 'Share',
  ICON_NAME_SHAZAM: 'Shazam',
  ICON_NAME_SHIELD: 'Shield',
  ICON_NAME_SKYPE: 'Skype',
  ICON_NAME_SNAPCHAT: 'Snapchat',
  ICON_NAME_SOUNDCLOUD: 'Soundcloud',
  ICON_NAME_SPIN1: 'Spin1',
  ICON_NAME_SPIN2: 'Spin2',
  ICON_NAME_SPIN3: 'Spin3',
  ICON_NAME_SPOTIFY_CIRCLED: 'Spotify Circled',
  ICON_NAME_SPOTIFY: 'Spotify',
  ICON_NAME_STAR_EMPTY: 'Star Empty',
  ICON_NAME_STAR: 'Star',
  ICON_NAME_STEAM: 'Steam',
  ICON_NAME_STOP: 'Stop',
  ICON_NAME_SUBWAY: 'Subway',
  ICON_NAME_T_SHIRT: 'T Shirt',
  ICON_NAME_TAGS: 'Tags',
  ICON_NAME_TH_LARGE: 'Th Large',
  ICON_NAME_THEATRE: 'Theatre',
  ICON_NAME_THUMBS_DOWN: 'Thumbs Down',
  ICON_NAME_THUMBS_UP: 'Thumbs Up',
  ICON_NAME_TICKET: 'Ticket',
  ICON_NAME_TO_END_ALT: 'To End Alt',
  ICON_NAME_TO_END: 'To End',
  ICON_NAME_TO_START_ALT: 'To Start Alt',
  ICON_NAME_TO_START: 'To Start',
  ICON_NAME_TRAIN: 'Train',
  ICON_NAME_TRASH: 'Trash',
  ICON_NAME_TUMBLR_CIRCLED: 'Tumblr Circled',
  ICON_NAME_TUMBLR_SQUARED: 'Tumblr Squared',
  ICON_NAME_TUMBLR: 'Tumblr',
  ICON_NAME_TWITCH: 'Twitch',
  ICON_NAME_TWITTER_CIRCLED: 'Twitter Circled',
  ICON_NAME_TWITTER_FILLED: 'Twitter Filled',
  ICON_NAME_TWITTER_SQUARED: 'Twitter Squared',
  ICON_NAME_TWITTER: 'Twitter',
  ICON_NAME_UNHAPPY: 'Unhappy',
  ICON_NAME_UP_OPEN_THIN: 'Up Open Thin',
  ICON_NAME_UP_OPEN: 'Up Open',
  ICON_NAME_UPLOAD: 'Upload',
  ICON_NAME_USER_ADD: 'User Add',
  ICON_NAME_USER: 'User',
  ICON_NAME_USERS: 'Users',
  ICON_NAME_VCARD: 'Vcard',
  ICON_NAME_VEVO: 'Vevo',
  ICON_NAME_VIDEO: 'Video',
  ICON_NAME_VIDEOCAM: 'Videocam',
  ICON_NAME_VIMEO_CIRCLED: 'Vimeo Circled',
  ICON_NAME_VIMEO_SQUARED: 'Vimeo Squared',
  ICON_NAME_VIMEO: 'Vimeo',
  ICON_NAME_VINE: 'Vine',
  ICON_NAME_VISA: 'Visa',
  ICON_NAME_VK: 'Vk',
  ICON_NAME_VOLUME_DOWN: 'Volume Down',
  ICON_NAME_VOLUME_OFF: 'Volume Off',
  ICON_NAME_VOLUME_UP: 'Volume Up',
  ICON_NAME_WATER: 'Water',
  ICON_NAME_WHEELCHAIR: 'Wheelchair',
  ICON_NAME_WINDOWS: 'Windows',
  ICON_NAME_WORDPRESS: 'Wordpress',
  ICON_NAME_WRENCH: 'Wrench',
  ICON_NAME_YELP: 'Yelp',
  ICON_NAME_YOUTUBE_PLAY: 'Youtube Play',
  ICON_NAME_YOUTUBE_SQUARED: 'Youtube Squared',
  ICON_NAME_YOUTUBE: 'Youtube',
  ICON_NAME_ZOOM_IN: 'Zoom In',
  ICON_NAME_ZOOM_OUT: 'Zoom Out',
  ADMIN_PANEL_V2_DUPLICATE_PANEL_BUTTON_TOOLTIP: makeTranslation('Add another {{title}} Panel')<{ title: string }>(),
  ADMIN_PANEL_V2_DELETE_DUPLICATED_PANEL_BUTTON_TOOLTIP: makeTranslation('Delete {{title}} Panel')<{ title: string }>(),
  ADMIN_PANEL_V2_DELETE_DUPLICATED_PANEL_MODAL_TITLE: makeTranslation('Are you sure you want to delete this {{title}} Panel?')<{ title: string }>(),
  ADMIN_PANEL_V2_DELETE_DUPLICATED_PANEL_MODAL_SUBTITLE: 'You will not be able to undo this action',
  BRAND_NAME: 'Maestro',
  ADMIN_LOCALIZATION_HEADER_TEXT: 'Provide localized strings for languages of your choosing. Users will be able to select between the options you make available',
  ADMIN_LOCALIZATION_ENABLE_USER_SELECTION: 'Enable User Language Selection',
  ADMIN_LOCALIZATION_ADDITIONAL_SUPPORTED_LANGUAGES: 'Additional Supported Languages',
  ADMIN_LOCALIZATION_DEFAULT_LANGUAGE: 'Default Language',
  ADMIN_LOCALIZATION_DEFAULT_DROPDOWN_OPTION: 'Original Text',
  ADMIN_LOCALIZATION_ADD_LANGUAGE: 'Add Language',
  ADMIN_LOCALIZATION_MISSING_STRINGS: 'Missing strings',
  ADMIN_LOCALIZATION_DROPDOWN_PLACEHOLDER: 'Select language',
  ADMIN_LOCALIZATION_FILE_UPLOAD_SUCCESS: 'Upload successful!',
  ADMIN_DELETE_LANGUAGE_MODAL_TITLE: 'Are you sure you want to delete this language?',
  ADMIN_DELETE_LANGUAGE_MODAL_SUBTITLE: 'You will not be able to undo this action',
  EMAIL_PLACEHOLDER: 'email@example.com',
  STUDIO_INVITE_TO_STAGE: 'Invite guest to stage',
  CHAT_SILENCE_USER: 'Silence user',
  CHAT_UNSILENCE_USER: 'Unsilence user',
  ADMIN_LABEL_SHOW_ALL: 'Show All',
  GO_TO_CHANNEL_SLUG: makeTranslation('go to {{slug}}')<{ slug: string }>(),
  ON_CHANNEL_SLUG: makeTranslation('on {{slug}}')<{ slug: string }>(),
  STUDIO_STREAM_STALLED_ERROR_MESSAGE: 'Something went wrong with your Studio Stream. Please go live again.',
  SET_UP_ACCESS_MODAL_TITLE: makeTranslation('Your {{planName}} plan requires that you set up paid access to your content.')<{ planName: string }>(),
  SET_UP_ACCESS_MODAL_SUBTITLE: 'You can also upgrade to a custom plan to offer free streams.',
  SET_UP_ACCESS_MODAL_BUTTON_TEXT: 'Set Up Access',
  GO_TO_SITE: 'Go To Site',
  CHOOSE_SITE: 'Choose the site you\'d like to access.',
  START_FOR_FREE: 'Start for Free',
  CHOOSE_A_SITE_NAME: 'Choose a site name',
  CREATE_YOUR_SITE: 'Create Your Site',
  SITE_NAME: 'Site Name',
  SITE_NAME_NOT_AVAILABLE: 'This site name is not available',
  SITE_NAME_CONTAINS_PROFANITY: 'Sitename cannot contain profanity',
  SITE_NAME_SPECIAL_CHARACTERS: 'Sitename may have letters, digits, dashes, or underscores',
  SITE_NAME_MAX_LENGTH: 'Sitename must be less than 30 characters',
  SITE_NAME_MIN_LENGTH: 'Sitename must be 3+ characters',
  CHOOSE_SITE_NAME: 'Please choose a site name',
  SITE_NAME_AVAILABLE: 'This site name is available',
  SIGNUP_MARKETING_OPT_IN: 'I agree to receive marketing communications from Maestro',
  RECAPTCHA_REQUIRED: 'Please complete the reCAPTCHA',
  TERMS_AGREEMENT: makeTranslation('By clicking sign up, you agree to Maestro\'s <a href={{termsOfService}} target="_blank">Terms of Service</a> and <a href={{privacyPolicy}} target="_blank">Privacy Policy</a>')<{ privacyPolicy: string; termsOfService: string; }>(),
  PURCHASE_AND_SIGNUP: 'Purchase & Sign Up',
  WELCOME_TO_MAESTRO: 'Welcome to Maestro!',
  WELCOME_TO_MAESTRO2: 'You\'re almost there!',
  MODAL_SITE_SUCCESS_DESCRIPTION: 'We\'re setting up your site, get ready to create!',
  MODAL_SITE_SUCCESS_DESCRIPTION2: 'We\'re putting the finishing touches on your new site',
  WANT_TO_KEEP_MORE: 'Want to Keep More ?',
  KEEP: 'Keep',
  FREE_FOREVER: 'Free Forever',
  LETS_TALK: 'Let\'s Talk',
  ADMIN_USERS: 'Admin Users',
  UNLIMITED: 'Unlimited',
  PAGES: 'Pages',
  LIVE_STREAMING: 'Live Streaming',
  VIDEO_WEBSITE_BUILDER: 'Video Website Builder',
  MONETIZATION: 'Monetization',
  INTERACTIVITY: 'Interactivity',
  COMMUNITY: 'Community',
  STUDIO: 'Studio (Beta)',
  ANALYTICS: 'Analytics',
  CUSTOM_DOMAIN: 'Custom Domain',
  REMOVE_MAESTRO_BRAND: 'Ability to Remove Maestro Branding',
  DEVELOPER_TOOLS: 'Developer Tools',
  FREE_EVENTS: 'Free Events',
  SERVICE_LEVEL_AGREEMENT: 'Service Level Agreement',
  CUSTOMER_SUCCESS_MANAGER: 'Customer Success Manager',
  CLOUD_DATA_EXPORT: 'Cloud Data Export',
  VIEWER_SUPPORT: 'Viewer Support',
  EVENT_SUPPORT: 'Event Support',
  EVENT_SUPPORT_TOOLTIP: 'Have a member of our team on standby to assist during your event to answer any questions and troubleshoot if necessary.',
  VIEWER_SUPPORT_TOOLTIP: 'Get live support from a member of our team to handle questions and requests from your your viewers.',
  CLOUD_DATA_EXPORT_TOOLTIP: 'Flow your video analytics and user data out of Maestro into your own cloud storage.',
  CUSTOMER_SUCCESS_MANAGER_TOOLTIP: 'Access day-to-day support from a member of our Customer Success team to answer your questions and help you strategize to maximize your success.',
  CONTACT_US: 'Contact Us',
  CHOOSE: 'Choose',
  CURRENTLY_ON_THIS_PLAN: 'Currently on this plan',
  BEST_VALUE: 'Best Value',
  CHOOSE_YOUR_PLAN: 'Choose your Plan',
  HIDE_PLAN_FULL_DETAILS: 'Hide plan details',
  SEE_FULL_PLAN_DETAILS: 'See full plan details',
  EMBED_CODE: 'Embed Code',
  EMBED_CODE_BLOCK_MODAL_HEADER: 'Embed Code Settings',
  EMBED_CODE_BLOCK_MODAL_SUB_HEADER: 'Embed Code',
  OPEN_IN_NEW_TAB: 'Open in new tab',
  BEHAVIOR: 'Behavior',
  SELECT_PAGE: 'Select Page',
  LINK_ERROR_PLEASE_TRY_AGAIN: 'Link error. Please try again',
  BUTTON_SIZE: 'Button Size',
  OPEN_CREATE_ACCOUNT_MODAL: 'Open Create Account Modal',
  OPEN_LOGIN_MODAL: 'Open Login Modal',
  GO_TO_PAGE: 'Go To Page',
  TV_APPS_LOGIN_MODAL_TITLE: 'Enter the code to activate your device',
  TV_APPS_LOGIN_MODAL_DESCRIPTION: 'Enter the code that appears on your TV',
  TV_APPS_LOGIN_MODAL_INVALID_CODE_ERROR: 'Invalid code, please check your TV and try again',
  TV_APPS_LOGIN_MODAL_SUCESS_TITLE: 'Success!',
  TV_APPS_LOGIN_MODAL_SUCESS_DESCRIPTION: 'Your TV is now linked to your account. Continue the process on your TV.',
  TV_APPS_LOGIN_MODAL_NEED_HELP: 'Need Help?',
  TV_APPS_LOGIN_MODAL_NEED_HELP_LINK: 'Visit the Help Center',
  LINK_COLOR: 'Link Color',
  THEME_COLORS: 'Theme Colors',
  DEFAULT_DATA: 'Default Data',
  SEASON: 'Season',
  TOURNAMENT: 'Tournament',
  SESSION: 'Session',
  GAME_TYPE: 'Game Type',
  BATTLE_ROYALE: 'Battle Royale',
  ZERO_BUILD: 'Zero Build',
  SEARCH_BY_NAME: 'Search by Name',
  PLACE: 'Place',
  MATCHES: 'Matches',
  ELIMS: 'Elims',
  DIVISION: 'Division',
  PLAYER_NAME: 'Player Name',
  FILTERS: 'Filters',
  GENERAL: 'General',
  RANKED: 'Ranked',
  SELECT_SEASON: 'Select Season',
  SELECT_TOURNAMENT: 'Select Tournament',
  SELECT_SESSION: 'Select Session',
  NO_RESULTS_FOR_X: makeTranslation('No results for \'{{x}}\'')<{ x: string }>(),
  MOVE_UP: 'Move Up',
  MOVE_DOWN: 'Move Down',
  LANDING_PAGE_LANDSCAPE_MESSAGE: 'This page doesn\'t rotate. Please flip your phone orientation to continue your experience.',
  NO_CONTENT_AVAILABLE_FOR_PURCHASE: 'This content is no longer available for purchase',
  CARD_SETTINGS: 'Card Settings',
  CARD_ACTION: 'Card Action',
} as const;

export default enMaestroDictionary;
